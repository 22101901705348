import { PropsWithChildren, ReactElement } from 'react';
import {
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';
import { useIsMediumScreen } from '../../../theming/useScreenSize';

export type SrDrawerProps = DrawerProps & {
  sx?: SxProps<Theme>;
  width?: number;
  noAnimate?: boolean;
};

export const SrDrawer = ({
  sx,
  noAnimate,
  width: widthIn,
  ...props
}: SrDrawerProps): ReactElement => {
  const isMediumScreen = useIsMediumScreen();
  const width = widthIn
    ? { width: { xs: 1, md: props.open ? widthIn : 0 } }
    : undefined;

  return (
    <Drawer
      {...props}
      variant={isMediumScreen ? 'temporary' : 'persistent'}
      sx={[
        {
          flexShrink: 0,
          height: '100%',
          ...width,
          '& .MuiDrawer-paper': {
            borderRadius: 0.5,
            border: 'none',
            backgroundColor: 'background.secondary',
            backgroundImage: 'none',
            ...width,
            transition: (theme) =>
              noAnimate
                ? 'none !important'
                : `width ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut} 0ms`,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {props.children}
    </Drawer>
  );
};

export type SrDrawerHeaderProps = {
  sx?: SxProps<Theme>;
  onClose?(): void;
};

const Header = ({
  sx,
  onClose,
  children,
}: PropsWithChildren<SrDrawerHeaderProps>): React.JSX.Element => {
  return (
    <Typography
      variant="h2"
      component={Stack}
      sx={[
        {
          color: 'text.primary',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          py: 1,
          px: 1.5,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
      {onClose && (
        <IconButton onClick={onClose}>
          <FaIcon icon={faXmark} fontSize="medium" />
        </IconButton>
      )}
    </Typography>
  );
};

export type SrDrawerContentProps = {
  sx?: SxProps<Theme>;
};

const Content = ({
  sx,
  children,
}: PropsWithChildren<SrDrawerContentProps>): React.JSX.Element => {
  return (
    <Stack
      sx={[
        {
          gap: 1,
          px: 1,
          pt: 1,
          pb: 2,
          overflowY: 'auto',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Stack>
  );
};

export type SrDrawerFooterProps = {
  sx?: SxProps<Theme>;
  sticky?: boolean;
};

const Footer = ({
  sx,
  children,
  sticky,
}: PropsWithChildren<SrDrawerFooterProps>): React.JSX.Element => {
  return (
    <Stack
      gap={1}
      direction="row"
      sx={[
        {
          position: sticky ? 'sticky' : 'absolute',
          width: '100%',
          bottom: 0,
          p: 2,
          borderTop: '3px solid',
          borderTopColor: 'divider',
          backgroundColor: 'background.secondary',
          button: { flexGrow: 1 },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Stack>
  );
};

SrDrawer.Header = Header;
SrDrawer.Content = Content;
SrDrawer.Footer = Footer;
