import { ConfigService } from '@shuttlerock/configuration';
import { UserRole } from '@shuttlerock/auth-types';
import { UserRole as GraphUserRole } from '@shuttlerock/api-types/federated';
import { commonBasicLogger } from 'launchdarkly-js-sdk-common';
import {
  initialize as ldClientInitialize,
  LDClient,
} from 'launchdarkly-js-client-sdk';
import { gql } from 'graphql-tag';
import { request } from 'graphql-request';
import { MeDocument } from '../graphql/generated';
import { FetchUserError } from './errors';

export enum UserDataKind {
  User = 'user',
  Deprecated = 'deprecated',
}

type UserDataResult = NewUserDataResult | DeprecatedUserDataResult;

type NewUserDataResult = {
  kind: UserDataKind.User;
  user: NewUserData | undefined;
};

export type NewUserData = {
  key: string;
  fullName: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  picture: string | undefined;
  role: UserRole;
};

type DeprecatedUserDataResult = {
  kind: UserDataKind.Deprecated;
  user: DeprecatedUserData | undefined;
};

export type DeprecatedUserData = {
  id: string;
  integrationKey: string;
};

const MeQuery = gql`
  query Me {
    me {
      user {
        key
        fullName
        firstName
        lastName
        email
        picture
        role
      }
    }
  }
` as typeof MeDocument;

// HACK: We can't use the common flags object here as it creates a circular
// dependency/infinite loop trying to load the user context. So we need to
// create a client with an anonymous context.
async function novaUserMigration() {
  // HACK 2: Just assume the flag is on in tests. Easier to clean up later
  if (process.env.NODE_ENV === 'test') {
    return true;
  }

  novaUserMigration.ldClient ??= ldClientInitialize(
    ConfigService.LAUNCHDARKLY_CLIENT_ID() || '',
    { kind: 'user', anonymous: true },
    {
      wrapperName: 'react-client-sdk',
      sendEventsOnlyForVariation: true,
      logger: commonBasicLogger({
        // eslint-disable-next-line no-console
        destination: console.error,
        level: 'error',
      }),
    },
  );
  await novaUserMigration.ldClient.waitUntilReady();
  return novaUserMigration.ldClient.variation('nova-user-migration', false);
}

novaUserMigration.ldClient = undefined as LDClient | undefined;

/**
 * Queries our API for the current users details.
 * @internal - Not to be used outside `@creative-foundation/auth`
 */
export async function getUserData(
  accessToken: string,
): Promise<UserDataResult> {
  if (!(await novaUserMigration())) {
    return deprecatedGetUserData(accessToken);
  }

  try {
    const name = 'src-web';
    const version = ConfigService.APP_VERSION();
    const response = await request(
      `${ConfigService.SUPERGRAPH_BASE_URL()}/graphql`,
      MeQuery,
      undefined,
      {
        Authorization: `Bearer ${accessToken}`,
        'x-client-id': [name, version].filter(Boolean).join(';'),
        'apollographql-client-name': name,
        ...(version && { 'apollographql-client-version': version }),
      },
    );

    const user = response.me?.user;
    if (!user) {
      return { kind: UserDataKind.User, user: undefined };
    }

    return {
      kind: UserDataKind.User,
      user: {
        key: user.key,
        fullName: user.fullName ?? undefined,
        firstName: user.firstName ?? undefined,
        lastName: user.lastName ?? undefined,
        email: user.email ?? undefined,
        picture: user.picture ?? undefined,
        role: userRoleMap.get(user.role) ?? UserRole.Guest,
      },
    };
  } catch (e) {
    if (e instanceof Error) {
      throw new FetchUserError(e.message);
    }
    // eslint-disable-next-line no-console
    console.error(e);
    throw new FetchUserError(`An unexpected error occurred: ${e}`);
  }
}

const userRoleMap = new Map<GraphUserRole, UserRole>([
  [GraphUserRole.SrcAdmin, UserRole.SRC_Admin],
  [GraphUserRole.SrcManager, UserRole.SRC_Manager],
  [GraphUserRole.SrcTraffic, UserRole.SRC_Traffic],
  [GraphUserRole.SrcArtDirector, UserRole.SRC_Art_Director],
  [GraphUserRole.SrcDesigner, UserRole.SRC_Designer],
  [GraphUserRole.Client, UserRole.Client],
  [GraphUserRole.Guest, UserRole.Guest],
]);

const DEPRECATED_USER_DATA_QUERY = `
query Me {
  user: legacyUser {
    id
    integrationKey
  }
}
`.trim();

async function deprecatedGetUserData(
  accessToken: string,
): Promise<DeprecatedUserDataResult> {
  try {
    const response = await fetch(`${ConfigService.ORDER_BASE_URL()}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ query: DEPRECATED_USER_DATA_QUERY }),
    });

    if (!response.ok) {
      throw new FetchUserError(`${response.status} (${response.statusText})`);
    }

    const { data } = await response.json();
    const user = data?.user;
    if (!isDeprecatedUserData(user)) {
      return { kind: UserDataKind.Deprecated, user: undefined };
    }

    return {
      kind: UserDataKind.Deprecated,
      user: {
        id: user.id,
        integrationKey: user.integrationKey,
      },
    };
  } catch (e) {
    if (e instanceof FetchUserError) {
      throw e;
    }
    if (e instanceof Error) {
      throw new FetchUserError(e.message);
    }
    console.error(e);
    throw new FetchUserError(`An unexpected error occurred: ${e}`);
  }
}

function isDeprecatedUserData(input: unknown): input is DeprecatedUserData {
  function hasShape(x: unknown): x is { id: unknown; integrationKey: unknown } {
    return (
      typeof x === 'object' && x !== null && 'id' in x && 'integrationKey' in x
    );
  }

  return (
    hasShape(input) &&
    typeof input.id === 'string' &&
    typeof input.integrationKey === 'string'
  );
}
