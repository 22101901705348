import { ReactElement } from 'react';
import {
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';

type SrMessageProps = {
  icon?: IconDefinition;
  title?: string;
  subtitle?: string;
  sx?: SxProps<Theme>;
  children?: string | ReactElement | ReactElement[];
};

export const SrMessage = ({
  icon,
  title,
  subtitle,
  sx,
  children,
}: SrMessageProps): ReactElement => {
  return (
    <Stack sx={[{ width: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {title && (
        <SrMessage.Title>
          <>
            {icon && (
              <FaIcon
                fontSize="large"
                icon={icon}
                sx={{ my: 0.2, color: 'text.primaryAlt' }} // @TODO: Adjust the colour to the instructions.dark
              />
            )}
            <span>{title}</span>
          </>
        </SrMessage.Title>
      )}

      {subtitle && <SrMessage.Subtitle>{subtitle}</SrMessage.Subtitle>}

      {children}
    </Stack>
  );
};

/**
 * Message screen's Title
 */
type SrMessageTitleProps = {
  sx?: SxProps<Theme>;
  children: string | ReactElement | ReactElement[];
};

const SrMessageTitle = ({
  children,
  sx,
}: SrMessageTitleProps): React.JSX.Element => {
  return (
    <Typography
      variant="h2"
      sx={[
        {
          display: 'flex',
          gap: 2,
          mb: 2,
          alignSelf: 'center',
          color: 'text.primaryAlt',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  );
};

SrMessage.Title = SrMessageTitle;

/**
 * Message screen's subtitle
 */
type SrMessageSubtitleProps = TypographyProps & {
  sx?: SxProps<Theme>;
  children: string | ReactElement | ReactElement[];
};

const SrMessageSubtitle = ({
  children,
  sx,
}: SrMessageSubtitleProps): React.JSX.Element => {
  return (
    <Typography
      variant="subtitle1"
      sx={[
        { color: 'text.secondary', textAlign: 'center' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  );
};

SrMessage.Subtitle = SrMessageSubtitle;
