export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: any;
  /** Credentials for uploading files to AWS */
  Credential: any;
  Decimal: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  Time: any;
  Uint64: any;
  Upload: any;
  join__FieldSet: any;
  link__Import: any;
};

/**  Input for updating preferences for a preferred AI model.  */
export type AiModelPreferenceInput = {
  /**  Type of category generative AI is to be used for.  */
  category: GenerativeAiCategory;
  /**  Codes of the generative AI models that are to be used.  */
  modelCodes: Array<Scalars['String']>;
  /**  Usage notes for the creative team.  */
  notes?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of AcceptInvitation */
export type AcceptInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** UUID of the token to be accepted. */
  invitation: InvitationAcceptInput;
};

/** Autogenerated return type of AcceptInvitation. */
export type AcceptInvitationPayload = {
  __typename?: 'AcceptInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invitation?: Maybe<LegacyInvitation>;
};

/** The calculated hero balance for an account */
export type AccountHeroBalance = {
  __typename?: 'AccountHeroBalance';
  /** The key of the account for which the hero balance is calculated. */
  accountKey: Scalars['ID'];
  /** The number of unused heroes available for a given license plus account. */
  heroBalance: Scalars['Int'];
};

/** An account license. */
export type AccountLicense = {
  __typename?: 'AccountLicense';
  id: Scalars['ID'];
  maxActiveHeroes: Scalars['Int'];
  /** The maximum number of deliverables that can be actively in production at one time, for all clients in this license */
  maxDeliverablesInProduction: Scalars['Int'];
  /** The total number of deliverables currently in production for all clients in this license */
  totalDeliverablesInProduction: Scalars['Int'];
};

/** An account license plus. */
export type AccountLicensePlus = {
  __typename?: 'AccountLicensePlus';
  balanceExpiresAt?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
};

export type AccountLimit = {
  __typename?: 'AccountLimit';
  /** The total amount of deliverables that can be used for a given account. */
  limit: Scalars['Int'];
  /** The total amount of heroes that can be used for a given account. */
  limitType: UsageType;
};

export enum AccountType {
  License = 'LICENSE',
  LicensePlus = 'LICENSE_PLUS',
  Subscription = 'SUBSCRIPTION',
  Unrestricted = 'UNRESTRICTED'
}

/** The calculated usage for an account */
export type AccountUsage = {
  __typename?: 'AccountUsage';
  /** The amount of heroes that are in use for a given account. */
  usage: Scalars['Int'];
  /** The amount of deliverables that are in use for a given account. */
  usageType: UsageType;
};

export type AdaptResult = {
  __typename?: 'AdaptResult';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  prompt: Scalars['String'];
  status: AdaptResultStatus;
};

export enum AdaptResultStatus {
  Error = 'Error',
  Pending = 'Pending',
  Success = 'Success'
}

export type AdaptResultsForDeliverableInput = {
  deliverableKey: Scalars['ID'];
  sessionId: Scalars['ID'];
};

/** Input for adding a new comment to a revision. */
export type AddCommentInput = {
  /** Is the comment only viewable by internal users. */
  isInternalOnly: Scalars['Boolean'];
  /** The body text of the comment. */
  message: Scalars['String'];
  /** Revision ID that the comment should be associated with. */
  revisionId: Scalars['ID'];
};

export type AddConceptVariationInput = {
  integrationKey: Scalars['ID'];
  isInstantCopyChange?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of AddDeliverableFormatToConcept */
export type AddDeliverableFormatToConceptInput = {
  /**
   * The ID of the advert
   * @deprecated Use advertIntegrationKey instead
   */
  advertId?: InputMaybe<Scalars['ID']>;
  /** The integration key of the advert */
  advertIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Deliverable formats to be used for the Advert. */
  deliverableFormatAttributes: AdvertsDeliverableFormatInput;
};

/** Autogenerated return type of AddDeliverableFormatToConcept. */
export type AddDeliverableFormatToConceptPayload = {
  __typename?: 'AddDeliverableFormatToConceptPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AddSpecificationToDeliverable */
export type AddSpecificationToDeliverableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the deliverable to be updated. */
  integrationKey: Scalars['ID'];
  /** Integration key of the specification being associated to the deliverable. */
  specificationIntegrationKey: Scalars['ID'];
};

/** Autogenerated return type of AddSpecificationToDeliverable. */
export type AddSpecificationToDeliverablePayload = {
  __typename?: 'AddSpecificationToDeliverablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated deliverable, or null. */
  deliverable?: Maybe<Deliverable>;
};

export type AddVariationInput = {
  isInstantCopyChange?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['ID'];
  languageCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** An advert. */
export type Advert = {
  __typename?: 'Advert';
  /** AdvertsDeliverableFormats belonging to the Advert. */
  advertsDeliverableFormats: AdvertsDeliverableFormats;
  /** Answers submitted to the Advert. */
  answers: Array<Answer>;
  /**
   * Assets attached to the Advert
   *
   * Permitted search attributes: ["id", "name", "asset_type"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  assets: Assets;
  /** The UUID of the attached audience. */
  audienceIntegrationKey?: Maybe<Scalars['ID']>;
  /**
   * The URL of the audio asset for the Advert.
   * @deprecated Use Concept attributes audio_urls and latest_audio_type instead
   */
  audioUrl?: Maybe<Scalars['String']>;
  /**
   * Concept Variations belonging to the Advert.
   *
   * Permitted search attributes: ["id", "advert_id", "integration_key",
   * "order_id", "original_input_type_name",
   * "adverts_deliverable_formats_length_name"]
   *
   * Permitted sort attributes: ["position"]
   */
  conceptVariations: Adverts;
  /** Indication of whether the deliverable contains AI. */
  containsAi: Scalars['Boolean'];
  /** The copy answers of the concept. */
  copyAnswers?: Maybe<Array<Answer>>;
  /**
   * Copy Variations belonging to the Advert.
   *
   * Permitted search attributes: ["id", "advert_id", "integration_key",
   * "order_id", "original_input_type_name",
   * "adverts_deliverable_formats_length_name"]
   *
   * Permitted sort attributes: ["position"]
   */
  copyVariations: Adverts;
  /**
   * Deliverables belonging to the Advert.
   *
   * Permitted search attributes: ["id", "duration_filter", "order_client_name",
   * "order_client_vertical_name", "order_hubspot_id", "order_name",
   * "order_region_name", "order_serial_id", "copy_variation_number",
   * "deliverable_format_id", "deliverable_format_name",
   * "deliverable_format_platform_name", "hero", "language_code", "order_best_of",
   * "order_id", "pending_review_or_approved", "hero_or_copy_variation_filter",
   * "advert_position", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "order_best_of", "order_created_at", "order_completed_at"]
   */
  deliverables: LegacyDeliverables;
  /** Designer role Users assigned to the Advert. */
  designers: Array<LegacyUser>;
  /** The UUID of the Advert. */
  id: Scalars['ID'];
  /** A surrogate UUID used to identify an Advert when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** Language variations requested for the Advert. */
  languageVariationCodes?: Maybe<Array<Scalars['String']>>;
  /**
   * The URL of the meta audio asset for the Advert.
   * @deprecated Use audio_url instead
   */
  metaAudioAssetUrl?: Maybe<Scalars['String']>;
  /** The user-specified name for the Advert. */
  name?: Maybe<Scalars['String']>;
  /** The next advert. */
  nextAdvert?: Maybe<Advert>;
  /** The total number of concept variations. */
  numConceptVariations: Scalars['Int'];
  /** The total number of copy variations. */
  numCopyVariations: Scalars['Int'];
  /** The total number of languages. */
  numLanguages: Scalars['Int'];
  /** The campaign objective of the advert. */
  objective?: Maybe<Objective>;
  /** The order assigned to the Advert. */
  order?: Maybe<Order>;
  /** The input type as determined by the file types of order assets. */
  originalInputType: OriginalInputType;
  /** AKA the concept number. */
  position: Scalars['Int'];
  /** The previous advert. */
  previousAdvert?: Maybe<Advert>;
  /** The production notes of the Advert. */
  productionNotes?: Maybe<Scalars['String']>;
  /**
   * Questions associated with the advert.
   *
   * Permitted search attributes: ["id", "question_option_id"]
   *
   * Permitted sort attributes: ["created_at", "position"]
   */
  questions: Array<Question>;
  /** The root advert this advert belongs to if it is a variation. */
  rootAdvert?: Maybe<Advert>;
  /** The submission state of the Advert. */
  submissionState: SubmissionState;
  /** The total number of deliverables ordered for the Advert. */
  totalDeliverables?: Maybe<Scalars['Int']>;
  /** Flag determining if a child advert should use the answers given to their parent. */
  useRootAdvertAnswers: Scalars['Boolean'];
};


/** An advert. */
export type AdvertAdvertsDeliverableFormatsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


/** An advert. */
export type AdvertAnswersArgs = {
  actual?: InputMaybe<Scalars['Boolean']>;
};


/** An advert. */
export type AdvertAssetsArgs = {
  assetType?: InputMaybe<AssetType>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An advert. */
export type AdvertConceptVariationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An advert. */
export type AdvertCopyVariationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An advert. */
export type AdvertDeliverablesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An advert. */
export type AdvertQuestionsArgs = {
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};

/** A container for arguments used when creating an advert */
export type AdvertCreateInput = {
  /** Deliverable formats to be used for the Advert. */
  advertsDeliverableFormatsAttributes?: InputMaybe<Array<AdvertsDeliverableFormatInput>>;
  /** Language variations to be assigned to the Advert. */
  languageVariationCodes?: InputMaybe<Array<Scalars['String']>>;
  /** The name of the advert. */
  name?: InputMaybe<Scalars['String']>;
  /** The total number of concept variations. */
  numConceptVariations?: InputMaybe<Scalars['Int']>;
  /** The total number of copy variations. */
  numCopyVariations?: InputMaybe<Scalars['Int']>;
  /**
   * The UUID of the order to be attached by this advert.
   * @deprecated Use `orderIntegrationKey` instead.
   */
  orderId?: InputMaybe<Scalars['ID']>;
  /** The integration key of the order to be attached by this advert. */
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when updating an advert */
export type AdvertUpdateInput = {
  /** Deliverable formats to be used for the Advert. */
  advertsDeliverableFormatsAttributes?: InputMaybe<Array<AdvertsDeliverableFormatInput>>;
  /** Asset UUIDs to be associated with the advert. */
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The UUID of the attached audience. */
  audienceIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** Designers to be assigned to the advert. */
  designerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** UUID of the target advert. */
  id?: InputMaybe<Scalars['ID']>;
  /** IntegrationKey of the target advert. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** Language variations to be assigned to the Advert. */
  languageVariationCodes?: InputMaybe<Array<Scalars['String']>>;
  /** The name of the advert. */
  name?: InputMaybe<Scalars['String']>;
  /** The total number of concept variations. */
  numConceptVariations?: InputMaybe<Scalars['Int']>;
  /** The total number of copy variations. */
  numCopyVariations?: InputMaybe<Scalars['Int']>;
  /** The campaign objective of the advert. */
  objective?: InputMaybe<Objective>;
  /** The production notes of the Advert. */
  productionNotes?: InputMaybe<EncodedString>;
  /** Flag determining if a child advert should use the answers given to their parent. */
  useRootAdvertAnswers?: InputMaybe<Scalars['Boolean']>;
};

/** A paginated array of Adverts */
export type Adverts = {
  __typename?: 'Adverts';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Adverts */
  nodes: Array<Advert>;
};

/** An adverts deliverable format type. */
export type AdvertsDeliverableFormat = {
  __typename?: 'AdvertsDeliverableFormat';
  /** The deliverable format assigned to the Advert. */
  deliverableFormat: DeliverableFormat;
  /** The dimension of deliverable. */
  dimension?: Maybe<Dimension>;
  /** The length of deliverable. */
  formatLength: FormatLength;
  /** Flags the AdvertDeliverableFormat as the hero candidate. */
  hero: Scalars['Boolean'];
  /** The UUID of the AdvertsDeliverableFormat. */
  id: Scalars['ID'];
};

/** A container for arguments used when creating an adverts deliverable format */
export type AdvertsDeliverableFormatInput = {
  /** The UUID of the deliverable format to be attached by this advert. */
  deliverableFormatId: Scalars['ID'];
  /** The dimensions of requested deliverables. */
  dimensions: Array<DeliverableFormatDimensionInput>;
};

/** A paginated array of AdvertsDeliverableFormats */
export type AdvertsDeliverableFormats = {
  __typename?: 'AdvertsDeliverableFormats';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of AdvertsDeliverableFormats */
  nodes: Array<AdvertsDeliverableFormat>;
};

export enum AgeRange {
  Age18To24 = 'Age18To24',
  Age25To34 = 'Age25To34',
  Age35To44 = 'Age35To44',
  Age45To54 = 'Age45To54',
  Age55To64 = 'Age55To64',
  Age65Plus = 'Age65Plus'
}

export enum AgeRangePreference {
  Age18To24 = 'Age18To24',
  Age25To34 = 'Age25To34',
  Age35To44 = 'Age35To44',
  Age45To54 = 'Age45To54',
  Age55To64 = 'Age55To64',
  Age65Plus = 'Age65Plus',
  Unspecified = 'Unspecified'
}

export type AnalyticsDashboard = {
  __typename?: 'AnalyticsDashboard';
  /** The URL to embed the dashboard. */
  embedUrl: Scalars['String'];
  /** The expiry time of the embed URL. */
  expiry: Scalars['Time'];
};

export type AnalyticsDashboards = {
  __typename?: 'AnalyticsDashboards';
  /** The client insights dashboard. */
  clientInsights: AnalyticsDashboard;
};

/** Representing a single contiguous pen stroke on the canvas. */
export type Annotation = {
  __typename?: 'Annotation';
  /** Json string containing all data needed to reconstruct an annotation */
  data: Scalars['String'];
  id: Scalars['ID'];
};

export type AnnotationInput = {
  data: Scalars['String'];
};

/** The answer to a question asked during submission of an order */
export type Answer = {
  __typename?: 'Answer';
  /** The answer in English */
  englishResponseText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderId: Scalars['ID'];
  question: Question;
  /** @deprecated Use `question.id` instead. */
  questionId: Scalars['ID'];
  questionLabel?: Maybe<Scalars['String']>;
  responseText?: Maybe<Scalars['String']>;
};

/** A container for arguments used when answering a Question */
export type AnswerCreateInput = {
  questionId: Scalars['ID'];
  responseText?: InputMaybe<EncodedString>;
};

/** Autogenerated input type of AnswerQuestions */
export type AnswerQuestionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  resource: BulkAnswersInput;
};

/** Autogenerated return type of AnswerQuestions. */
export type AnswerQuestionsPayload = {
  __typename?: 'AnswerQuestionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

/** A paginated array of Answers */
export type Answers = {
  __typename?: 'Answers';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Answers */
  nodes: Array<Answer>;
};

export type ApproveBriefInput = {
  /** Integration key of the project brief to update. */
  projectIntegrationKey: Scalars['ID'];
};

/** Autogenerated input type of ApproveDeliverables */
export type ApproveDeliverablesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Order to be approved. */
  id: Scalars['ID'];
};

/** Autogenerated return type of ApproveDeliverables. */
export type ApproveDeliverablesPayload = {
  __typename?: 'ApproveDeliverablesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The approved Order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of ArchiveAsset */
export type ArchiveAssetInput = {
  asset: AssetArchiveInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ArchiveAsset. */
export type ArchiveAssetPayload = {
  __typename?: 'ArchiveAssetPayload';
  asset?: Maybe<Asset>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ArchiveAssets */
export type ArchiveAssetsInput = {
  /** Integration keys of the assets to be archived. */
  assetIntegrationKeys: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The integration key of the branded order to be detached from assets. */
  orderIntegrationKey: Scalars['ID'];
};

/** Autogenerated return type of ArchiveAssets. */
export type ArchiveAssetsPayload = {
  __typename?: 'ArchiveAssetsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ArchiveUgcAssetsInput = {
  /** Integration keys of the UGC Assets to archive */
  keys: Array<Scalars['ID']>;
};

export type ArchiveUgcOrderInput = {
  /** Integration key of the UGC Order to archive */
  key: Scalars['ID'];
};

export type ArchiveUgcDeliverablesInput = {
  /** Integration keys of the UGC Deliverables to archive */
  keys: Array<Scalars['ID']>;
};

export type ArchiveUsersInput = {
  /** Integration keys of the users */
  keys: Array<Scalars['ID']>;
};

export enum ArchivedFilter {
  /** Return only active records */
  False = 'FALSE',
  /** Return all records */
  Include = 'INCLUDE',
  /** Return only archived records */
  True = 'TRUE'
}

/** A file uploaded by the client to be used by designers */
export type Asset = {
  __typename?: 'Asset';
  assetType: AssetType;
  /**
   * Branded Orders attached to the Asset.
   *
   * Permitted search attributes: ["id",
   * "adverts_deliverable_formats_platform_name", "adverts_objective_name",
   * "adverts_original_input_type_name",
   * "approved_deliverables_deliverable_format_filter_name",
   * "approved_deliverables_deliverable_format_platform_name",
   * "approved_deliverables_duration_filter", "client_name",
   * "client_vertical_name", "hubspot_id", "name", "objective_name", "region_name",
   * "serial_id", "state_name", "best_of", "by_effective_state", "client_ancestry",
   * "client_id", "completed_at", "enterprise_ancestry", "full_search_cont",
   * "group_ancestry", "region_ancestry", "state", "submitted_at", "type",
   * "user_id", "eligible_to_create_variations", "client_ancestry_or_client_id",
   * "client_client_partners_id", "client_creative_specialists_id",
   * "client_production_managers_id"]
   *
   * Permitted sort attributes: ["created_at", "active_states", "best_of",
   * "brand_name", "client_name", "completed_at", "created_at", "enterprise_name",
   * "name", "state", "submitted_at", "target_date", "target_date_nulls",
   * "total_deliverables_ordered"]
   */
  brandedOrders: Orders;
  client?: Maybe<ClientBrand>;
  clientIntegrationKey: Scalars['ID'];
  format: Scalars['String'];
  id: Scalars['ID'];
  integrationKey: Scalars['ID'];
  name: Scalars['String'];
  notes: Array<AssetNote>;
  presignedUrl: Scalars['String'];
  size: Scalars['BigInt'];
  storageKey: Scalars['String'];
  /** The url to the thumbnail for this Asset. This will be null for unsupported asset types */
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};


/** A file uploaded by the client to be used by designers */
export type AssetBrandedOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** A file uploaded by the client to be used by designers */
export type AssetThumbnailUrlArgs = {
  width?: InputMaybe<Scalars['Int']>;
};

/** A container for arguments used when archiving an asset */
export type AssetArchiveInput = {
  /** The UUID of asset. */
  id: Scalars['ID'];
  /**
   * The UUID of the branded order to be detached from asset.
   * @deprecated Use `order_integration_key` instead.
   */
  orderId?: InputMaybe<Scalars['ID']>;
  /** The integration key of the branded order to be detached from asset. */
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when creating an asset */
export type AssetCreateInput = {
  /** The asset type of the asset */
  assetType: AssetType;
  /** Integration key of the client brand the asset belongs to. */
  brandIntegrationKey?: InputMaybe<Scalars['ID']>;
  /**
   * The ID of the client the asset belongs to.
   * @deprecated Use `clientIntegrationKey` instead.
   */
  clientId?: InputMaybe<Scalars['ID']>;
  /** The file format of the asset */
  format: Scalars['String'];
  /** The file name of the asset */
  name: Scalars['String'];
  /**
   * UUID of an order to attach the new asset to.
   * @deprecated Use `orderIntegrationKey` instead.
   */
  orderId?: InputMaybe<Scalars['ID']>;
  /** Integration key of an order to attach the new asset to. */
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** The file size of the asset */
  size: Scalars['BigInt'];
  /** The AWS url where the asset is stored */
  url: Scalars['String'];
};

export type AssetLink = {
  __typename?: 'AssetLink';
  /** Notes of the asset. */
  note?: Maybe<Scalars['String']>;
  /** URL of the asset. */
  url?: Maybe<Scalars['String']>;
};

export type AssetLinkInput = {
  /** Notes of the asset. */
  note?: InputMaybe<Scalars['String']>;
  /** URL of the asset. */
  url?: InputMaybe<Scalars['String']>;
};

/** A note attached to an Asset */
export type AssetNote = {
  __typename?: 'AssetNote';
  content: Scalars['String'];
  createdAt: Scalars['BigInt'];
  id: Scalars['ID'];
  updatedAt: Scalars['BigInt'];
};

export enum AssetType {
  AssetZip = 'asset_zip',
  Audio = 'audio',
  Brand = 'brand',
  BrandLogo = 'brand_logo',
  CombinedZip = 'combined_zip',
  Compressed = 'compressed',
  DeliverablesZip = 'deliverables_zip',
  Font = 'font',
  Logo = 'logo',
  Music = 'music',
  Reference = 'reference',
  Stills = 'stills',
  TextField = 'text_field',
  Video = 'video',
  WorkingFilesZip = 'working_files_zip'
}

/** A paginated array of Assets */
export type Assets = {
  __typename?: 'Assets';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Assets */
  nodes: Array<Asset>;
};

/** The async job */
export type AsyncJob = {
  __typename?: 'AsyncJob';
  data: Scalars['JSON'];
  id: Scalars['ID'];
};

export type Attachment = {
  __typename?: 'Attachment';
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type AttachmentInput = {
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  s3Key: Scalars['String'];
};

/** An audience. */
export type Audience = {
  __typename?: 'Audience';
  ageRanges?: Maybe<Array<AgeRange>>;
  brandIntegrationKey: Scalars['ID'];
  gender?: Maybe<TargetGender>;
  id: Scalars['ID'];
  incomeLifestyle?: Maybe<Scalars['String']>;
  /** @deprecated Use key instead. */
  integrationKey: Scalars['ID'];
  interestsAndBehaviours?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  /** The integration key of the audience. */
  key: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum AudienceAgeRange {
  Age18To24 = 'Age18To24',
  Age25To34 = 'Age25To34',
  Age35To44 = 'Age35To44',
  Age45To54 = 'Age45To54',
  Age55To64 = 'Age55To64',
  Age65Plus = 'Age65Plus'
}

export enum AudienceGender {
  All = 'All',
  Female = 'Female',
  Male = 'Male'
}

export type AudienceSubstitution = {
  __typename?: 'AudienceSubstitution';
  currentAudience?: Maybe<Audience>;
  integrationKey?: Maybe<Scalars['ID']>;
};

/** A container for arguments used when updating the auth0_integration_key of a user. */
export type Auth0IntegrationKeyUpdateInput = {
  /** Auth0 User Id to be assigned to the user as an Integration Key. */
  auth0IntegrationKey: Scalars['ID'];
  /** UUID of the target user. */
  id: Scalars['ID'];
};

export type Auth0User = {
  __typename?: 'Auth0User';
  /** The auth0 identifier for the user. */
  auth0Id: Scalars['String'];
  /**
   * A flag indicating if this users personal details can be updated.
   * (email, name, picture etc.)
   *
   * Users who sign in using a social account (e.g. Google) will need
   * to manage their personal details via their social account instead.
   */
  canUpdatePersonalDetails: Scalars['Boolean'];
  /** The user profile associated with this auth0 user. */
  user?: Maybe<User>;
};

/**
 * Author of a revision or comment.
 *
 * This type should be replaced by the user type in the future.
 */
export type Author = {
  __typename?: 'Author';
  /** Avatar URL. */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Email address. */
  email: Scalars['String'];
  /** User ID. */
  id?: Maybe<Scalars['String']>;
  /** Full name. */
  name: Scalars['String'];
};

/** A container for arguments used when creating an asset */
export type BrandLogoCreateInput = {
  /** The integration key of the brand the asset belongs to. */
  brandIntegrationKey?: InputMaybe<Scalars['String']>;
  /** The ID of the client the asset belongs to. */
  clientId?: InputMaybe<Scalars['ID']>;
  /** The file format of the asset */
  format: Scalars['String'];
  /** The file name of the asset */
  name: Scalars['String'];
  /** The file size of the asset */
  size: Scalars['BigInt'];
  /** The AWS url where the asset is stored */
  url: Scalars['String'];
};

export type BrandProfile = {
  __typename?: 'BrandProfile';
  assetSources: Array<Scalars['String']>;
  audiences: Array<Audience>;
  brandAssets: Array<Scalars['ID']>;
  brandGuidelines?: Maybe<Scalars['String']>;
  brandIntegrationKey: Scalars['ID'];
  brandNotes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationKey: Scalars['ID'];
  /** The market assigned to the Brand Profile */
  market?: Maybe<Market>;
  personality?: Maybe<Scalars['String']>;
  productionNotes?: Maybe<Scalars['String']>;
  socialChannels: Array<Scalars['String']>;
};

/** Scopes for a given Brand. */
export type BrandScopes = {
  __typename?: 'BrandScopes';
  canCreateBrandProfile: Scalars['Boolean'];
  canShowBrandProfile: Scalars['Boolean'];
  canUpdateBrandProfile: Scalars['Boolean'];
};

/** A container for arguments used when reverting an order to UGC Ideation. */
export type BriefRevertToUgcIdeationInput = {
  /** Integration key of the concept. */
  conceptKey: Scalars['ID'];
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order. */
  integrationKey: Scalars['ID'];
};

/** Autogenerated input type of BriefSubmittedForAssetProduction */
export type BriefSubmittedForAssetProductionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the project to be confirmed. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of BriefSubmittedForAssetProduction. */
export type BriefSubmittedForAssetProductionPayload = {
  __typename?: 'BriefSubmittedForAssetProductionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The submitted order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of BriefSubmittedForCsReview */
export type BriefSubmittedForCsReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be submitted. */
  order: SubmittedBriefForCsReviewInput;
};

/** Autogenerated return type of BriefSubmittedForCsReview. */
export type BriefSubmittedForCsReviewPayload = {
  __typename?: 'BriefSubmittedForCsReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The submitted order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of BriefSubmittedForProductionReview */
export type BriefSubmittedForProductionReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the project to be confirmed. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** The integration key of the user who submitted the order. */
  userIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of BriefSubmittedForProductionReview. */
export type BriefSubmittedForProductionReviewPayload = {
  __typename?: 'BriefSubmittedForProductionReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The optimized project. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of BriefSubmittedForUgcIdeation */
export type BriefSubmittedForUgcIdeationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be submitted. */
  order: UgcIdeationSubmissionInput;
};

/** Autogenerated return type of BriefSubmittedForUgcIdeation. */
export type BriefSubmittedForUgcIdeationPayload = {
  __typename?: 'BriefSubmittedForUgcIdeationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The submitted order. */
  order?: Maybe<Order>;
};

/** A container for arguments used when answering Questions in bulk. */
export type BulkAnswersInput = {
  /** @deprecated Use `advertIntegrationKey` instead. */
  advertId?: InputMaybe<Scalars['ID']>;
  advertIntegrationKey?: InputMaybe<Scalars['ID']>;
  answers: Array<AnswerCreateInput>;
  /** @deprecated Use `orderIntegrationKey` instead. */
  orderId?: InputMaybe<Scalars['ID']>;
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when creating Assets in bulk. */
export type BulkAssetsInput = {
  /** Details of the assets to create. */
  assets: Array<AssetCreateInput>;
  /** Integration key of the client brand the asset belongs to. */
  brandIntegrationKey?: InputMaybe<Scalars['ID']>;
  /**
   * UUID of the client the assets belongs to.
   * @deprecated Use `clientIntegrationKey` instead.
   */
  clientId?: InputMaybe<Scalars['ID']>;
  /**
   * UUID of the order the assets belongs to.
   * @deprecated Use `integrationKey` instead.
   */
  orderId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order the assets belongs to. */
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** CES rating enumeration. */
export enum CesRating {
  /**
   * Difficult.
   * @deprecated Use `Two` instead.
   */
  Difficult = 'Difficult',
  /**
   * Easy.
   * @deprecated Use `Six` instead.
   */
  Easy = 'Easy',
  /** Somewhat easy/satisfied. */
  Five = 'Five',
  /** Neutral. */
  Four = 'Four',
  /**
   * Neither easy or difficult.
   * @deprecated Use `Four` instead.
   */
  Neutral = 'Neutral',
  /** Extremely difficult/unsatisfied. */
  One = 'One',
  /** Extremely easy/satisfied. */
  Seven = 'Seven',
  /** Easy/Satisfied. */
  Six = 'Six',
  /**
   * Somewhat difficult.
   * @deprecated Use `Three` instead.
   */
  SomewhatDifficult = 'SomewhatDifficult',
  /**
   * Somewhat easy.
   * @deprecated Use `Five` instead.
   */
  SomewhatEasy = 'SomewhatEasy',
  /** Somewhat difficult/unsatisfied. */
  Three = 'Three',
  /** Difficult/Unsatisfied. */
  Two = 'Two',
  /**
   * Very difficult.
   * @deprecated Use `One` instead.
   */
  VeryDifficult = 'VeryDifficult',
  /**
   * Very easy.
   * @deprecated Use `Seven` instead.
   */
  VeryEasy = 'VeryEasy'
}

export type ClaimInvitationInput = {
  /** Unique key of the invitation to claim */
  key: Scalars['String'];
};

/** Autogenerated input type of CleanupE2eTestData */
export type CleanupE2eTestDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CleanupE2eTestData. */
export type CleanupE2eTestDataPayload = {
  __typename?: 'CleanupE2eTestDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** A base client. */
export type Client = {
  /** The license details for Clients with the License account type. */
  accountLicense?: Maybe<AccountLicense>;
  /** The license plus details for Clients with the License Plus account type. */
  accountLicensePlus?: Maybe<AccountLicensePlus>;
  /** Whether or not this clients account is required to submit CES feedback. */
  accountRequiresCes: Scalars['Boolean'];
  /** The account type of the Client. */
  accountType: AccountType;
  /** The number of active deliverables for a subscription account type. */
  activeDeliverables?: Maybe<Scalars['Int']>;
  /** The number of active hero deliverables for a license account type. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** Ancestor of the current Client at a given depth. */
  ancestor?: Maybe<ClientGroup>;
  /** The ancestry depth of the Client */
  ancestryDepth: Scalars['Int'];
  /** Brand guidelines URL relevant to the Client. */
  brandGuidelinesUrl?: Maybe<Scalars['String']>;
  /** The name of the brand Client refers to. */
  brandName?: Maybe<Scalars['String']>;
  /** Flag used to determine if the Client can be discarded. */
  canDiscard: Scalars['Boolean'];
  /** The Key of the client account (root client) is associated with. */
  clientAccountIntegrationKey?: Maybe<Scalars['ID']>;
  /** Client Partners assigned to the Client. */
  clientPartners: Array<LegacyUser>;
  /** Creative Specialists assigned to the Client. */
  creativeSpecialists: Array<LegacyUser>;
  /** Description of the Client and the service they offer. */
  description?: Maybe<Scalars['String']>;
  /** Facebook URL relevant to the Client. */
  facebookUrl?: Maybe<Scalars['String']>;
  /** The feature flags of the Client. */
  featureFlags: Array<FeatureFlag>;
  /** Hubspot Company ID linking the record to Shuttlerock's sales database. */
  hubspotId?: Maybe<Scalars['BigInt']>;
  /** The UUID of the Client. */
  id: Scalars['ID'];
  /** Instagram URL relevant to the Client. */
  instagramUrl?: Maybe<Scalars['String']>;
  /** A surrogate UUID used to identify a client when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** Flag used to determine if the client allows logged out users to download their deliverables. */
  loggedOutDownloading: Scalars['Boolean'];
  /** The maximum number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables: Scalars['Int'];
  /** The name of the Client. */
  name: Scalars['String'];
  /** Other URL relevant to the Client. */
  otherUrl?: Maybe<Scalars['String']>;
  /** Pinterest URL relevant to the Client. */
  pinterestUrl?: Maybe<Scalars['String']>;
  /** Platforms assigned to the Client. */
  platforms: Array<Platform>;
  /** Flag used to determine if the client allows deliverables to be downloaded before they have been approved. */
  preApprovalDownloading: Scalars['Boolean'];
  /** Production Managers assigned to the Client. */
  productionManagers: Array<LegacyUser>;
  /** The sales region of the Client. */
  region: Region;
  /** Hubspot Subscription ID linking the record to Shuttlerock's sales database. */
  subscriptionId?: Maybe<Scalars['BigInt']>;
  /** Tiktok URL relevant to the Client. */
  tiktokUrl?: Maybe<Scalars['String']>;
  /** Twitter URL relevant to the Client. */
  twitterUrl?: Maybe<Scalars['String']>;
  /** The type of the client */
  type: ClientType;
  /** Industry category used to define the Client. */
  vertical?: Maybe<Vertical>;
  /** Website URL relevant to the Client. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** Youtube URL relevant to the Client. */
  youtubeUrl?: Maybe<Scalars['String']>;
};


/** A base client. */
export type ClientAncestorArgs = {
  ancestryDepth?: InputMaybe<Scalars['Int']>;
};

export type ClientAccount = {
  __typename?: 'ClientAccount';
  /**
   * The active HubSpot deals associated with this client account.
   * Active deals are deals that can currently have new projects associated with them.
   *
   * Requires authorization and will be null without the correct permissions.
   */
  activeDeals?: Maybe<Array<HubspotDeal>>;
  /**  Determines whether generative AI preferences can be changed for this client account.  */
  canUpdateGenerativeAIPreferences: Scalars['Boolean'];
  /**  Determines whether generative AI is enabled for this client account.  */
  enableGenerativeAI: Scalars['Boolean'];
  /**  Determines whether platform AI is enabled for this client account.  */
  enablePlatformAI: Scalars['Boolean'];
  /**  Determines whether UGC ordering is enabled for this client account.  */
  enableUgcOrdering: Scalars['Boolean'];
  /**  The generative AI preferences associated with this client account.  */
  generativeAIPreferences: Array<GenerativeAiPreference>;
  id: Scalars['ID'];
  /** @deprecated Use 'key' instead */
  integrationKey: Scalars['ID'];
  /**  The CreativeX integration for the client account.  */
  integrations: ClientAccountIntegrations;
  key: Scalars['ID'];
  limits: Array<AccountLimit>;
  name?: Maybe<Scalars['String']>;
  organizationalUnit: OrganizationalUnit;
  /**
   * Determines whether projects for this client require a HubSpot deal to be associated with them.
   * If true, projects must have a HubSpot deal associated before moving the project to production review.
   */
  requireHubspotDeals: Scalars['Boolean'];
  usage: Array<AccountUsage>;
};

/**  Input for updating generative AI preferences for a client account.  */
export type ClientAccountGenerativeAiPreferencesInput = {
  /**  The key of the client account to update.  */
  key: Scalars['ID'];
  /**  The preferred models for each category of generative AI.  */
  preferredModels: Array<AiModelPreferenceInput>;
};

export type ClientAccountIntegrations = {
  __typename?: 'ClientAccountIntegrations';
  /** Whether the client can update the CreativeX integration */
  canUpdateCreativeXIntegration: Scalars['Boolean'];
  /**  The integrations for the client account.  */
  clientAccountKey: Scalars['ID'];
  /** The CreativeX integration for the client account */
  creativeX?: Maybe<CreativeXIntegration>;
};

/** Scopes for a given client account. */
export type ClientAccountScopes = {
  __typename?: 'ClientAccountScopes';
  canInviteUser: Scalars['Boolean'];
};

export type ClientBrand = Client & PermittedAttributes & {
  __typename?: 'ClientBrand';
  /** The license details for Clients with the License account type. */
  accountLicense?: Maybe<AccountLicense>;
  /** The license plus details for Clients with the License Plus account type. */
  accountLicensePlus?: Maybe<AccountLicensePlus>;
  /** Whether or not this clients account is required to submit CES feedback. */
  accountRequiresCes: Scalars['Boolean'];
  /** The account type of the Client. */
  accountType: AccountType;
  /** The number of active deliverables for a subscription account type. */
  activeDeliverables?: Maybe<Scalars['Int']>;
  /** The number of active hero deliverables for a license account type. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** Ancestor of the current Client at a given depth. */
  ancestor?: Maybe<ClientGroup>;
  /** The ancestry depth of the Client */
  ancestryDepth: Scalars['Int'];
  /** An array of asset sources that can be considered to supplement the provided assets. */
  assetSources?: Maybe<Array<Scalars['String']>>;
  /**
   * Assets belonging to the Client.
   *
   * Permitted search attributes: ["id", "name", "asset_type"]
   *
   * Permitted sort attributes: ["created_at"]
   * @deprecated Retrieve brand assets via creative-customer-api instead.
   */
  assets: Assets;
  /** Brand guidelines URL relevant to the Client. */
  brandGuidelinesUrl?: Maybe<Scalars['String']>;
  /** The name of the brand Client refers to. */
  brandName?: Maybe<Scalars['String']>;
  /** The brand notes of the Client. */
  brandNotes?: Maybe<Scalars['String']>;
  /** Flag used to determine if the Client can be discarded. */
  canDiscard: Scalars['Boolean'];
  /** Whether the client can update the CreativeX brand */
  canUpdateCreativeXBrand: Scalars['Boolean'];
  /** Account associated with the brand */
  clientAccount: ClientAccount;
  /** The Key of the client account (root client) is associated with. */
  clientAccountIntegrationKey?: Maybe<Scalars['ID']>;
  /** Client Partners assigned to the Client. */
  clientPartners: Array<LegacyUser>;
  /** Creative Specialists assigned to the Client. */
  creativeSpecialists: Array<LegacyUser>;
  /** The assigned CreativeX brand */
  creativeXBrand?: Maybe<CreativeXBrand>;
  /** The default project visibility for projects created under this brand. */
  defaultProjectVisibility: ProjectVisibility;
  /** Description of the Client and the service they offer. */
  description?: Maybe<Scalars['String']>;
  /** Facebook URL relevant to the Client. */
  facebookUrl?: Maybe<Scalars['String']>;
  /** The feature flags of the Client. */
  featureFlags: Array<FeatureFlag>;
  /** Hubspot Company ID linking the record to Shuttlerock's sales database. */
  hubspotId?: Maybe<Scalars['BigInt']>;
  /** The UUID of the client brand. */
  id: Scalars['ID'];
  /** Instagram URL relevant to the Client. */
  instagramUrl?: Maybe<Scalars['String']>;
  /**
   * A surrogate UUID used to identify a client brand when integrating with external apps.
   * @deprecated Use `key` instead.
   */
  integrationKey: Scalars['ID'];
  /** A surrogate UUID used to identify a client brand when integrating with external apps. */
  key: Scalars['ID'];
  /** Flag used to determine if the client allows logged out users to download their deliverables. */
  loggedOutDownloading: Scalars['Boolean'];
  /** The maximum number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables: Scalars['Int'];
  /** The name of the Client. */
  name: Scalars['String'];
  /**
   * Orders submitted for the Client.
   *
   * Permitted search attributes: ["id",
   * "adverts_deliverable_formats_platform_name", "adverts_objective_name",
   * "adverts_original_input_type_name",
   * "approved_deliverables_deliverable_format_filter_name",
   * "approved_deliverables_deliverable_format_platform_name",
   * "approved_deliverables_duration_filter", "client_name",
   * "client_vertical_name", "hubspot_id", "name", "objective_name", "region_name",
   * "serial_id", "state_name", "best_of", "by_effective_state", "client_ancestry",
   * "client_id", "completed_at", "enterprise_ancestry", "full_search_cont",
   * "group_ancestry", "region_ancestry", "state", "submitted_at", "type",
   * "user_id", "eligible_to_create_variations", "client_ancestry_or_client_id",
   * "client_client_partners_id", "client_creative_specialists_id",
   * "client_production_managers_id"]
   *
   * Permitted sort attributes: ["created_at", "active_states", "best_of",
   * "brand_name", "client_name", "completed_at", "created_at", "enterprise_name",
   * "name", "state", "submitted_at", "target_date", "target_date_nulls",
   * "total_deliverables_ordered"]
   */
  orders: Orders;
  organizationalUnit: OrganizationalUnit;
  /** Other URL relevant to the Client. */
  otherUrl?: Maybe<Scalars['String']>;
  permittedAttributes: Scalars['JSON'];
  /** Pinterest URL relevant to the Client. */
  pinterestUrl?: Maybe<Scalars['String']>;
  /** Platforms assigned to the Client. */
  platforms: Array<Platform>;
  /** Flag used to determine if the client allows deliverables to be downloaded before they have been approved. */
  preApprovalDownloading: Scalars['Boolean'];
  /** Production Managers assigned to the Client. */
  productionManagers: Array<LegacyUser>;
  /** The production notes of the Client. */
  productionNotes?: Maybe<Scalars['String']>;
  /** The sales region of the Client. */
  region: Region;
  /** The submission state of the Client's assets. */
  submissionState: SubmissionState;
  /** Hubspot Subscription ID linking the record to Shuttlerock's sales database. */
  subscriptionId?: Maybe<Scalars['BigInt']>;
  /** Tiktok URL relevant to the Client. */
  tiktokUrl?: Maybe<Scalars['String']>;
  /** Twitter URL relevant to the Client. */
  twitterUrl?: Maybe<Scalars['String']>;
  /** The type of the client */
  type: ClientType;
  /** Industry category used to define the Client. */
  vertical?: Maybe<Vertical>;
  /** Website URL relevant to the Client. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** Youtube URL relevant to the Client. */
  youtubeUrl?: Maybe<Scalars['String']>;
};


export type ClientBrandAncestorArgs = {
  ancestryDepth?: InputMaybe<Scalars['Int']>;
};


export type ClientBrandAssetsArgs = {
  assetType?: InputMaybe<AssetType>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


export type ClientBrandOrdersArgs = {
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortInput>>;
  state?: InputMaybe<OrderState>;
};

export enum ClientBrandPermission {
  /** Access for client brand to create a project. */
  ClientBrandPermissionCreateProject = 'CLIENT_BRAND_PERMISSION_CREATE_PROJECT',
  /** Access for client brand to create a ugc order. */
  ClientBrandPermissionCreateUgcOrder = 'CLIENT_BRAND_PERMISSION_CREATE_UGC_ORDER',
  /** Access for client brand to read a client account. */
  ClientBrandPermissionReadClientBrand = 'CLIENT_BRAND_PERMISSION_READ_CLIENT_BRAND',
  /** NOT A REAL PERMISSION, DO NOT USE */
  ClientBrandPermissionUnspecified = 'CLIENT_BRAND_PERMISSION_UNSPECIFIED'
}

/** A container for arguments used when creating a client. */
export type ClientCreateInput = {
  /** The account type of the client. */
  accountType?: InputMaybe<AccountType>;
  /** The expiry date of the hero balance of a License Plus account type. */
  balanceExpiresAt?: InputMaybe<Scalars['ISO8601Date']>;
  /** A flag to determine if the client is brand. */
  brand?: InputMaybe<Scalars['Boolean']>;
  /** Brand guidelines URL relevant to the client. */
  brandGuidelinesUrl?: InputMaybe<Scalars['String']>;
  /** The brand notes of the client. */
  brandNotes?: InputMaybe<Scalars['String']>;
  /** An array of user UUIDs to be assigned as client partners for this client. */
  clientPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** An array of user UUIDs to be assigned as creative specialists for this client. */
  creativeSpecialistIds?: InputMaybe<Array<Scalars['ID']>>;
  /** A brief description of the client and the service they offer. */
  description?: InputMaybe<Scalars['String']>;
  /** Facebook URL relevant to the client. */
  facebookUrl?: InputMaybe<Scalars['String']>;
  /** The feature flags of the client. */
  featureFlags?: InputMaybe<Array<FeatureFlag>>;
  /** The hero balance available for a License Plus account type. */
  heroBalance?: InputMaybe<Scalars['Int']>;
  /** The Hubspot ID used to link the record to Shuttlerock's sales database. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /** A flag to determine if the client should inherit vertical, region, platforms and collaborators from its parent. */
  inheritParentFields?: InputMaybe<Scalars['Boolean']>;
  /** Instagram URL relevant to the client. */
  instagramUrl?: InputMaybe<Scalars['String']>;
  /** A flag to determine if deliverables for this client can be downloaded by logged out users. */
  loggedOutDownloading?: InputMaybe<Scalars['Boolean']>;
  /** The number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables?: InputMaybe<Scalars['Int']>;
  /** The number of active heroes allowed for a License account type. */
  maxActiveHeroes?: InputMaybe<Scalars['Int']>;
  /** The name of the client. */
  name: Scalars['String'];
  /** Other URL relevant to the client. */
  otherUrl?: InputMaybe<Scalars['String']>;
  /**
   * The UUID of the parent client.
   * @deprecated Use `parentKey` instead.
   */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The UUID IntegrationKey of the parent client. */
  parentKey?: InputMaybe<Scalars['ID']>;
  /** Pinterest URL relevant to the client. */
  pinterestUrl?: InputMaybe<Scalars['String']>;
  /** Platforms to be made available to the client. */
  platformIds?: InputMaybe<Array<Scalars['ID']>>;
  /** A flag to determine if the client may download deliverables prior to approval. */
  preApprovalDownloading?: InputMaybe<Scalars['Boolean']>;
  /** An array of user UUIDs to be assigned as production manager for this client. */
  productionManagerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The name of the sales region that the client belongs to. */
  region?: InputMaybe<Scalars['String']>;
  /** Tiktok URL relevant to the client. */
  tiktokUrl?: InputMaybe<Scalars['String']>;
  /** Twitter URL relevant to the client. */
  twitterUrl?: InputMaybe<Scalars['String']>;
  /** The UUID of the industry vertical the client belongs to (i.e: travel, education). */
  verticalId?: InputMaybe<Scalars['ID']>;
  /** Website URL relevant to the client. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Youtube URL relevant to the client. */
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

export type ClientGroup = Client & PermittedAttributes & {
  __typename?: 'ClientGroup';
  /** The license details for Clients with the License account type. */
  accountLicense?: Maybe<AccountLicense>;
  /** The license plus details for Clients with the License Plus account type. */
  accountLicensePlus?: Maybe<AccountLicensePlus>;
  /** Whether or not this clients account is required to submit CES feedback. */
  accountRequiresCes: Scalars['Boolean'];
  /** The account type of the Client. */
  accountType: AccountType;
  /** The number of active deliverables for a subscription account type. */
  activeDeliverables?: Maybe<Scalars['Int']>;
  /** The number of active hero deliverables for a license account type. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** Ancestor of the current Client at a given depth. */
  ancestor?: Maybe<ClientGroup>;
  /** The ancestry depth of the Client */
  ancestryDepth: Scalars['Int'];
  /** Brand guidelines URL relevant to the Client. */
  brandGuidelinesUrl?: Maybe<Scalars['String']>;
  /** The name of the brand Client refers to. */
  brandName?: Maybe<Scalars['String']>;
  /** Flag used to determine if the Client can be discarded. */
  canDiscard: Scalars['Boolean'];
  /**
   * Clients who are set as children of the Client.
   *
   * Permitted search attributes: ["id", "account_type_name", "hubspot_id", "name",
   * "region_name", "account_type", "ancestry", "enterprise_ancestry",
   * "region_ancestry", "group_ancestry", "brands", "enterprises", "groups",
   * "region_groups", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "name", "region_name"]
   */
  children: Clients;
  /** The Key of the client account (root client) is associated with. */
  clientAccountIntegrationKey?: Maybe<Scalars['ID']>;
  /** Client Partners assigned to the Client. */
  clientPartners: Array<LegacyUser>;
  /** Creative Specialists assigned to the Client. */
  creativeSpecialists: Array<LegacyUser>;
  /** Description of the Client and the service they offer. */
  description?: Maybe<Scalars['String']>;
  /** Facebook URL relevant to the Client. */
  facebookUrl?: Maybe<Scalars['String']>;
  /** The feature flags of the Client. */
  featureFlags: Array<FeatureFlag>;
  /** Hubspot Company ID linking the record to Shuttlerock's sales database. */
  hubspotId?: Maybe<Scalars['BigInt']>;
  /** The UUID of the Client. */
  id: Scalars['ID'];
  /** Instagram URL relevant to the Client. */
  instagramUrl?: Maybe<Scalars['String']>;
  /** A surrogate UUID used to identify a client when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** Flag used to determine if the client allows logged out users to download their deliverables. */
  loggedOutDownloading: Scalars['Boolean'];
  /** The maximum number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables: Scalars['Int'];
  /** The name of the Client. */
  name: Scalars['String'];
  /** The number of available brands for this client. */
  numBrands: Scalars['Int'];
  /** The number of groups for this client. */
  numGroups: Scalars['Int'];
  /** Other URL relevant to the Client. */
  otherUrl?: Maybe<Scalars['String']>;
  permittedAttributes: Scalars['JSON'];
  /** Pinterest URL relevant to the Client. */
  pinterestUrl?: Maybe<Scalars['String']>;
  /** Platforms assigned to the Client. */
  platforms: Array<Platform>;
  /** Flag used to determine if the client allows deliverables to be downloaded before they have been approved. */
  preApprovalDownloading: Scalars['Boolean'];
  /** Production Managers assigned to the Client. */
  productionManagers: Array<LegacyUser>;
  /** The sales region of the Client. */
  region: Region;
  /** Hubspot Subscription ID linking the record to Shuttlerock's sales database. */
  subscriptionId?: Maybe<Scalars['BigInt']>;
  /** Tiktok URL relevant to the Client. */
  tiktokUrl?: Maybe<Scalars['String']>;
  /** Twitter URL relevant to the Client. */
  twitterUrl?: Maybe<Scalars['String']>;
  /** The type of the client */
  type: ClientType;
  /** Industry category used to define the Client. */
  vertical?: Maybe<Vertical>;
  /** Website URL relevant to the Client. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** Youtube URL relevant to the Client. */
  youtubeUrl?: Maybe<Scalars['String']>;
};


export type ClientGroupAncestorArgs = {
  ancestryDepth?: InputMaybe<Scalars['Int']>;
};


export type ClientGroupChildrenArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};

export enum ClientType {
  DirectClient = 'DirectClient',
  EnterpriseBrandClient = 'EnterpriseBrandClient',
  EnterpriseClient = 'EnterpriseClient',
  EnterpriseGroup = 'EnterpriseGroup',
  EnterpriseRegion = 'EnterpriseRegion'
}

/** A container for arguments used when updating a client. */
export type ClientUpdateInput = {
  /** The account type of the client. */
  accountType?: InputMaybe<AccountType>;
  /** An array of asset sources that can be considered to supplement the provided assets. */
  assetSources?: InputMaybe<Array<Scalars['String']>>;
  /** The expiry date of the hero balance of a License Plus account type. */
  balanceExpiresAt?: InputMaybe<Scalars['ISO8601Date']>;
  /** Brand guidelines URL relevant to the client. */
  brandGuidelinesUrl?: InputMaybe<Scalars['String']>;
  /** The brand notes of the client. */
  brandNotes?: InputMaybe<EncodedString>;
  /** An array of user UUIDs to be assigned as client partners for this client. */
  clientPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** An array of user UUIDs to be assigned as creative specialists for this client. */
  creativeSpecialistIds?: InputMaybe<Array<Scalars['ID']>>;
  /** A brief description of the client and the service they offer. */
  description?: InputMaybe<Scalars['String']>;
  /** Facebook URL relevant to the client. */
  facebookUrl?: InputMaybe<Scalars['String']>;
  /** The feature flags of the client. */
  featureFlags?: InputMaybe<Array<FeatureFlag>>;
  /** The hero balance available for a License Plus account type. */
  heroBalance?: InputMaybe<Scalars['Int']>;
  /** The Hubspot ID used to link the record to Shuttlerock's sales database. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /**
   * UUID of the target client.
   * @deprecated Use integrationKey instead
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Instagram URL relevant to the client. */
  instagramUrl?: InputMaybe<Scalars['String']>;
  /** SRC integration for the target client. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** A flag to determine if deliverables for this client can be downloaded by logged out users. */
  loggedOutDownloading?: InputMaybe<Scalars['Boolean']>;
  /** The number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables?: InputMaybe<Scalars['Int']>;
  /** The number of active heroes allowed for a License account type. */
  maxActiveHeroes?: InputMaybe<Scalars['Int']>;
  /** The name of the client. */
  name?: InputMaybe<Scalars['String']>;
  /** Other URL relevant to the client. */
  otherUrl?: InputMaybe<Scalars['String']>;
  /**
   * The UUID of the parent client.
   * @deprecated Use parent_key instead
   */
  parentId?: InputMaybe<Scalars['ID']>;
  /** SRC integration for the parent group. */
  parentKey?: InputMaybe<Scalars['ID']>;
  /** Pinterest URL relevant to the client. */
  pinterestUrl?: InputMaybe<Scalars['String']>;
  /** Platforms to be made available to the client. */
  platformIds?: InputMaybe<Array<Scalars['ID']>>;
  /** A flag to determine if the client may download deliverables prior to approval. */
  preApprovalDownloading?: InputMaybe<Scalars['Boolean']>;
  /** An array of user UUIDs to be assigned as production manager for this client. */
  productionManagerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The production notes of the client. */
  productionNotes?: InputMaybe<EncodedString>;
  /** The name of the sales region that the client belongs to. */
  region?: InputMaybe<Scalars['String']>;
  /** Tiktok URL relevant to the client. */
  tiktokUrl?: InputMaybe<Scalars['String']>;
  /** Twitter URL relevant to the client. */
  twitterUrl?: InputMaybe<Scalars['String']>;
  /** The UUID of the industry vertical the client belongs to (i.e: travel, education). */
  verticalId?: InputMaybe<Scalars['ID']>;
  /** Website URL relevant to the client. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Youtube URL relevant to the client. */
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

/** A paginated array of Clients */
export type Clients = {
  __typename?: 'Clients';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Clients */
  nodes: Array<Client>;
};

export type Collaborator = {
  __typename?: 'Collaborator';
  /** @deprecated Use `user` instead */
  integrationKey: Scalars['ID'];
  type: CollaboratorType;
  user: User;
};

export enum CollaboratorType {
  /** The client partner(s) for the brand. Added implicitly by the system. */
  ClientPartner = 'ClientPartner',
  /** The creative specialists(s) for the brand. Added implicitly by the system. */
  CreativeSpecialist = 'CreativeSpecialist',
  /** The production manager(s) for the brand. Added implicitly by the system. */
  ProductionManager = 'ProductionManager',
  /** The user that owns the project. Updated when the project is created and submitted */
  ProjectOwner = 'ProjectOwner',
  /** A collaborator that was added explicitly by a user. (i.e. via addCollaboratorsToProject mutation) */
  UserDefined = 'UserDefined'
}

/** Comment associated with a revision. */
export type Comment = {
  __typename?: 'Comment';
  /** The author of this comment. */
  author?: Maybe<Author>;
  /** Created at time. */
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  /** Is the author of this comment an internal SRC user. */
  isInternalAuthor: Scalars['Boolean'];
  /** Is the comment only viewable by internal users. */
  isInternalOnly: Scalars['Boolean'];
  /** Is the comment a rejection of a revision. */
  isRejection: Scalars['Boolean'];
  /** The body text of the comment. */
  message: Scalars['String'];
  /** Last updated at time. */
  updatedAt: Scalars['Time'];
};

/** Autogenerated input type of CompleteOrder */
export type CompleteOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be completed. */
  order: OrderCompleteInput;
};

/** Autogenerated return type of CompleteOrder. */
export type CompleteOrderPayload = {
  __typename?: 'CompleteOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The completed order, returned for the purpose of specifying what was completed. */
  order?: Maybe<Order>;
};

/** A Concept. */
export type Concept = {
  __typename?: 'Concept';
  /** The URLs of the audio assets of deliverables uploaded to this concept, sorted by newest first. */
  audioUrls: Array<Scalars['String']>;
  brief: ConceptBrief;
  /** Indicates whether the concept can be deleted. */
  canDelete: Scalars['Boolean'];
  /** Whether the concept contains AI. */
  containsAi: Scalars['Boolean'];
  id: Scalars['ID'];
  /** @deprecated use key instead */
  integrationKey: Scalars['ID'];
  /** The concept key. */
  key: Scalars['ID'];
  /** The latest audio type of a deliverable uploaded to this concept. */
  latestAudioType?: Maybe<DeliverableAudioType>;
  /** The name of the concept. */
  name?: Maybe<Scalars['String']>;
  /** AKA the concept number. */
  position: Scalars['Int'];
  /**
   * Deprecated
   * @deprecated use brief
   */
  productOrService?: Maybe<Scalars['String']>;
  /** @deprecated use projectKey instead */
  projectIntegrationKey: Scalars['ID'];
  projectKey: Scalars['ID'];
  ugcOrder?: Maybe<UgcOrder>;
  variations: Variations;
};


/** A Concept. */
export type ConceptVariationsArgs = {
  params?: InputMaybe<VariationsParams>;
};

export enum ConceptApproach {
  Native = 'Native',
  NonNative = 'NonNative',
  SemiNative = 'SemiNative'
}

export type ConceptBrief = {
  __typename?: 'ConceptBrief';
  /** Asset links and notes to be associated with the concept. */
  assetLinks?: Maybe<Array<AssetLink>>;
  /** Check whether the concept brief is valid. */
  briefValid: Scalars['Boolean'];
  /** Check whether ugc can be added to the concept brief. */
  canAddUgc: Scalars['Boolean'];
  /** Check whether the brief can be submitted for CS Review */
  canSubmitForCsReview: Scalars['Boolean'];
  /** Check whether the brief can be submitted for UGC Ideation */
  canSubmitForUgcIdeation: ConceptSubmissionState;
  /** Disclaimer for the concept. */
  disclaimer?: Maybe<Scalars['String']>;
  /** Rich text describing the story to communicate, any creative ideas and direction, and what makes this concept different to others in the project. */
  idea?: Maybe<Scalars['String']>;
  key: Scalars['ID'];
  /** The market assigned to the Concept */
  market?: Maybe<Market>;
  /** Product or service this concept is promoting. */
  productOrService?: Maybe<Scalars['String']>;
  projectKey: Scalars['ID'];
  scenes: Array<Scene>;
  /** Modular Sections that have been added to the Concept */
  sections?: Maybe<Array<Section>>;
  /** Check whether the concept has a valid UGC Brief. */
  ugcBriefValid: Scalars['Boolean'];
  /** Variation Briefs for the Concept */
  variationBriefs: Array<VariationBrief>;
};

/** A container for arguments used when duplicating a concept. */
export type ConceptDuplicateInput = {
  /**
   * UUID of the target concept.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** IntegrationKey of the target concept. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** Interim type for concept until concept type implements field level access control */
export type ConceptLite = {
  __typename?: 'ConceptLite';
  /** The integration key of the concept. */
  key: Scalars['ID'];
  productOrService?: Maybe<Scalars['String']>;
};

export enum ConceptSubmissionState {
  Invalid = 'INVALID',
  Noop = 'NOOP',
  Unspecified = 'UNSPECIFIED',
  Valid = 'VALID'
}

/** A container for arguments used when creating a concept variation */
export type ConceptVariationCreateInput = {
  /** Integration Key to assign to the new concept. */
  conceptIntegrationKey: Scalars['ID'];
  /** Name of the Concept Variation. */
  name: Scalars['String'];
  /** Integration Key for the source concept being varied. */
  sourceConceptIntegrationKey: Scalars['ID'];
};

export type Concepts = {
  __typename?: 'Concepts';
  nodes: Array<Concept>;
};

export type ConceptsFilter = {
  key?: InputMaybe<IdFilter>;
};

export type ConceptsParams = {
  filter?: InputMaybe<ConceptsFilter>;
};

/** Autogenerated input type of ConfirmOrder */
export type ConfirmOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /**
   * UUID of the order to be confirmed.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order to be confirmed. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of ConfirmOrder. */
export type ConfirmOrderPayload = {
  __typename?: 'ConfirmOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The confirmed order. */
  order?: Maybe<Order>;
};

/** A copilot status. */
export enum CopilotStatus {
  Completed = 'completed',
  Failed = 'failed',
  Generating = 'generating'
}

/** A Copy Suggestion. */
export type CopySuggestion = {
  __typename?: 'CopySuggestion';
  /** The UUID of the Copy Suggestion. */
  id: Scalars['ID'];
  /** A list of previous responses. */
  responses?: Maybe<Array<Scalars['String']>>;
  /** The generated copy. */
  value: Scalars['String'];
};

/** Autogenerated input type of CreateAdvert */
export type CreateAdvertInput = {
  /** Details of the advert to be created. */
  advert: AdvertCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAdvert. */
export type CreateAdvertPayload = {
  __typename?: 'CreateAdvertPayload';
  /** The new advert. */
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateAssets */
export type CreateAssetsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  input: BulkAssetsInput;
};

/** Autogenerated return type of CreateAssets. */
export type CreateAssetsPayload = {
  __typename?: 'CreateAssetsPayload';
  /** The newly created assets */
  assets: Array<Asset>;
  /** The Client Brand the assets were added to. */
  client?: Maybe<ClientBrand>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Order the assets were added to. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of CreateBrandLogo */
export type CreateBrandLogoInput = {
  asset: BrandLogoCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateBrandLogo. */
export type CreateBrandLogoPayload = {
  __typename?: 'CreateBrandLogoPayload';
  asset?: Maybe<Asset>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateClient */
export type CreateClientInput = {
  /** Details of the client to be created. */
  client: ClientCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateClient. */
export type CreateClientPayload = {
  __typename?: 'CreateClientPayload';
  /** The new client. */
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateConceptVariation */
export type CreateConceptVariationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Details of the concept variation to be created. */
  variation: ConceptVariationCreateInput;
};

/** Autogenerated return type of CreateConceptVariation. */
export type CreateConceptVariationPayload = {
  __typename?: 'CreateConceptVariationPayload';
  /** The new concept variation. */
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCopyVariation */
export type CreateCopyVariationInput = {
  /**
   * The ID of the advert
   * @deprecated Use `advertIntegrationKey` instead.
   */
  advertId?: InputMaybe<Scalars['ID']>;
  /** The integration key of the advert */
  advertIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCopyVariation. */
export type CreateCopyVariationPayload = {
  __typename?: 'CreateCopyVariationPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  copyVariation?: Maybe<Advert>;
};

export type CreateFileInput = {
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  organizationKey?: InputMaybe<Scalars['ID']>;
  projectKey?: InputMaybe<Scalars['ID']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  ugcOrderKey?: InputMaybe<Scalars['ID']>;
};

export type CreateInvitationInput = {
  /** Optional email address of an invitee */
  email?: InputMaybe<Scalars['String']>;
  /** Optional first name of an invitee */
  firstName?: InputMaybe<Scalars['String']>;
  /** Optional last name of an invitee */
  lastName?: InputMaybe<Scalars['String']>;
  /** Integration key of the organisation unit this invite will grant access to */
  orgUnitKey: Scalars['ID'];
  /** Optional flag to skip sending an email */
  skipEmail?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrUpdateAudienceInput = {
  ageRanges?: InputMaybe<Array<AgeRange>>;
  brandIntegrationKey: Scalars['ID'];
  gender?: InputMaybe<TargetGender>;
  incomeLifestyle?: InputMaybe<Scalars['String']>;
  integrationKey?: InputMaybe<Scalars['ID']>;
  interestsAndBehaviours?: InputMaybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateOrUpdateBrandProfileInput = {
  assetSources?: InputMaybe<Array<Scalars['String']>>;
  brandGuidelines?: InputMaybe<Scalars['String']>;
  brandIntegrationKey: Scalars['ID'];
  brandNotes?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** The market code to be assigned to the brand */
  marketCode?: InputMaybe<Scalars['String']>;
  personality?: InputMaybe<Scalars['String']>;
  productionNotes?: InputMaybe<Scalars['String']>;
  socialChannels?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateOrUpdateConceptInput = {
  /** Asset links and notes to be associated with the concept. */
  assetLinks?: InputMaybe<Array<AssetLinkInput>>;
  /** Disclaimer for the concept. */
  disclaimer?: InputMaybe<Scalars['String']>;
  /** Rich text describing the story to communicate, any creative ideas and direction, and what makes this concept different to others in the project. */
  idea?: InputMaybe<Scalars['String']>;
  /** Integration key of the concept to update. */
  integrationKey: Scalars['ID'];
  /** The market code to be assigned to the concept */
  marketCode?: InputMaybe<Scalars['String']>;
  /** Product or service this concept is promoting. */
  productOrService?: InputMaybe<Scalars['String']>;
  /** Integration key of the project this concept belongs to. */
  projectIntegrationKey: Scalars['ID'];
  /** Brief Sections for the Concept */
  sections?: InputMaybe<Array<Section>>;
};

export type CreateOrUpdateFileInput = {
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  key?: InputMaybe<Scalars['ID']>;
  organizationKey?: InputMaybe<Scalars['ID']>;
  projectKey?: InputMaybe<Scalars['ID']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  ugcOrderKey?: InputMaybe<Scalars['ID']>;
};

export type CreateOrUpdateSceneInput = {
  conceptIntegrationKey: Scalars['ID'];
  /** @deprecated Temporary Field. Needed to tie notes back to questions. */
  legacyQuestionId?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  /** @deprecated Temporary Field. Needed to create Concept just-in-time. */
  projectIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of CreateOrder */
export type CreateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the Order to be created. */
  order: OrderCreateInput;
};

/** Autogenerated return type of CreateOrder. */
export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The new Order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of CreateProductionDeliveryTurnaround */
export type CreateProductionDeliveryTurnaroundInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Details of the delivery turnaround to be created. */
  productionDeliveryTurnaround: ProductionDeliveryTurnaroundCreateInput;
};

/** Autogenerated return type of CreateProductionDeliveryTurnaround. */
export type CreateProductionDeliveryTurnaroundPayload = {
  __typename?: 'CreateProductionDeliveryTurnaroundPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The result of the mutation. */
  result: Scalars['Boolean'];
};

export type CreateUgcAssetInput = {
  /** Integration key of the file to use for this asset */
  fileKey: Scalars['ID'];
  /** Integration key of the UGC Order this asset belongs to */
  ugcOrderKey: Scalars['ID'];
};

export type CreateUgcDeliverableInput = {
  fileKey: Scalars['ID'];
  ugcOrderKey: Scalars['ID'];
};

export type CreateVariationBriefInput = {
  /** Key of the Concept to add the Variation to. */
  conceptKey: Scalars['ID'];
};

export enum CreativeGroup {
  Banner = 'banner',
  Beta = 'beta',
  CreativeLabs = 'creative_labs',
  Interactive = 'interactive',
  Social = 'social'
}

export type CreativeXBrand = {
  __typename?: 'CreativeXBrand';
  /** The CreativeX brand external ID */
  externalId: Scalars['Int'];
  /** The CreativeX brand name */
  name: Scalars['String'];
};

export type CreativeXIntegration = {
  __typename?: 'CreativeXIntegration';
  /** Brands associated with the CreativeX account */
  brands: Array<CreativeXBrand>;
};

export type CreativeXPreflight = {
  __typename?: 'CreativeXPreflight';
  /** The create time for the CreativeX preflight task */
  createdAt: Scalars['Time'];
  /** The CreativeX preflight task error message */
  error?: Maybe<Scalars['String']>;
  /** The CreativeX preflight task ID */
  id: Scalars['ID'];
  /** The CreativeX preflight request ID */
  requestId: Scalars['Int'];
  /** The CreativeX preflight task status */
  status: PreflightStatus;
  /** The update time for the CreativeX preflight task */
  updatedAt: Scalars['Time'];
};

export type CreativeXResult = {
  __typename?: 'CreativeXResult';
  /** The CreativeX result guidelines */
  guidelines: Array<CreativeXResultGuideline>;
  /** The CreativeX result ID */
  id: Scalars['ID'];
  /** The CreativeX result quality tier */
  qualityTier: Scalars['String'];
  /** The CreativeX result score */
  score: Scalars['Float'];
  /** The CreativeX result scorecard URL */
  scorecardUrl?: Maybe<Scalars['String']>;
};

export type CreativeXResultGuideline = {
  __typename?: 'CreativeXResultGuideline';
  /** The CreativeX guideline name */
  name: Scalars['String'];
  /** Whether the CreativeX result passed for this guideline */
  passed: Scalars['Boolean'];
  /** The CreativeX guideline weighting */
  weight: Scalars['Float'];
};

/** Primary Creator Entity. */
export type Creator = {
  __typename?: 'Creator';
  /** Address city. */
  addressCity: Scalars['String'];
  /** Address country code. */
  addressCountryCode: Scalars['String'];
  /** Address line 1. */
  addressLine1: Scalars['String'];
  /** Address line 2. */
  addressLine2?: Maybe<Scalars['String']>;
  /** Address state. */
  addressState: Scalars['String'];
  /** Address zip code. */
  addressZipCode: Scalars['String'];
  /** Year of birth. */
  birthYear: Scalars['Int'];
  /** Time the Creator Entity was created. */
  createdAt: Scalars['Time'];
  /** Email. */
  email: Scalars['String'];
  /** Ethnicity. */
  ethnicity: Scalars['String'];
  /** First name. */
  firstName: Scalars['String'];
  /** Gender. */
  gender: Gender;
  /** Hobbies / Interests. */
  hobbyInterests: Array<CreatorHobbyInterest>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Unique key cross services. */
  key: Scalars['ID'];
  /** Languages the creator is fluent in (spoken and/or written). */
  knownLanguages: Array<Language>;
  /**
   * Language codes.
   * @deprecated Use knownLanguages instead.
   */
  languages: Array<Scalars['String']>;
  /** Last name. */
  lastName: Scalars['String'];
  /** Media. */
  media: Array<Media>;
  /** Full name. */
  name: Scalars['String'];
  /** Additional notes about the creator. */
  notes: Scalars['String'];
  /** Offers assigned to the creator. */
  offers: Array<CreatorOffer>;
  /** Orders assigned to the creator. */
  orders: Array<UgcOrder>;
  /** Post ads answer. */
  postAds: CreatorPostAd;
  /** Profile / bio. */
  profile: Scalars['String'];
  /** Reliability Score */
  score: Scalars['Int'];
  /** Social media. */
  socialMedia: Array<CreatorSocialMedia>;
  /**
   * Social media links.
   * @deprecated Use socialMedia instead.
   */
  socialMediaLinks: Array<Scalars['String']>;
  /** Specialty verticals. */
  specialtyVerticals: Array<CreatorSpecialtyVertical>;
  /** Approval status. */
  status: CreatorStatus;
};


/** Primary Creator Entity. */
export type CreatorOffersArgs = {
  filter?: InputMaybe<CreatorOffersFilter>;
};


/** Primary Creator Entity. */
export type CreatorOrdersArgs = {
  filter?: InputMaybe<CreatorOrdersFilter>;
};

export type CreatorAttributes = {
  __typename?: 'CreatorAttributes';
  /** Retrieve a distinct list of countries. */
  countries: Array<Scalars['String']>;
  /** Retrieve a distinct list of ethnicities. */
  ethnicities: Array<Scalars['String']>;
  /** Retrieve an individual gender by id. */
  gender: Gender;
  /** Retrieve all available genders. */
  genders: Array<Gender>;
  /** Retrieve an individual hobby / interest by id. */
  hobbyInterest: CreatorHobbyInterest;
  /** Retrieve all available hobbies / interests. */
  hobbyInterests: Array<CreatorHobbyInterest>;
  /** Retrieve an individual language by code. */
  language: Language;
  /** Retrieve all available languages. */
  languages: Array<Language>;
  /** Retrieve an individual specialty vertical by id. */
  specialtyVertical: CreatorSpecialtyVertical;
  /** Retrieve all available specialty verticals. */
  specialtyVerticals: Array<CreatorSpecialtyVertical>;
};


export type CreatorAttributesGenderArgs = {
  id: Scalars['ID'];
};


export type CreatorAttributesHobbyInterestArgs = {
  id: Scalars['ID'];
};


export type CreatorAttributesHobbyInterestsArgs = {
  params?: InputMaybe<QueryParams>;
};


export type CreatorAttributesLanguageArgs = {
  code: Scalars['String'];
};


export type CreatorAttributesLanguagesArgs = {
  filter?: InputMaybe<Scalars['String']>;
};


export type CreatorAttributesSpecialtyVerticalArgs = {
  id: Scalars['ID'];
};


export type CreatorAttributesSpecialtyVerticalsArgs = {
  params?: InputMaybe<QueryParams>;
};

export type CreatorBrief = {
  __typename?: 'CreatorBrief';
  additionalInstructions?: Maybe<Scalars['String']>;
  audienceAgeRanges?: Maybe<Array<AudienceAgeRange>>;
  audienceGender?: Maybe<AudienceGender>;
  audienceIncomeLifestyle?: Maybe<Scalars['String']>;
  audienceInterestsAndBehaviours?: Maybe<Scalars['String']>;
  audienceLocation?: Maybe<Scalars['String']>;
  audienceName?: Maybe<Scalars['String']>;
  brandAssets?: Maybe<Array<UgcAsset>>;
  brandDescription?: Maybe<Scalars['String']>;
  brandLogoStorageKey?: Maybe<Scalars['String']>;
  brandName: Scalars['String'];
  brandProductPositioning?: Maybe<Scalars['String']>;
  brandRelevantSocialChannels?: Maybe<Scalars['String']>;
  conceptApproach?: Maybe<ConceptApproach>;
  conceptDirection?: Maybe<Scalars['String']>;
  conceptName: Scalars['String'];
  conceptPosition: Scalars['Int'];
  deliverable?: Maybe<Scalars['String']>;
  dosAndDonts?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Language>>;
  legalRequirements?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  productOrService?: Maybe<Scalars['String']>;
  productProcurement?: Maybe<Scalars['String']>;
  projectName: Scalars['String'];
  referenceAssets?: Maybe<Array<UgcAsset>>;
  referenceLinks?: Maybe<Array<UgcAssetLink>>;
  scriptOrVoiceOverInstructions?: Maybe<Scalars['String']>;
  theHook?: Maybe<Scalars['String']>;
  ugcOrderKey: Scalars['ID'];
  uniqueSellingPoints?: Maybe<Scalars['String']>;
};

/** Hobby / Interest (i.e. of a creator). */
export type CreatorHobbyInterest = {
  __typename?: 'CreatorHobbyInterest';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Human readable label for this hobby / interest. */
  label: Scalars['String'];
};

/** Creator Input. */
export type CreatorInput = {
  /** Address city. */
  addressCity: Scalars['String'];
  /** Address country code. */
  addressCountryCode: Scalars['String'];
  /** Address line 1. */
  addressLine1: Scalars['String'];
  /** Address line 2. */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** Address state. */
  addressState: Scalars['String'];
  /** Address zip code. */
  addressZipCode: Scalars['String'];
  /** Year of birth. */
  birthYear: Scalars['Int'];
  /** Email. */
  email: Scalars['String'];
  /** Ethnicity. */
  ethnicity: Scalars['String'];
  /** First name. */
  firstName: Scalars['String'];
  /** Gender identifier. */
  genderID: Scalars['ID'];
  /** Hobby / Interest identifiers. */
  hobbyInterestIDs: Array<Scalars['ID']>;
  /** Unique Identifier. */
  key?: InputMaybe<Scalars['ID']>;
  /** Languages. */
  languages: Array<Scalars['String']>;
  /** Last name. */
  lastName: Scalars['String'];
  /** Media input. */
  media: Array<MediaInput>;
  /** Additional notes about the creator. */
  notes: Scalars['String'];
  /** Post ads answer. */
  postAds: CreatorPostAd;
  /** Profile / bio. */
  profile: Scalars['String'];
  /** Reliability Score */
  score: Scalars['Int'];
  /** Social media links. */
  socialMediaLinks: Array<Scalars['String']>;
  /** Specialty vertical identifiers. */
  specialtyVerticalIDs: Array<Scalars['ID']>;
  /** Approval status. */
  status: CreatorStatus;
};

/** Post ads answer. */
export enum CreatorMediaType {
  Introduction = 'INTRODUCTION',
  Portfolio = 'PORTFOLIO',
  Profile = 'PROFILE'
}

export type CreatorOffer = {
  __typename?: 'CreatorOffer';
  brief?: Maybe<UgcBrief>;
  canRevoke: Scalars['Boolean'];
  declineReason?: Maybe<Scalars['String']>;
  key: Scalars['ID'];
  revokeReason?: Maybe<Scalars['String']>;
  state: CreatorOfferState;
};

export enum CreatorOfferState {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Revoked = 'REVOKED'
}

export type CreatorOffersFilter = {
  states?: InputMaybe<Array<CreatorOfferState>>;
};

export type CreatorOrdersFilter = {
  states?: InputMaybe<Array<UgcOrderState>>;
};

/** Post ads answer. */
export enum CreatorPostAd {
  No = 'NO',
  Unsure = 'UNSURE',
  Yes = 'YES'
}

export type CreatorSearchFilter = {
  addressCity?: InputMaybe<StringFilter>;
  addressCountryCode?: InputMaybe<StringFilter>;
  birthYear?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<TimeFilter>;
  ethnicity?: InputMaybe<StringFilter>;
  gender?: InputMaybe<IdFilter>;
  hobbyInterests?: InputMaybe<IdArrayFilter>;
  keyword?: InputMaybe<StringFilter>;
  languages?: InputMaybe<StringArrayFilter>;
  name?: InputMaybe<StringFilter>;
  score?: InputMaybe<IntFilter>;
  specialtyVerticals?: InputMaybe<IdArrayFilter>;
  status?: InputMaybe<CreatorStatusFilter>;
};

export type CreatorSearchParams = {
  /** Search query. */
  filter?: InputMaybe<CreatorSearchFilter>;
  pagination?: InputMaybe<OffsetPagination>;
  sort?: InputMaybe<SortInput>;
};

export type CreatorSearchResult = {
  __typename?: 'CreatorSearchResult';
  meta: OffsetPageInfo;
  nodes: Array<Creator>;
};

export type CreatorSocialMedia = {
  __typename?: 'CreatorSocialMedia';
  type: CreatorSocialMediaType;
  url: Scalars['String'];
};

export enum CreatorSocialMediaType {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Linkedin = 'LINKEDIN',
  Other = 'OTHER',
  Pinterest = 'PINTEREST',
  Snapchat = 'SNAPCHAT',
  Tiktok = 'TIKTOK',
  Twitter = 'TWITTER',
  Youtube = 'YOUTUBE'
}

/** CreatorSpecialtyVertical (i.e. of a creator). */
export type CreatorSpecialtyVertical = {
  __typename?: 'CreatorSpecialtyVertical';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Human readable label for this specialty vertical. */
  label: Scalars['String'];
};

/** Creator approval status. */
export enum CreatorStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING'
}

export type CreatorStatusFilter = {
  eq?: InputMaybe<CreatorStatus>;
  in?: InputMaybe<Array<CreatorStatus>>;
};

/** CSAT rating enumeration. */
export enum CsatRating {
  /** Completely satisfied. */
  CompletelySatisfied = 'CompletelySatisfied',
  /** Moderately satisfied. */
  ModeratelySatisfied = 'ModeratelySatisfied',
  /** Not at all satisfied. */
  NotAtAllSatisfied = 'NotAtAllSatisfied',
  /** Slightly satisfied. */
  SlightlySatisfied = 'SlightlySatisfied',
  /** Very satisfied. */
  VerySatisfied = 'VerySatisfied'
}

export type DeactivateUgcDeliverablesInput = {
  /** Integration keys of the UGC Deliverables to deactivate */
  keys: Array<Scalars['ID']>;
};

export type DeclineCreatorOfferInput = {
  key: Scalars['ID'];
  reason: Scalars['String'];
};

/** Autogenerated input type of DeliverOrder */
export type DeliverOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the order to be delivered. */
  id: Scalars['ID'];
};

/** Autogenerated return type of DeliverOrder. */
export type DeliverOrderPayload = {
  __typename?: 'DeliverOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The delivered order. */
  order?: Maybe<Order>;
};

/** A deliverable asset produced for an Order. */
export type Deliverable = {
  __typename?: 'Deliverable';
  /** The Advert associated to the Deliverable asset. */
  advert?: Maybe<Advert>;
  /** The Audio Asset associated to the Deliverable. */
  audio?: Maybe<DeliverableAudio>;
  /**
   * The URL of the Audio Asset associated to the Deliverable.
   * @deprecated Use audio instead
   */
  audioUrl?: Maybe<Scalars['String']>;
  /** The bitrate for a Deliverable asset, if it is a video. */
  bitrate?: Maybe<Scalars['Int']>;
  /** The bitrate mode for a Deliverable, if it is a video. */
  bitrateMode?: Maybe<Scalars['String']>;
  /** The category the deliverable belongs to. E.g. copy_variations, hero_and_formats, etc. */
  category: DeliverableCategory;
  /** The Cloudflare video ID. */
  cfVideoId?: Maybe<Scalars['String']>;
  /** Indication of whether the deliverable contains AI. */
  containsAi: Ternary;
  /** The Copy Variation Number associated to the Deliverable asset. */
  copyVariationNumber: Scalars['Int'];
  creativeX?: Maybe<DeliverableCreativeX>;
  /** Deliverable Format associated with the Deliverable asset. */
  deliverableFormat: DeliverableFormat;
  /** The duration in seconds for video deliverables. */
  duration: Scalars['Float'];
  /** A secondary file name for the Deliverable when exporting, typically used for client-specific file naming conventions. */
  exportFileName?: Maybe<Scalars['String']>;
  /** The export filename. */
  exportFilename?: Maybe<Scalars['String']>;
  /** The file associated with the deliverable. */
  file: File;
  /** The original name of the Deliverable file when it was uploaded. */
  fileName: Scalars['String'];
  /** The file size of the Deliverable file in bytes. */
  fileSize: Scalars['Int'];
  /** File extension for the Deliverable asset. */
  fileType: DeliverableFileType;
  /** The format length category for the Deliverable asset. */
  formatLength: FormatLength;
  /** The Frame rate of the Deliverable asset, if it is a video. */
  framesPerSecond?: Maybe<Scalars['Float']>;
  hasConceptRevisions: Scalars['Boolean'];
  /** The actual height of the Deliverable, extracted from the file when uploaded. */
  height?: Maybe<Scalars['Int']>;
  /** Flags the Deliverable asset as the hero Deliverable for that order. */
  hero: Scalars['Boolean'];
  /** Checks if the deliverable is an approved hero deliverable. */
  heroApproved?: Maybe<Scalars['Boolean']>;
  /** The UUID of the Deliverable. */
  id: Scalars['ID'];
  /**
   * A surrogate UUID used to identify a deliverable when integrating with external apps.
   * @deprecated Use `key` instead.
   */
  integrationKey: Scalars['ID'];
  /** The key of the deliverable. */
  key: Scalars['ID'];
  /** The Language associated to the Deliverable asset. */
  languageCode?: Maybe<Scalars['String']>;
  /**
   * The URL of the Meta Audio Asset associated to the Deliverable.
   * @deprecated Use audio_url instead
   */
  metaAudioAssetUrl?: Maybe<Scalars['String']>;
  /** The number of pending concept revisions for a deliverable. */
  numConceptRevisions: Scalars['Int'];
  /** The number of pending revision requests for a deliverable. */
  numRevisionRequests: Scalars['Int'];
  /** The Order that the Deliverable asset was created for. */
  order: Order;
  /** Numerical position of the Deliverable in relation to the others in this order. */
  position: Scalars['Int'];
  /** The quality assurance report results. */
  qualityAssuranceReport?: Maybe<QualityAssuranceReport>;
  /** The number of Revision for a Deliverable asset. */
  revision: Scalars['Int'];
  revisions: Revisions;
  /** The UTC timestamp when the Deliverable's revisions were last changed, as an Integer. */
  revisionsUpdatedAt?: Maybe<Scalars['Int']>;
  /** The path to the file on S3. */
  s3Path: Scalars['String'];
  /** Integration key for an associated Specification. */
  specificationIntegrationKey?: Maybe<Scalars['ID']>;
  /** The production state of the Deliverable. */
  state: DeliverableState;
  /** The state of the deliverable. */
  status: DeliverableStatus;
  /** The url to the thumbnail for this Deliverable. For images this is the same as the url field. */
  thumbnailUrl: Scalars['String'];
  /** Given name of the Deliverable, chosen by the Production team on upload. */
  title?: Maybe<Scalars['String']>;
  /** Unit number associated to the Deliverable asset to prevent it being detected as a duplicate. */
  unitNumber: Scalars['Int'];
  /** The UTC timestamp when the Deliverable was last updated, as an Integer. */
  updatedAt: Scalars['Int'];
  /** Public web address where the Deliverable asset is located. */
  url: Scalars['String'];
  /** The version number of the Deliverable. */
  versionNumberExternal?: Maybe<Scalars['Int']>;
  /** The internal version number of the Deliverable in respect to the external version number. */
  versionNumberInternal?: Maybe<Scalars['Int']>;
  /** The actual width of the Deliverable, extracted from the file when uploaded. */
  width?: Maybe<Scalars['Int']>;
};


/** A deliverable asset produced for an Order. */
export type DeliverableThumbnailUrlArgs = {
  fit?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** An audio asset. */
export type DeliverableAudio = {
  __typename?: 'DeliverableAudio';
  /** The type of the audio asset. */
  type: DeliverableAudioType;
  /** The URL of the audio asset. */
  url?: Maybe<Scalars['String']>;
};

/** A container for arguments used when creating/updating an audio asset. */
export type DeliverableAudioInput = {
  /** The type of the audio asset. */
  type: DeliverableAudioType;
  /** The URL of the audio asset. */
  url?: InputMaybe<Scalars['String']>;
};

/** The type of an audio asset. */
export enum DeliverableAudioType {
  ClientProvided = 'CLIENT_PROVIDED',
  CustomAudio = 'CUSTOM_AUDIO',
  NoAudio = 'NO_AUDIO'
}

export enum DeliverableCategory {
  CopyVariations = 'copy_variations',
  HeroAndFormats = 'hero_and_formats',
  Languages = 'languages',
  Unknown = 'unknown'
}

export type DeliverableCreativeX = {
  __typename?: 'DeliverableCreativeX';
  /** The CreativeX preflight task */
  preflight: CreativeXPreflight;
  /** The CreativeX preflight result */
  result?: Maybe<CreativeXResult>;
};

export enum DeliverableFileType {
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Mp4 = 'MP4',
  Png = 'PNG'
}

/** A Deliverable Format. */
export type DeliverableFormat = {
  __typename?: 'DeliverableFormat';
  /** The Creative Group the DeliverableFormat belongs to. */
  creativeGroup?: Maybe<CreativeGroup>;
  /** The Dimension of the DeliverableFormat. */
  dimension?: Maybe<Dimension>;
  /** The code used to map a deliverable name to its Deliverable Format */
  filenameCode?: Maybe<Scalars['String']>;
  /** The possible lengths of deliverable. */
  formatLengths: Array<FormatLength>;
  /** The UUID of the Deliverable Format. */
  id: Scalars['ID'];
  /**
   * A surrogate UUID used to identify a deliverable format when integrating with external apps.
   * @deprecated Use `key` instead.
   */
  integrationKey: Scalars['ID'];
  /** Flag identifying deliverable formats with custom dimensions. */
  isCustom: Scalars['Boolean'];
  /** A surrogate UUID used to identify a deliverable format when integrating with external apps. */
  key: Scalars['ID'];
  /** A combination of the Deliverable Format's name and ratio. */
  label: Scalars['String'];
  /** Given name for the Deliverable Format. */
  name: Scalars['String'];
  /** The platform name. */
  platform: Platform;
  /** Determining the order to display. */
  position: Scalars['Int'];
};

/** A container for arguments used when creating an deliverable format dimension */
export type DeliverableFormatDimensionInput = {
  /** The lengths of requested deliverables. */
  formatLengths: Array<FormatLength>;
  /** The height of the dimension. */
  height?: InputMaybe<Scalars['Int']>;
  /** The width of the dimension. */
  width?: InputMaybe<Scalars['Int']>;
};

/** A paginated array of Deliverable Formats */
export type DeliverableFormats = {
  __typename?: 'DeliverableFormats';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Deliverable Formats */
  nodes: Array<DeliverableFormat>;
};

/** A container for arguments used when creating/updating a deliverable */
export type DeliverableInput = {
  /** The UUID of the Advert to be associated to this deliverable. */
  advertId: Scalars['ID'];
  /** The audio asset associated to the Deliverable asset. */
  audio?: InputMaybe<DeliverableAudioInput>;
  /**
   * The URL of the audio asset associated to the Deliverable asset.
   * @deprecated Use audio instead.
   */
  audioUrl?: InputMaybe<Scalars['String']>;
  /** The bitrate for a Deliverable asset, if it is a video. */
  bitrate?: InputMaybe<Scalars['Int']>;
  /** The bitrate mode for a Deliverable asset if it is a video. */
  bitrateMode?: InputMaybe<Scalars['String']>;
  /** Indication of whether the deliverable contains AI. */
  containsAi?: InputMaybe<Ternary>;
  /** The Copy Variation Number associated to the Deliverable asset. */
  copyVariationNumber?: InputMaybe<Scalars['Int']>;
  /** The UUID of the deliverable format to be attached by this deliverable. */
  deliverableFormatId?: InputMaybe<Scalars['ID']>;
  /** The duration of the deliverable. */
  duration?: InputMaybe<Scalars['Float']>;
  /** A secondary file name for the Deliverable when exporting. */
  exportFileName?: InputMaybe<Scalars['String']>;
  /** The original name of the Deliverable file when it was uploaded. */
  fileName: Scalars['String'];
  /** The file size of the Deliverable file in bytes. */
  fileSize?: InputMaybe<Scalars['Int']>;
  /** The file type of the deliverable. */
  fileType?: InputMaybe<DeliverableFileType>;
  /** The format length category for the Deliverable. */
  formatLength?: InputMaybe<FormatLength>;
  /** The Frame rate of the Deliverable asset, if it is a video. */
  framesPerSecond?: InputMaybe<Scalars['Float']>;
  /** The actual height of the Deliverable, extracted from the file when uploaded. */
  height?: InputMaybe<Scalars['Int']>;
  /** Flags the Deliverable asset as the hero Deliverable for that order. */
  hero: Scalars['Boolean'];
  /** UUID of the target deliverable. */
  id?: InputMaybe<Scalars['ID']>;
  /** The Language associated to the Deliverable asset. */
  languageCode: Scalars['String'];
  /**
   * The URL of the Meta audio asset associated to the Deliverable asset.
   * @deprecated Use audio_url instead
   */
  metaAudioAssetUrl?: InputMaybe<Scalars['String']>;
  /** The position of the deliverable. */
  position?: InputMaybe<Scalars['Int']>;
  /** The number of Revision for a Deliverable asset. */
  revision?: InputMaybe<Scalars['Int']>;
  /** The S3 path of the deliverable. */
  s3Path?: InputMaybe<Scalars['String']>;
  /**
   * The title of the deliverable.
   * @deprecated Use export_file_name instead.
   */
  title?: InputMaybe<Scalars['String']>;
  /** Unit number associated to the Deliverable asset to prevent it being detected as a duplicate. */
  unitNumber: Scalars['Int'];
  /** The actual width of the Deliverable, extracted from the file when uploaded. */
  width?: InputMaybe<Scalars['Int']>;
};

export type DeliverableRevisionQuickfixable = {
  __typename?: 'DeliverableRevisionQuickfixable';
  points: Scalars['Int'];
};

export type DeliverableSpecification = {
  __typename?: 'DeliverableSpecification';
  createdAt: Scalars['Time'];
  creationType: DeliverableSpecificationCreationType;
  /** The latest deliverable for a specification, if available */
  deliverable?: Maybe<Deliverable>;
  format: DeliverableSpecificationFormat;
  id: Scalars['ID'];
  isHero: Scalars['Boolean'];
  isSourceVariation: Scalars['Boolean'];
  key: Scalars['ID'];
  languageCode: Scalars['String'];
  progressStatus: DeliverableSpecificationProgressStatus;
  unitNumber: Scalars['Int'];
};

export enum DeliverableSpecificationCreationType {
  AdditionalFormats = 'ADDITIONAL_FORMATS',
  Automation = 'AUTOMATION',
  Briefing = 'BRIEFING',
  Reconciliation = 'RECONCILIATION',
  Variations = 'VARIATIONS'
}

export type DeliverableSpecificationFormat = {
  __typename?: 'DeliverableSpecificationFormat';
  dimension: SpecificationDimension;
  heroPriority?: Maybe<Scalars['Int']>;
  key: Scalars['ID'];
  length: FormatLength;
  name: Scalars['String'];
  platform: Platform;
};

export enum DeliverableSpecificationProgressStatus {
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED'
}

export type DeliverableSpecifications = {
  __typename?: 'DeliverableSpecifications';
  meta: OffsetPageInfo;
  nodes: Array<DeliverableSpecification>;
};

export type DeliverableSpecificationsFilter = {
  isHero?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<IdFilter>;
};

export type DeliverableSpecificationsParams = {
  filter?: InputMaybe<DeliverableSpecificationsFilter>;
  pagination?: InputMaybe<OffsetPagination>;
};

export enum DeliverableState {
  Approved = 'approved',
  ClientReview = 'client_review',
  Expired = 'expired',
  InternalReview = 'internal_review',
  InternallyRejected = 'internally_rejected',
  PendingDelivery = 'pending_delivery',
  Rejected = 'rejected'
}

export enum DeliverableStatus {
  Approved = 'APPROVED',
  ClientReview = 'CLIENT_REVIEW',
  ClientRevision = 'CLIENT_REVISION',
  InternalReview = 'INTERNAL_REVIEW',
  InternalRevision = 'INTERNAL_REVISION',
  PendingDelivery = 'PENDING_DELIVERY',
  Superseded = 'SUPERSEDED',
  Unspecified = 'UNSPECIFIED'
}

/** Autogenerated input type of DeliverableUnapproved */
export type DeliverableUnapprovedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Deliverable to be unapproved. */
  deliverable: UnapprovedDeliverableInput;
};

/** Autogenerated return type of DeliverableUnapproved. */
export type DeliverableUnapprovedPayload = {
  __typename?: 'DeliverableUnapprovedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable to be updated. */
  deliverable?: Maybe<Deliverable>;
};

/** A container for arguments used when updating a deliverable */
export type DeliverableUpdateInput = {
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /**
   * Event sequence ID of incoming event.
   * @deprecated Related to Kinesis events. Superseded by knative and `event_id`.
   */
  eventSequenceId?: InputMaybe<Scalars['Int']>;
  /** Flags a hero deliverable as being approved. */
  heroApproved?: InputMaybe<Scalars['Boolean']>;
  /** UUID of the target deliverable. */
  id?: InputMaybe<Scalars['ID']>;
  /** A surrogate UUID used to identify a deliverable when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** Number of revisions that apply to all deliverables in the concept. */
  numConceptRevisions?: InputMaybe<Scalars['Int']>;
  /** Number of revisions requested on the deliverable. */
  numRevisionRequests?: InputMaybe<Scalars['Int']>;
  /** Integration key of the specification being associated to the deliverable. */
  specificationIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** State of the deliverable. */
  state?: InputMaybe<DeliverableState>;
  /** The external version number of the deliverable. */
  versionNumberExternal?: InputMaybe<Scalars['Int']>;
  /** The internal version number of the deliverable in respect to the external version number. */
  versionNumberInternal?: InputMaybe<Scalars['Int']>;
};

export type Deliverables = {
  __typename?: 'Deliverables';
  meta: OffsetPageInfo;
  nodes: Array<Deliverable>;
};

export type DeliverablesFilter = {
  key?: InputMaybe<IdFilter>;
};

export type DeliverablesParams = {
  filter?: InputMaybe<DeliverablesFilter>;
  pagination?: InputMaybe<OffsetPagination>;
};

/** A container for arguments used when rejecting deliverables */
export type DeliverablesRejectInput = {
  /** The UUID of the project to be rejected. */
  id: Scalars['ID'];
  /** The priority level of a revision round. */
  priority?: InputMaybe<ProductionPriority>;
};

export type DeliverablesRevisionPoints = {
  __typename?: 'DeliverablesRevisionPoints';
  points: Scalars['Int'];
};

/** The expected delivery turnaround time. */
export type DeliveryTurnaround = {
  __typename?: 'DeliveryTurnaround';
  /** Reason the delivery time was adjusted. */
  adjustedDeliveryReason?: Maybe<Scalars['String']>;
  /** The new delivery time. */
  adjustedDeliveryTime?: Maybe<Scalars['BigInt']>;
  /** Time of delivery. */
  deliveredAt?: Maybe<Scalars['BigInt']>;
  /** The expected delivery time. */
  expectedDeliveryTime?: Maybe<Scalars['BigInt']>;
  /** Id of the delivery turnaround. */
  key: Scalars['ID'];
  /** The original delivery time before any manual updates. */
  originalDeliveryTime?: Maybe<Scalars['BigInt']>;
};

/** Autogenerated input type of DestroyAdvert */
export type DestroyAdvertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /**
   * UUID of the advert to be destroyed.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the advert to be destroyed. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of DestroyAdvert. */
export type DestroyAdvertPayload = {
  __typename?: 'DestroyAdvertPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DestroyConceptVariation */
export type DestroyConceptVariationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the Concept Variation to be destroyed. */
  integrationKey: Scalars['ID'];
};

/** Autogenerated return type of DestroyConceptVariation. */
export type DestroyConceptVariationPayload = {
  __typename?: 'DestroyConceptVariationPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** A Dimension. */
export type Dimension = {
  __typename?: 'Dimension';
  /**
   * A flag indicating that the Dimension is custom.
   * @deprecated Use deliverable_format.is_custom instead
   */
  custom: Scalars['Boolean'];
  /** The Dimension height. */
  height: Scalars['Int'];
  /** The humanized ratio of the Dimension width & height, e.g. 16:9 */
  humanizedRatio: Scalars['String'];
  /** The UUID of the Dimension. */
  id: Scalars['ID'];
  /** The Dimension's human readable label in the form: <width>x<height> <humanize_ratio>. */
  label: Scalars['String'];
  /** The decimal expression of the ratio of the Dimension width and height, e.g. 1.78. */
  ratio: Scalars['Float'];
  /** The Dimension width. */
  width: Scalars['Int'];
};

export type DimensionInput = {
  custom: Scalars['Boolean'];
  height: Scalars['Int'];
  width: Scalars['Int'];
};

/** Autogenerated input type of DiscardBrand */
export type DiscardBrandInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  integrationKey: Scalars['ID'];
};

/** Autogenerated return type of DiscardBrand. */
export type DiscardBrandPayload = {
  __typename?: 'DiscardBrandPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DiscardClientAccount */
export type DiscardClientAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  integrationKey: Scalars['ID'];
};

/** Autogenerated return type of DiscardClientAccount. */
export type DiscardClientAccountPayload = {
  __typename?: 'DiscardClientAccountPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DiscardDeliverable */
export type DiscardDeliverableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Deliverable to be discarded. */
  id: Scalars['ID'];
};

/** Autogenerated return type of DiscardDeliverable. */
export type DiscardDeliverablePayload = {
  __typename?: 'DiscardDeliverablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Deliverable>;
};

/** Autogenerated input type of DiscardOrder */
export type DiscardOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be discarded. */
  order: OrderDiscardInput;
};

/** Autogenerated return type of DiscardOrder. */
export type DiscardOrderPayload = {
  __typename?: 'DiscardOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The discarded order, returned for the purpose of specifying what was discarded. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of DiscardWorkingFile */
export type DiscardWorkingFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID integration Key of the Working File to be discarded. */
  key: Scalars['ID'];
};

/** Autogenerated return type of DiscardWorkingFile. */
export type DiscardWorkingFilePayload = {
  __typename?: 'DiscardWorkingFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  workingFile?: Maybe<WorkingFile>;
};

/** Autogenerated input type of DuplicateConcept */
export type DuplicateConceptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the concept to be duplicated. */
  concept: ConceptDuplicateInput;
};

/** Autogenerated return type of DuplicateConcept. */
export type DuplicateConceptPayload = {
  __typename?: 'DuplicateConceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The duplicated concept. */
  concept?: Maybe<Advert>;
};

/** Autogenerated input type of DuplicateOrder */
export type DuplicateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be duplicated. */
  order: OrderDuplicateInput;
};

/** Autogenerated return type of DuplicateOrder. */
export type DuplicateOrderPayload = {
  __typename?: 'DuplicateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The duplicated order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of DuplicateProject */
export type DuplicateProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the project to be duplicated. */
  order: ProjectDuplicateInput;
};

/** Autogenerated return type of DuplicateProject. */
export type DuplicateProjectPayload = {
  __typename?: 'DuplicateProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The duplicated project. */
  order?: Maybe<Order>;
};

/** Input for editing an existing revision comment. */
export type EditCommentInput = {
  /** Comment ID of the comment to be updated. */
  id: Scalars['ID'];
  /** Is the comment only viewable by internal users. */
  isInternalOnly: Scalars['Boolean'];
  /** The body text of the comment. */
  message: Scalars['String'];
};

export type EditRevisionInput = {
  addAnnotations?: InputMaybe<Array<AnnotationInput>>;
  addAttachments?: InputMaybe<Array<AttachmentInput>>;
  appliesToConcept: Scalars['Boolean'];
  category: RevisionCategory;
  comment: Scalars['String'];
  hasAnnotation: Scalars['Boolean'];
  hasFullFrame: Scalars['Boolean'];
  hasThumbnail: Scalars['Boolean'];
  id: Scalars['ID'];
  newFullFrame?: InputMaybe<Scalars['Upload']>;
  newThumbnail?: InputMaybe<Scalars['Upload']>;
  removeAttachments?: InputMaybe<Array<Scalars['ID']>>;
  subCategory?: InputMaybe<RevisionSubCategory>;
  /** Apply revision on a video format to a specific point in time. Also used to track the primary frame for annotations. */
  timestamp?: InputMaybe<Scalars['Float']>;
  /** End point in time for applying a time range to a revision. */
  timestampEnd?: InputMaybe<Scalars['Float']>;
  /** Starting point in time for applying a time range to a revision. */
  timestampStart?: InputMaybe<Scalars['Float']>;
  /** Type of the timestamp that is applied to a revision. */
  timestampType: TimestampType;
};

/** Represents a string that has been encoded */
export type EncodedString = {
  /** The encoded string data */
  data: Scalars['String'];
  /** The type of encoding applied to the string */
  encoding: StringEncoding;
};

export type EnsureUserInput = {
  /** Auth0's identifier for this user */
  auth0UserId: Scalars['String'];
  /** Email address belonging to the user */
  email?: InputMaybe<Scalars['String']>;
  /** Given name of the user */
  firstName?: InputMaybe<Scalars['String']>;
  /** Full name of the user (first name + last name) */
  fullName?: InputMaybe<Scalars['String']>;
  /** Family name of the user */
  lastName?: InputMaybe<Scalars['String']>;
  /** URL to the users avatar/profile picture */
  picture?: InputMaybe<Scalars['String']>;
  /** Users RBAC role from Auth0 SSO connection */
  ssoRole?: InputMaybe<UserRole>;
};

/** Possible error codes returned by this API. */
export enum ErrorCode {
  /** Record has already been processed. */
  AlreadyProcessed = 'ALREADY_PROCESSED',
  /** Has been archived. */
  Archived = 'ARCHIVED',
  /** Creative labs platform is not supported for this action. */
  CreativeLabsIncompatible = 'CREATIVE_LABS_INCOMPATIBLE',
  /** Duplicate record found. */
  Duplicate = 'DUPLICATE',
  /** Requested format length is unavailable for this action. */
  FormatLengthUnavailable = 'FORMAT_LENGTH_UNAVAILABLE',
  /** An argument was invalid (e.g. failed validation etc.). */
  InvalidArgument = 'INVALID_ARGUMENT',
  /** Brief failed validation */
  InvalidBrief = 'INVALID_BRIEF',
  /** Data was in an invalid state. */
  InvalidState = 'INVALID_STATE',
  /** Client was not authorised to perform this action. */
  NotAuthorised = 'NOT_AUTHORISED',
  /** No record found. */
  NotFound = 'NOT_FOUND',
  /** Client was not authorised to perform this action. */
  Unauthorised = 'UNAUTHORISED',
  /** Unhandled error. */
  Unhandled = 'UNHANDLED',
  /** Unknown error. */
  Unknown = 'UNKNOWN',
  DimensionRatioNotUnique = 'dimension_ratio_not_unique',
  UserNotMatch = 'user_not_match'
}

export type ExpireInvitationsInput = {
  /** The keys of the invitations to expire */
  keys: Array<Scalars['String']>;
};

export enum FeatureFlag {
  E2ETestData = 'E2E_TEST_DATA',
  ShuttlerockImagine = 'SHUTTLEROCK_IMAGINE'
}

export type File = {
  __typename?: 'File';
  extension: Scalars['String'];
  fileName: Scalars['String'];
  key: Scalars['ID'];
  preview?: Maybe<FilePreview>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key: Scalars['String'];
  s3Path: Scalars['String'];
  size: Scalars['Int'];
  uploadedAt: Scalars['Time'];
  url: Scalars['String'];
};


export type FilePreviewArgs = {
  options?: InputMaybe<FilePreviewOptions>;
};

export type FilePreview = {
  __typename?: 'FilePreview';
  /** The resulting content/mime type of the transformed file */
  contentType: Scalars['String'];
  /** The URL to the transformed file */
  url: Scalars['String'];
};

export type FilePreviewOptions = {
  /**
   * Limit the file type(s) of the preview to those supported by your platform, or a
   * specific type (i.e. passing only IMAGE to get thumbnail urls).
   */
  accept?: InputMaybe<Array<FilePreviewType>>;
  /**
   * The desired height of the preview.
   * Can be omitted to retain the original height (or aspect ratio if width is provided)
   * @deprecated Use quality instead
   */
  height?: InputMaybe<Scalars['Int']>;
  /**
   * The desired quality of the preview, defaults to UNSCALED.
   * If the quality is not supported for given file and accept param, then a preview will not be returned.
   */
  quality?: InputMaybe<FilePreviewPreset>;
  /**
   * Optimise the preview for use as a thumbnail
   * @deprecated Use quality instead
   */
  thumbnail?: InputMaybe<Scalars['Boolean']>;
  /**
   * The desired width of the preview.
   * Can be omitted to retain the original width (or aspect ratio if height is provided)
   * @deprecated Use quality instead
   */
  width?: InputMaybe<Scalars['Int']>;
};

export enum FilePreviewPreset {
  Scaled_240P = 'SCALED_240P',
  Unscaled = 'UNSCALED'
}

export enum FilePreviewType {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Pdf = 'PDF',
  Video = 'VIDEO'
}

export type FormatInput = {
  dimension: DimensionInput;
  formatIntegrationKey: Scalars['ID'];
  formatLengths: Array<FormatLength>;
  formatName: Scalars['String'];
  platform: PlatformInput;
};

export enum FormatLength {
  Animated = 'ANIMATED',
  Custom = 'CUSTOM',
  Less_6S = 'LESS_6S',
  Less_10S = 'LESS_10S',
  Less_15S = 'LESS_15S',
  Less_30S = 'LESS_30S',
  Static = 'STATIC'
}

/** A container for arguments used when submitting additional formats. */
export type FormatsSubmitInput = {
  /** UUID of the project that Formats are being submitted for. */
  id: Scalars['ID'];
  /** The priority of the formats being submitted. */
  priority?: InputMaybe<ProductionPriority>;
};

/** Gender (i.e. of a creator). */
export type Gender = {
  __typename?: 'Gender';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Human readable label for this gender. */
  label: Scalars['String'];
};

export enum GenderPreference {
  Female = 'Female',
  Male = 'Male',
  Unspecified = 'Unspecified'
}

export enum GenerativeAiCategory {
  Image = 'IMAGE',
  Music = 'MUSIC',
  Video = 'VIDEO',
  Voice = 'VOICE'
}

export type GenerativeAiModel = {
  __typename?: 'GenerativeAIModel';
  /**  Code of the generative AI model.  */
  code: Scalars['String'];
  /**  Whether this generative AI model is the default in its category.  */
  default: Scalars['Boolean'];
  /**  Icon which the generative AI model is to be identified with.  */
  iconUrl: Scalars['String'];
  /**  Name of the generative AI model.  */
  name: Scalars['String'];
  /**  Terms and conditions for the generative AI model.  */
  termsUrl: Scalars['String'];
};

export type GenerativeAiModelOptions = {
  __typename?: 'GenerativeAIModelOptions';
  category: GenerativeAiCategory;
  models: Array<GenerativeAiModel>;
};

export type GenerativeAiPreference = {
  __typename?: 'GenerativeAIPreference';
  /**  Type of category generative AI is to be used for.  */
  category: GenerativeAiCategory;
  /**  The generative AI models that are to be used.  */
  models: Array<GenerativeAiModel>;
  /**  Usage notes for the creative team.  */
  notes?: Maybe<Scalars['String']>;
};

/** A HubSpot deal. */
export type HubspotDeal = {
  __typename?: 'HubspotDeal';
  /** The amount of the deal in the currency of the deal. */
  amount?: Maybe<Scalars['Float']>;
  /** The close date of the deal, this is set at a date level of granularity. */
  closeDate: Scalars['Time'];
  /** The code of the currency of the deal, e.g. NZD. */
  currencyCode: Scalars['String'];
  /** The ID of the deal in HubSpot. */
  id: Scalars['String'];
  /** The name of the deal. */
  name: Scalars['String'];
};

/** The HubSpot deal selection for a project. */
export type HubspotDealSelection = HubspotDeal | NoHubspotDeal;

export type IdArrayFilter = {
  /** return match if all the provided values match */
  all?: InputMaybe<Array<Scalars['ID']>>;
  /** return match if any of the provided values on the record */
  any?: InputMaybe<Array<Scalars['ID']>>;
};

export type IdFilter = {
  /** return match if the provided value matches the record */
  eq?: InputMaybe<Scalars['ID']>;
  /** return match if record in provided values */
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type IntFilter = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated input type of InternallyDeliverOrder */
export type InternallyDeliverOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the order. */
  id: Scalars['ID'];
};

/** Autogenerated return type of InternallyDeliverOrder. */
export type InternallyDeliverOrderPayload = {
  __typename?: 'InternallyDeliverOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The order. */
  order?: Maybe<Order>;
};

export type Invitation = {
  __typename?: 'Invitation';
  /** The UTC timestamp when the invitation was accepted */
  acceptedAt?: Maybe<Scalars['Time']>;
  /** The UTC timestamp when the invitation was created */
  createdAt: Scalars['Time'];
  /** Optional email address of an invitee */
  email?: Maybe<Scalars['String']>;
  /** The UTC timestamp when the invitation last emailed */
  emailedAt?: Maybe<Scalars['Time']>;
  /** The UTC timestamp when the invitation expired */
  expiredAt?: Maybe<Scalars['Time']>;
  /** Optional first name of an invitee */
  firstName?: Maybe<Scalars['String']>;
  /** Optional full name of an invitee */
  fullName?: Maybe<Scalars['String']>;
  /** Unique identifier of the invitation */
  key: Scalars['String'];
  /** Optional last name of an invitee */
  lastName?: Maybe<Scalars['String']>;
  /** The current status of the invitation */
  status: InvitationStatus;
};

/** A container for arguments used when accepting an invitation */
export type InvitationAcceptInput = {
  /** Auth0 integration key to be associated to the accepting user. */
  auth0UserId: Scalars['String'];
  /** The UUID of the invitation to be accepted. */
  id: Scalars['ID'];
};

/** A container for arguments used when resending an invite to a User. */
export type InvitationResendInput = {
  /** UUID of the User to re-invite. */
  id: Scalars['ID'];
  /** Flag indicating whether to skip sending an invitation email to the new user. Defaults to false. */
  skipEmail?: InputMaybe<Scalars['Boolean']>;
};

/** An invitation state. */
export enum InvitationState {
  Accepted = 'accepted',
  Expired = 'expired',
  Pending = 'pending'
}

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export type InvitationStatusFilter = {
  in?: InputMaybe<Array<InvitationStatus>>;
};

export type Invitations = {
  __typename?: 'Invitations';
  meta: OffsetPageInfo;
  nodes: Array<Invitation>;
};

export type InvitationsFilter = {
  /** Fuzzy search over email or name of single user invitations */
  keyword?: InputMaybe<Scalars['String']>;
  orgUnitKey?: InputMaybe<IdFilter>;
  status?: InputMaybe<InvitationStatusFilter>;
};

export type InvitationsParams = {
  filter?: InputMaybe<InvitationsFilter>;
  pagination?: InputMaybe<OffsetPagination>;
  sort?: InputMaybe<SortInput>;
};

/** Autogenerated input type of InviteNewUser */
export type InviteNewUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the User to be invited. */
  invite: InviteUserInput;
};

/** Autogenerated return type of InviteNewUser. */
export type InviteNewUserPayload = {
  __typename?: 'InviteNewUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The invited User. */
  user?: Maybe<LegacyUser>;
};

/** A container for arguments used when inviting a new user */
export type InviteUserInput = {
  /**
   * UUID of the Client to add the new User to.
   * @deprecated Use `clientIntegrationKey` instead.
   */
  clientId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the Client to add the new User to. */
  clientIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** Email address of the invited User, where an invitation email will be sent. */
  email: Scalars['String'];
  /** Given name of the new User. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Family name of the new User. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Flag indicating whether to skip sending an invitation email to the new user. Defaults to false. */
  skipEmail?: InputMaybe<Scalars['Boolean']>;
};

/** Language (i.e. that a creator speaks). */
export type Language = {
  __typename?: 'Language';
  /** The time this language was archived, or null */
  archivedAt?: Maybe<Scalars['Time']>;
  /** A foreign key used to uniquely identify this language within SRC */
  code: Scalars['String'];
  /** A (mostly) ISO-639 code used by the Google Translate API (see https://cloud.google.com/translate/docs/languages) */
  googleCode?: Maybe<Scalars['String']>;
  /** A human-readable english name for the language */
  name: Scalars['String'];
};

/** A paginated array of Deliverables */
export type LegacyDeliverables = {
  __typename?: 'LegacyDeliverables';
  /** The total number of Deliverables */
  count: Scalars['Int'];
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Deliverables */
  nodes: Array<Deliverable>;
};

/** Invitation details for a specific user. */
export type LegacyInvitation = {
  __typename?: 'LegacyInvitation';
  /** The UTC timestamp when the Invitation was accepted. */
  acceptedAt?: Maybe<Scalars['Int']>;
  /** The UTC timestamp when the Invitation was created. */
  createdAt: Scalars['Int'];
  /** Email address the invitation was sent to. */
  email?: Maybe<Scalars['String']>;
  /** Whether the invite email address has already been registered. */
  emailRegistered: Scalars['Boolean'];
  /** The UTC timestamp when the Invitation was expired. */
  expiredAt?: Maybe<Scalars['Int']>;
  /** Given name of the invited user. */
  firstName?: Maybe<Scalars['String']>;
  /** The UUID of the Invitation. */
  id: Scalars['ID'];
  /** Family name of the invited user. */
  lastName?: Maybe<Scalars['String']>;
  /** The UTC timestamp when the Invitation was sent. */
  sentAt?: Maybe<Scalars['Int']>;
  /** The current state of the Invitation. */
  state: InvitationState;
  /** Unique token used to redeem the Invitation. */
  token?: Maybe<Scalars['String']>;
  /** URL used to sign up to SRC as the user associated to the Invitation. */
  url?: Maybe<Scalars['String']>;
};

/** A User. */
export type LegacyUser = {
  __typename?: 'LegacyUser';
  /** The number of active deliverables a Subscription user currently has. */
  activeDeliverables?: Maybe<Scalars['Int']>;
  /** The number of active hero deliverables a License user currently has. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** The Key of the Client Account the user is associated with. */
  clientAccountIntegrationKey?: Maybe<Scalars['ID']>;
  /**
   * Clients the User has access to. (Note that we filter these by those the requesting User has access to).
   *
   * Permitted search attributes: ["id", "account_type_name", "hubspot_id", "name",
   * "region_name", "account_type", "ancestry", "enterprise_ancestry",
   * "region_ancestry", "group_ancestry", "brands", "enterprises", "groups",
   * "region_groups", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "name", "region_name"]
   */
  clients: Clients;
  /** The UTC timestamp for the User's creation, as an Integer. */
  createdAt: Scalars['Int'];
  /** Email address belonging to the user, sourced from Auth0. */
  email?: Maybe<Scalars['String']>;
  /** The feature flags of the User. */
  featureFlags: Array<FeatureFlag>;
  /** Given name of the user, sourced from Auth0 */
  firstName?: Maybe<Scalars['String']>;
  /** Full name of the user, sourced from Auth0 */
  fullName?: Maybe<Scalars['String']>;
  /** The UUID of the User. */
  id: Scalars['ID'];
  /** The integration key of the User. */
  integrationKey: Scalars['ID'];
  /** The Invitation details of the User */
  invitation?: Maybe<LegacyInvitation>;
  /** Family name of the user, sourced from Auth0 */
  lastName?: Maybe<Scalars['String']>;
  /** Flag indicating whether the user is a License user or not. */
  license?: Maybe<Scalars['Boolean']>;
  /** Flag indicating whether the user is a License Plus user or not. */
  licensePlus?: Maybe<Scalars['Boolean']>;
  /** The total number of active deliverables allowed for a Subscription user. */
  maxActiveDeliverables?: Maybe<Scalars['Int']>;
  /** The total number of active heroes allowed for a License user. */
  maxActiveHeroes?: Maybe<Scalars['Int']>;
  /**
   * Orders submitted by the User. (Note that we filter these by those the requesting User has access to).
   *
   * Permitted sort attributes: ["created_at", "active_states", "best_of",
   * "brand_name", "client_name", "completed_at", "created_at", "enterprise_name",
   * "name", "state", "submitted_at", "target_date", "target_date_nulls",
   * "total_deliverables_ordered"]
   */
  orders: Orders;
  /** Auth0 picture attached to the user */
  picture?: Maybe<Scalars['String']>;
  /** Studio the User belongs to. */
  studio?: Maybe<Studio>;
  /** Flag indicating whether the user is a Subscription user or not. */
  subscription?: Maybe<Scalars['Boolean']>;
};


/** A User. */
export type LegacyUserClientsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** A User. */
export type LegacyUserOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortInput>>;
  state?: InputMaybe<OrderState>;
};

/** A paginated array of Users */
export type LegacyUsers = {
  __typename?: 'LegacyUsers';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Users */
  nodes: Array<LegacyUser>;
};

export type Market = {
  __typename?: 'Market';
  /** The shorthand code for the Market */
  code: Scalars['String'];
  /** The full name of the Market */
  label: Scalars['String'];
};

export type Me = {
  __typename?: 'Me';
  creator?: Maybe<Creator>;
  key: Scalars['ID'];
  user?: Maybe<User>;
};

/** Media (i.e. of a creator). */
export type Media = {
  __typename?: 'Media';
  /** The media file. */
  file: File;
  /**
   * Key allowing the correct media file to be identified and retrieved
   * @deprecated Use `file` instead.
   */
  fileKey: Scalars['ID'];
  /** Unique identifier. */
  id: Scalars['ID'];
  /** The type of media attached to the creator */
  type: CreatorMediaType;
};

/** Media Input. */
export type MediaInput = {
  /** File key */
  fileKey: Scalars['ID'];
  /** Media type */
  mediaType: CreatorMediaType;
};

export enum MediaType {
  Image = 'Image',
  Video = 'Video'
}

/** Pagination metadata for paginated content */
export type Metadata = {
  __typename?: 'Metadata';
  /** The current page number */
  currentPage: Scalars['Int'];
  /** The number of results returned on the current page */
  currentResults: Scalars['Int'];
  /** Whether or not there are more pages to be fetched */
  hasMore: Scalars['Boolean'];
  /** The total number of pages */
  numPages: Scalars['Int'];
  /** The total number of results */
  numResults: Scalars['Int'];
  /** The maximum number of results returned per page */
  perPage: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCreatorOffer: UgcOrder;
  /** Accept an invitation to join Shuttlerock Cloud. Invitations can only be used once. */
  acceptInvitation?: Maybe<AcceptInvitationPayload>;
  acknowledgeNotification: Notification;
  /**
   * Associate a collaborator with a Project if they are not already a collaborator of any type.
   *
   * Requires authorization.
   */
  addCollaboratorToProjectIfNeeded: Project;
  /**
   * Associate collaborators with a Project.
   * Requires authorization.
   */
  addCollaboratorsToProject: Project;
  /** Add a new comment to a revision. */
  addComment: Comment;
  /** @deprecated Use the addVariation mutation instead. */
  addConceptVariation: SpecificationConcept;
  /**
   * Add a format to a concept.
   *
   * Requires authorization.
   */
  addDeliverableFormatToConcept?: Maybe<AddDeliverableFormatToConceptPayload>;
  /**
   * Associate a Specification to a Deliverable.
   *
   * Requires authorization.
   */
  addSpecificationToDeliverable?: Maybe<AddSpecificationToDeliverablePayload>;
  addVariation: Variation;
  /**
   * Answer Questions in bulk during Order briefing.
   *
   * Requires authorization.
   */
  answerQuestions?: Maybe<AnswerQuestionsPayload>;
  approveBrief: Scalars['Boolean'];
  /**
   * Approve an Order and its deliverables.
   *
   * Requires authorization.
   */
  approveDeliverables?: Maybe<ApproveDeliverablesPayload>;
  approveHeroDeliverable: RevisionDeliverable;
  /** Archive an asset */
  archiveAsset?: Maybe<ArchiveAssetPayload>;
  /** Archives assets from an order */
  archiveAssets?: Maybe<ArchiveAssetsPayload>;
  archiveAudience?: Maybe<Audience>;
  archiveRevision: Scalars['Boolean'];
  archiveUgcAssets: Array<UgcAsset>;
  /** @deprecated Use deactivateUgcDeliverables instead */
  archiveUgcDeliverables: Scalars['Boolean'];
  archiveUgcOrder?: Maybe<UgcOrder>;
  /** Archive users. */
  archiveUsers: Array<User>;
  /**
   * Submit a brief to the creative team for asset production.\n\nRequires
   * authorization, should only be called via event handler.
   */
  briefSubmittedForAssetProduction?: Maybe<BriefSubmittedForAssetProductionPayload>;
  /**
   * Submit a brief to the customer representative for review.\n\nThe brief will
   * still be editable after submission.\n\nOn submission, the submitting user will
   * be attributed to the order.\n\nRequires authorization, should only be called
   * via event handler.
   */
  briefSubmittedForCsReview?: Maybe<BriefSubmittedForCsReviewPayload>;
  /**
   * Confirms a briefs CS Review state and moves the project state from cs_review
   * to production_review.Requires authorization, should only be called via event handler.
   */
  briefSubmittedForProductionReview?: Maybe<BriefSubmittedForProductionReviewPayload>;
  /**
   * Submit a brief to the creative team for ugc ideation.\n\nThe brief will still
   * be editable after submission.\n\nOn submission, the submitting user will be
   * attributed to the order.\n\nRequires authorization, should only be called via event handler.
   */
  briefSubmittedForUgcIdeation?: Maybe<BriefSubmittedForUgcIdeationPayload>;
  /** Claim an invitation. */
  claimInvitation: Scalars['Boolean'];
  /**
   * Claim an existing user with a claim code.
   * @deprecated Use `claimInvitation` instead. Will be removed in the future
   */
  claimUser: User;
  /**
   * Cleanup E2E test data.
   *
   * Requires authorization.
   */
  cleanupE2eTestData?: Maybe<CleanupE2eTestDataPayload>;
  /**
   * Complete an order in progress or in client review.
   *
   * Requires authorization.
   */
  completeOrder?: Maybe<CompleteOrderPayload>;
  /** Confirm an brief for processing. Note that once confirmed the order will no longer be editable.Requires authorization. */
  confirmOrder?: Maybe<ConfirmOrderPayload>;
  /**
   * Create a new Advert.
   *
   * Requires authorization.
   */
  createAdvert?: Maybe<CreateAdvertPayload>;
  /**
   * Create multiple new assets for a given client or order.
   *
   * Requires authorization.
   */
  createAssets?: Maybe<CreateAssetsPayload>;
  /** Create a brand logo asset for a given client and deletes the old one */
  createBrandLogo?: Maybe<CreateBrandLogoPayload>;
  /**
   * Create a new client (brand or enterprise node).
   *
   * Requires authorization.
   */
  createClient?: Maybe<CreateClientPayload>;
  /**
   * Create a new concept variation.
   *
   * Requires authorization.
   */
  createConceptVariation?: Maybe<CreateConceptVariationPayload>;
  /**
   * Create a copy variation for an Advert.
   *
   * Requires authorization.
   */
  createCopyVariation?: Maybe<CreateCopyVariationPayload>;
  /** Endpoint to create a new file */
  createFile: File;
  /** Create a new invitation. */
  createInvitation: Invitation;
  createOrUpdateAudience?: Maybe<Audience>;
  createOrUpdateBrandProfile: BrandProfile;
  createOrUpdateConcept: Concept;
  /** Create or update a creator. */
  createOrUpdateCreator: Creator;
  /** Create or update a hobby / interest. */
  createOrUpdateCreatorHobbyInterest: CreatorHobbyInterest;
  /** Create or update a specialty vertical. */
  createOrUpdateCreatorSpecialtyVertical: CreatorSpecialtyVertical;
  /** @deprecated Files are immutable, use createFile instead. */
  createOrUpdateFile: File;
  createOrUpdateScene: Scene;
  /** Create or update a ugcOrder. */
  createOrUpdateUgcOrder?: Maybe<UgcOrder>;
  /**
   * Create a new Order.
   *
   * Requires authorization.
   */
  createOrder?: Maybe<CreateOrderPayload>;
  /**
   * Create a new Production Delivery Turnaround for a project
   *
   * Requires authorization.
   */
  createProductionDeliveryTurnaround?: Maybe<CreateProductionDeliveryTurnaroundPayload>;
  createUgcBrandAsset?: Maybe<UgcAsset>;
  createUgcDeliverable: UgcDeliverable;
  createUgcReferenceAsset?: Maybe<UgcAsset>;
  /**
   * Create a new user.
   * @deprecated Shouldn't be required. Will be removed in the future
   */
  createUser: User;
  createVariationBrief: VariationBrief;
  /** Sign up creator linked to current user. */
  creatorSignUp: Scalars['ID'];
  /**
   * Mark one or more UGC deliverables as inactive.
   * These will remain visible to the creator.
   */
  deactivateUgcDeliverables: Scalars['Boolean'];
  declineCreatorOffer: Scalars['Boolean'];
  /** Delete a creator. */
  deleteCreator: Creator;
  /** Delete a hobby / interest. */
  deleteCreatorHobbyInterest: CreatorHobbyInterest;
  /** Delete a specialty vertical. */
  deleteCreatorSpecialtyVertical: CreatorSpecialtyVertical;
  deleteUgcDeliverables: Scalars['Boolean'];
  /**
   * Delivers an order. Once delivered, the client can review deliverables.
   *
   * Note that an order can only be delivered with this mutation if it is in Internal revision with Shuttlerock staff.
   *
   * Requires authorization.
   */
  deliverOrder?: Maybe<DeliverOrderPayload>;
  /**
   * Unapprove an approved deliverable.
   *
   * Requires authorization.
   */
  deliverableUnapproved?: Maybe<DeliverableUnapprovedPayload>;
  /**
   * Destroys an advert.
   *
   * Requires authorization.
   */
  destroyAdvert?: Maybe<DestroyAdvertPayload>;
  /**
   * Destroys a Concept Variation.
   *
   * Requires authorization.
   */
  destroyConceptVariation?: Maybe<DestroyConceptVariationPayload>;
  /** Disable the CreativeX integration for a client account */
  disableCreativeXIntegration: ClientAccountIntegrations;
  /**
   * Discard a brand.
   *
   * Requires authorization.
   */
  discardBrand?: Maybe<DiscardBrandPayload>;
  /**
   * Discard a client account.
   *
   * Requires authorization.
   */
  discardClientAccount?: Maybe<DiscardClientAccountPayload>;
  /**
   * Destroys a Deliverable.
   *
   * Requires authorization.
   */
  discardDeliverable?: Maybe<DiscardDeliverablePayload>;
  /**
   * Discard a drafted order. Submitted orders cannot be discarded.
   *
   * Requires authorization.
   */
  discardOrder?: Maybe<DiscardOrderPayload>;
  /**
   * Discards a Working File.
   *
   * Requires authorization.
   */
  discardWorkingFile?: Maybe<DiscardWorkingFilePayload>;
  /**
   * Duplicates a concept - accessible to users that can edit the parent project.
   *
   * Requires authorization.
   */
  duplicateConcept?: Maybe<DuplicateConceptPayload>;
  /**
   * Duplicates an order to the given client (defaults to same client) - accessible to all users that can create a project.
   *
   * Requires authorization.
   */
  duplicateOrder?: Maybe<DuplicateOrderPayload>;
  /**
   * Duplicates a project to the same client, including answers and assets - accessible to all users that can create a project.
   *
   * Requires authorization.
   */
  duplicateProject?: Maybe<DuplicateProjectPayload>;
  /** Edit an existing revision comment. */
  editComment: Comment;
  editRevision: Revision;
  /**
   * Retrieves the auth0 user using the provided access token, creating a new auth0 user if one does not exist.
   * @deprecated Use `ensureUser` instead. Will be removed in the future
   */
  ensureAuth0User: Auth0User;
  /**
   * Retrieves the Auth0User using the provided access token, creating a
   * new Auth0User (and corresponding User) if they do not exist.
   */
  ensureUser: Auth0User;
  /** Expire invitations. */
  expireInvitations: Array<Invitation>;
  /**
   * Deliver internally an order.
   *
   * Requires authorization.
   */
  internallyDeliverOrder?: Maybe<InternallyDeliverOrderPayload>;
  /**
   * Invite a new User to an existing Client.
   *
   * Requires authorization.
   */
  inviteNewUser?: Maybe<InviteNewUserPayload>;
  /**
   * Create a new Production Delivery Turnaround for a project
   *
   * Requires authorization.
   */
  productionStageStarted?: Maybe<ProductionStageStartedPayload>;
  /**
   * Reject an Order and its deliverables.
   *
   * Requires authorization.
   */
  rejectDeliverables?: Maybe<RejectDeliverablesPayload>;
  /** Reject an existing revision with a comment. */
  rejectRevision: Revision;
  /**
   * Remove collaborators from a Project.
   *
   * Requires authorization.
   */
  removeCollaboratorsFromProject: Project;
  /** Remove an existing revision comment by ID. */
  removeComment: Scalars['Boolean'];
  /** @deprecated Use the removeVariation mutation instead. */
  removeConceptVariation: Scalars['ID'];
  /** Remove the CreativeX brand for a client brand */
  removeCreativeXBrand: ClientBrand;
  /**
   * Remove deliverable formats from a concept.
   *
   * Requires authorization.
   */
  removeDeliverableFormatsFromConcept?: Maybe<RemoveDeliverableFormatsFromConceptPayload>;
  /** Remove a draft specification. Returns the specification ID. */
  removeSpecification: Scalars['ID'];
  /**
   * Remove a user from an existing Client account.
   *
   * Requires authorization.
   */
  removeUser?: Maybe<RemoveUserPayload>;
  removeVariation: Scalars['ID'];
  /** Renew an invitation. */
  renewInvitation: Invitation;
  /**
   * Replace an already existing deliverable.
   *
   * Requires authorization.
   */
  replaceDeliverable?: Maybe<ReplaceDeliverablePayload>;
  /** Request that the user's account be deleted. */
  requestAccountDeletion: Scalars['Boolean'];
  requestRevision: Revision;
  /**
   * Resend an invite email to a User.
   *
   * Requires authorization.
   */
  resendLegacyInvitation?: Maybe<ResendLegacyInvitationPayload>;
  /** Revert a brief to UGC Ideation.\n\nRequires authorization, should only be called via event handler. */
  revertBriefToUgcIdeation?: Maybe<RevertBriefToUgcIdeationPayload>;
  /**
   * Revise an Order.
   *
   * Requires authorization.
   */
  reviseOrder?: Maybe<ReviseOrderPayload>;
  revokeCreatorOffer: Scalars['Boolean'];
  selectCreator: UgcOrder;
  /** Add notes to an asset. */
  setAssetNotes?: Maybe<SetAssetNotesPayload>;
  setConceptLanguage: Concept;
  setCreatorShortlist: UgcOrder;
  /**
   * Set a deal for a project.
   *
   * Requires authorization.
   */
  setDealForProject: Project;
  /**
   * Set the flag to enable UGC ordering for brands attached to a specific client account.
   *
   * Requires authorization.
   * @deprecated Use updateClientAccount instead
   */
  setEnableUgcOrdering: ClientAccount;
  /**
   * Updates the generation status .
   *
   * Requires authorization.
   */
  setOrderCopilotStatus?: Maybe<SetOrderCopilotStatusPayload>;
  /** Set a projects target date using the given ID. */
  setOrderTargetDate?: Maybe<SetOrderTargetDatePayload>;
  /**
   * Set the access settings of a Project.
   *
   * Requires authorization.
   */
  setProjectAccess: ProjectAccessSettings;
  setUgcAssetNote?: Maybe<UgcAsset>;
  submitAdaptRequest?: Maybe<Scalars['ID']>;
  submitBriefForAssetProduction: Scalars['Boolean'];
  submitBriefForCsReview: Scalars['Boolean'];
  submitBriefForProductionReview: Scalars['Boolean'];
  submitBriefForUgcIdeation: Scalars['Boolean'];
  /** Submit registration for a creator. */
  submitCreatorRegistration: Scalars['ID'];
  /**
   * Submit additional formats for an order.
   *
   * Requires authorization.
   */
  submitFormats?: Maybe<SubmitFormatsPayload>;
  /**
   * Submit an order for processing. Note that once submitted the order will no
   * longer be editable.On submission, the submitting user will be attributed to the order.
   *
   * Requires authorization.
   */
  submitOrder?: Maybe<SubmitOrderPayload>;
  submitPostBriefFormats: Scalars['Boolean'];
  /** Submit a CES rating for a project. */
  submitProjectCes: Scalars['Boolean'];
  /** Submit a CSAT rating for a project. */
  submitProjectCsat: Scalars['Boolean'];
  submitProjectFormats: Project;
  /** Submit the given UGC order's pending UGC deliverables for review */
  submitUgcDeliverables: Scalars['Boolean'];
  /**
   * Temporary mutation to sync user records created in the new user service back
   * to rails. Requires authorization, should only be called via event handler.
   */
  syncLegacyUser?: Maybe<SyncLegacyUserPayload>;
  /** Un-archive users. */
  unArchiveUsers: Array<User>;
  unapproveDeliverable: RevisionDeliverable;
  unarchiveRevision: Scalars['Boolean'];
  /**
   * Update the Advert with the given ID.
   *
   * Requires authorization.
   */
  updateAdvert?: Maybe<UpdateAdvertPayload>;
  /**
   * Update an auth0_integration_key for an existing user.
   *
   * Requires authorization.
   */
  updateAuth0IntegrationKey?: Maybe<UpdateAuth0IntegrationKeyPayload>;
  /**
   * Update the client with the given ID.
   *
   * Requires authorization.
   */
  updateClient?: Maybe<UpdateClientPayload>;
  /**
   * Update a client account.
   *
   * Requires authorization.
   */
  updateClientAccount: ClientAccount;
  /**
   * Update generative AI preferences for a client account.
   *
   * Requires authorization.
   */
  updateClientAccountGenerativeAIPreferences: ClientAccount;
  /**
   * Update a client brand.
   *
   * Requires authorization.
   */
  updateClientBrand: ClientBrand;
  /** @deprecated Use the updateVariationDescription mutation instead. */
  updateConceptDescription: SpecificationConcept;
  /** @deprecated Use the updateVariationFormats mutation instead. */
  updateConceptFormats: Array<DeliverableSpecification>;
  updateConceptNotes: RevisionConcept;
  /** @deprecated Use the updateVariationFormats mutation instead. */
  updateConceptVariationFormats: Array<DeliverableSpecification>;
  /** Update the CreativeX brand for a client brand */
  updateCreativeXBrand: ClientBrand;
  /** Update the CreativeX integration for a client account */
  updateCreativeXIntegration: ClientAccountIntegrations;
  updateCsReviewNotes: ProjectBrief;
  /**
   * Update an existing deliverable.
   *
   * Requires authorization.
   */
  updateDeliverable?: Maybe<UpdateDeliverablePayload>;
  /** Trigger update of a project's delivery turnaround time. */
  updateDeliveryTurnaround?: Maybe<UpdateDeliveryTurnaroundPayload>;
  /** Update the current user. */
  updateLegacyUser?: Maybe<UpdateLegacyUserPayload>;
  /**
   * Update an existing order.
   *
   * Requires authorization.
   */
  updateOrder?: Maybe<UpdateOrderPayload>;
  /**
   * Update the deliverables of an existing order.
   *
   * Requires authorization.
   */
  updateOrderDeliverables?: Maybe<UpdateOrderDeliverablesPayload>;
  /**
   * Update the order's translations with the given ID.
   *
   * Requires authorization.
   */
  updateTranslations?: Maybe<UpdateTranslationsPayload>;
  /**
   * Update a trello card belonging to the order with the given ID.
   *
   * Requires authorization.
   */
  updateTrello?: Maybe<UpdateTrelloPayload>;
  /** Trigger update of labels on a project's Trello card. */
  updateTrelloLabels?: Maybe<UpdateTrelloLabelsPayload>;
  updateUgcBrief?: Maybe<UgcBrief>;
  updateUgcDeliverable: UgcDeliverable;
  /** Update a user. */
  updateUser: User;
  updateVariationBrief: VariationBrief;
  updateVariationDescription: Variation;
  updateVariationFormats: Variation;
};


export type MutationAcceptCreatorOfferArgs = {
  key: Scalars['ID'];
};


export type MutationAcceptInvitationArgs = {
  input: AcceptInvitationInput;
};


export type MutationAcknowledgeNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationAddCollaboratorToProjectIfNeededArgs = {
  integrationKey: Scalars['ID'];
  userIntegrationKey: Scalars['ID'];
};


export type MutationAddCollaboratorsToProjectArgs = {
  integrationKey: Scalars['ID'];
  userIntegrationKeys: Array<Scalars['ID']>;
};


export type MutationAddCommentArgs = {
  input: AddCommentInput;
};


export type MutationAddConceptVariationArgs = {
  input: AddConceptVariationInput;
};


export type MutationAddDeliverableFormatToConceptArgs = {
  input: AddDeliverableFormatToConceptInput;
};


export type MutationAddSpecificationToDeliverableArgs = {
  input: AddSpecificationToDeliverableInput;
};


export type MutationAddVariationArgs = {
  input: AddVariationInput;
};


export type MutationAnswerQuestionsArgs = {
  input: AnswerQuestionsInput;
};


export type MutationApproveBriefArgs = {
  input: ApproveBriefInput;
};


export type MutationApproveDeliverablesArgs = {
  input: ApproveDeliverablesInput;
};


export type MutationApproveHeroDeliverableArgs = {
  key: Scalars['ID'];
};


export type MutationArchiveAssetArgs = {
  input: ArchiveAssetInput;
};


export type MutationArchiveAssetsArgs = {
  input: ArchiveAssetsInput;
};


export type MutationArchiveAudienceArgs = {
  integrationKey: Scalars['ID'];
};


export type MutationArchiveRevisionArgs = {
  id: Scalars['ID'];
};


export type MutationArchiveUgcAssetsArgs = {
  input: ArchiveUgcAssetsInput;
};


export type MutationArchiveUgcDeliverablesArgs = {
  input: ArchiveUgcDeliverablesInput;
};


export type MutationArchiveUgcOrderArgs = {
  input: ArchiveUgcOrderInput;
};


export type MutationArchiveUsersArgs = {
  input: ArchiveUsersInput;
};


export type MutationBriefSubmittedForAssetProductionArgs = {
  input: BriefSubmittedForAssetProductionInput;
};


export type MutationBriefSubmittedForCsReviewArgs = {
  input: BriefSubmittedForCsReviewInput;
};


export type MutationBriefSubmittedForProductionReviewArgs = {
  input: BriefSubmittedForProductionReviewInput;
};


export type MutationBriefSubmittedForUgcIdeationArgs = {
  input: BriefSubmittedForUgcIdeationInput;
};


export type MutationClaimInvitationArgs = {
  input: ClaimInvitationInput;
};


export type MutationClaimUserArgs = {
  claimCode: Scalars['String'];
};


export type MutationCleanupE2eTestDataArgs = {
  input: CleanupE2eTestDataInput;
};


export type MutationCompleteOrderArgs = {
  input: CompleteOrderInput;
};


export type MutationConfirmOrderArgs = {
  input: ConfirmOrderInput;
};


export type MutationCreateAdvertArgs = {
  input: CreateAdvertInput;
};


export type MutationCreateAssetsArgs = {
  input: CreateAssetsInput;
};


export type MutationCreateBrandLogoArgs = {
  input: CreateBrandLogoInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateConceptVariationArgs = {
  input: CreateConceptVariationInput;
};


export type MutationCreateCopyVariationArgs = {
  input: CreateCopyVariationInput;
};


export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


export type MutationCreateInvitationArgs = {
  input: CreateInvitationInput;
};


export type MutationCreateOrUpdateAudienceArgs = {
  input: CreateOrUpdateAudienceInput;
};


export type MutationCreateOrUpdateBrandProfileArgs = {
  input: CreateOrUpdateBrandProfileInput;
};


export type MutationCreateOrUpdateConceptArgs = {
  input: CreateOrUpdateConceptInput;
};


export type MutationCreateOrUpdateCreatorArgs = {
  input: CreatorInput;
};


export type MutationCreateOrUpdateCreatorHobbyInterestArgs = {
  id?: InputMaybe<Scalars['ID']>;
  label: Scalars['String'];
};


export type MutationCreateOrUpdateCreatorSpecialtyVerticalArgs = {
  id?: InputMaybe<Scalars['ID']>;
  label: Scalars['String'];
};


export type MutationCreateOrUpdateFileArgs = {
  input: CreateOrUpdateFileInput;
};


export type MutationCreateOrUpdateSceneArgs = {
  input: CreateOrUpdateSceneInput;
};


export type MutationCreateOrUpdateUgcOrderArgs = {
  input: UgcOrderInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateProductionDeliveryTurnaroundArgs = {
  input: CreateProductionDeliveryTurnaroundInput;
};


export type MutationCreateUgcBrandAssetArgs = {
  input: CreateUgcAssetInput;
};


export type MutationCreateUgcDeliverableArgs = {
  input: CreateUgcDeliverableInput;
};


export type MutationCreateUgcReferenceAssetArgs = {
  input: CreateUgcAssetInput;
};


export type MutationCreateVariationBriefArgs = {
  input: CreateVariationBriefInput;
};


export type MutationCreatorSignUpArgs = {
  input: SubmitCreatorRegistrationInput;
};


export type MutationDeactivateUgcDeliverablesArgs = {
  input: DeactivateUgcDeliverablesInput;
};


export type MutationDeclineCreatorOfferArgs = {
  input: DeclineCreatorOfferInput;
};


export type MutationDeleteCreatorArgs = {
  key: Scalars['ID'];
};


export type MutationDeleteCreatorHobbyInterestArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCreatorSpecialtyVerticalArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUgcDeliverablesArgs = {
  input: ArchiveUgcDeliverablesInput;
};


export type MutationDeliverOrderArgs = {
  input: DeliverOrderInput;
};


export type MutationDeliverableUnapprovedArgs = {
  input: DeliverableUnapprovedInput;
};


export type MutationDestroyAdvertArgs = {
  input: DestroyAdvertInput;
};


export type MutationDestroyConceptVariationArgs = {
  input: DestroyConceptVariationInput;
};


export type MutationDisableCreativeXIntegrationArgs = {
  clientAccountKey: Scalars['ID'];
};


export type MutationDiscardBrandArgs = {
  input: DiscardBrandInput;
};


export type MutationDiscardClientAccountArgs = {
  input: DiscardClientAccountInput;
};


export type MutationDiscardDeliverableArgs = {
  input: DiscardDeliverableInput;
};


export type MutationDiscardOrderArgs = {
  input: DiscardOrderInput;
};


export type MutationDiscardWorkingFileArgs = {
  input: DiscardWorkingFileInput;
};


export type MutationDuplicateConceptArgs = {
  input: DuplicateConceptInput;
};


export type MutationDuplicateOrderArgs = {
  input: DuplicateOrderInput;
};


export type MutationDuplicateProjectArgs = {
  input: DuplicateProjectInput;
};


export type MutationEditCommentArgs = {
  input: EditCommentInput;
};


export type MutationEditRevisionArgs = {
  input: EditRevisionInput;
};


export type MutationEnsureAuth0UserArgs = {
  auth0UserId: Scalars['String'];
};


export type MutationEnsureUserArgs = {
  input: EnsureUserInput;
};


export type MutationExpireInvitationsArgs = {
  input: ExpireInvitationsInput;
};


export type MutationInternallyDeliverOrderArgs = {
  input: InternallyDeliverOrderInput;
};


export type MutationInviteNewUserArgs = {
  input: InviteNewUserInput;
};


export type MutationProductionStageStartedArgs = {
  input: ProductionStageStartedInput;
};


export type MutationRejectDeliverablesArgs = {
  input: RejectDeliverablesInput;
};


export type MutationRejectRevisionArgs = {
  input: RejectRevisionInput;
};


export type MutationRemoveCollaboratorsFromProjectArgs = {
  integrationKey: Scalars['ID'];
  userIntegrationKeys: Array<Scalars['ID']>;
};


export type MutationRemoveCommentArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveConceptVariationArgs = {
  input: RemoveConceptVariationInput;
};


export type MutationRemoveCreativeXBrandArgs = {
  clientBrandKey: Scalars['ID'];
};


export type MutationRemoveDeliverableFormatsFromConceptArgs = {
  input: RemoveDeliverableFormatsFromConceptInput;
};


export type MutationRemoveSpecificationArgs = {
  key: Scalars['ID'];
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationRemoveVariationArgs = {
  input: RemoveVariationInput;
};


export type MutationRenewInvitationArgs = {
  input: RenewInvitationInput;
};


export type MutationReplaceDeliverableArgs = {
  input: ReplaceDeliverableInput;
};


export type MutationRequestRevisionArgs = {
  input: RevisionInput;
};


export type MutationResendLegacyInvitationArgs = {
  input: ResendLegacyInvitationInput;
};


export type MutationRevertBriefToUgcIdeationArgs = {
  input: RevertBriefToUgcIdeationInput;
};


export type MutationReviseOrderArgs = {
  input: ReviseOrderInput;
};


export type MutationRevokeCreatorOfferArgs = {
  input: RevokeCreatorOfferInput;
};


export type MutationSelectCreatorArgs = {
  creatorKey?: InputMaybe<Scalars['ID']>;
  ugcOrderKey: Scalars['ID'];
};


export type MutationSetAssetNotesArgs = {
  input: SetAssetNotesInput;
};


export type MutationSetConceptLanguageArgs = {
  input: SetConceptLanguageInput;
};


export type MutationSetCreatorShortlistArgs = {
  creatorKeys: Array<Scalars['ID']>;
  ugcOrderKey: Scalars['ID'];
};


export type MutationSetDealForProjectArgs = {
  input: SetDealForProjectInput;
};


export type MutationSetEnableUgcOrderingArgs = {
  input: SetEnableUgcOrderingInput;
};


export type MutationSetOrderCopilotStatusArgs = {
  input: SetOrderCopilotStatusInput;
};


export type MutationSetOrderTargetDateArgs = {
  input: SetOrderTargetDateInput;
};


export type MutationSetProjectAccessArgs = {
  input: SetProjectAccessInput;
};


export type MutationSetUgcAssetNoteArgs = {
  input: SetUgcAssetNoteInput;
};


export type MutationSubmitAdaptRequestArgs = {
  input: SubmitAdaptRequestInput;
};


export type MutationSubmitBriefForAssetProductionArgs = {
  projectKey: Scalars['ID'];
};


export type MutationSubmitBriefForCsReviewArgs = {
  input: SubmitBriefForCsReviewInput;
};


export type MutationSubmitBriefForProductionReviewArgs = {
  input: SubmitBriefForProductionReviewInput;
};


export type MutationSubmitBriefForUgcIdeationArgs = {
  projectKey: Scalars['ID'];
};


export type MutationSubmitCreatorRegistrationArgs = {
  input: SubmitCreatorRegistrationInput;
};


export type MutationSubmitFormatsArgs = {
  input: SubmitFormatsInput;
};


export type MutationSubmitOrderArgs = {
  input: SubmitOrderInput;
};


export type MutationSubmitPostBriefFormatsArgs = {
  priority?: InputMaybe<ProductionPriority>;
  projectKey: Scalars['ID'];
};


export type MutationSubmitProjectCesArgs = {
  input: ProjectCesInput;
};


export type MutationSubmitProjectCsatArgs = {
  input: ProjectCsatInput;
};


export type MutationSubmitProjectFormatsArgs = {
  projectKey: Scalars['ID'];
};


export type MutationSubmitUgcDeliverablesArgs = {
  input: SubmitUgcDeliverablesInput;
};


export type MutationSyncLegacyUserArgs = {
  input: SyncLegacyUserInput;
};


export type MutationUnArchiveUsersArgs = {
  input: ArchiveUsersInput;
};


export type MutationUnapproveDeliverableArgs = {
  input: UnapproveDeliverableInput;
};


export type MutationUnarchiveRevisionArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateAdvertArgs = {
  input: UpdateAdvertInput;
};


export type MutationUpdateAuth0IntegrationKeyArgs = {
  input: UpdateAuth0IntegrationKeyInput;
};


export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};


export type MutationUpdateClientAccountArgs = {
  input: UpdateClientAccountInput;
};


export type MutationUpdateClientAccountGenerativeAiPreferencesArgs = {
  input: ClientAccountGenerativeAiPreferencesInput;
};


export type MutationUpdateClientBrandArgs = {
  input: UpdateClientBrandInput;
};


export type MutationUpdateConceptDescriptionArgs = {
  input: UpdateConceptDescriptionInput;
};


export type MutationUpdateConceptFormatsArgs = {
  input: UpdateConceptFormatsInput;
};


export type MutationUpdateConceptNotesArgs = {
  input: UpdateConceptNotesInput;
};


export type MutationUpdateConceptVariationFormatsArgs = {
  input: UpdateConceptVariationFormatsInput;
};


export type MutationUpdateCreativeXBrandArgs = {
  input: UpdateCreativeXBrandInput;
};


export type MutationUpdateCreativeXIntegrationArgs = {
  input: UpdateCreativeXIntegrationInput;
};


export type MutationUpdateCsReviewNotesArgs = {
  input: UpdateCsReviewNotesInput;
};


export type MutationUpdateDeliverableArgs = {
  input: UpdateDeliverableInput;
};


export type MutationUpdateDeliveryTurnaroundArgs = {
  input: UpdateDeliveryTurnaroundInput;
};


export type MutationUpdateLegacyUserArgs = {
  input: UpdateLegacyUserInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationUpdateOrderDeliverablesArgs = {
  input: UpdateOrderDeliverablesInput;
};


export type MutationUpdateTranslationsArgs = {
  input: UpdateTranslationsInput;
};


export type MutationUpdateTrelloArgs = {
  input: UpdateTrelloInput;
};


export type MutationUpdateTrelloLabelsArgs = {
  input: UpdateTrelloLabelsInput;
};


export type MutationUpdateUgcBriefArgs = {
  input: UpdateUgcBriefInput;
};


export type MutationUpdateUgcDeliverableArgs = {
  input: UpdateUgcDeliverableInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateVariationBriefArgs = {
  input: UpdateVariationBriefInput;
};


export type MutationUpdateVariationDescriptionArgs = {
  input: UpdateVariationDescriptionInput;
};


export type MutationUpdateVariationFormatsArgs = {
  input: UpdateVariationFormatsInput;
};

/** No HubSpotDeal was selected using the no deal assigned option. */
export type NoHubspotDeal = {
  __typename?: 'NoHubspotDeal';
  /** No-op field. */
  noop: Noop;
};

/**
 * Enum used to indicate this is a no-op field.
 * No-op fields are used to avoid objects with no fields, which is unsupported in GraphQL.
 */
export enum Noop {
  Noop = 'NOOP'
}

export type Notification = {
  __typename?: 'Notification';
  acknowledged: Scalars['Boolean'];
  id: Scalars['ID'];
  type: NotificationType;
};

export enum NotificationType {
  RevisionsCompleted = 'RevisionsCompleted',
  Unknown = 'Unknown'
}

/** The campaign objective of an order or advert. */
export enum Objective {
  BrandAwareness = 'brand_awareness',
  Consideration = 'consideration',
  DirectResponse = 'direct_response',
  FullFunnel = 'full_funnel',
  Unknown = 'unknown'
}

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  pageCount: Scalars['Int'];
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
  recordCount: Scalars['Int'];
};

export type OffsetPagination = {
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
};

/** An order. */
export type Order = {
  __typename?: 'Order';
  /** The sum of active hero deliverables for all adverts. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** Flags whether the project is an active project or not. */
  activeProject?: Maybe<Scalars['Boolean']>;
  /**
   * Adverts belonging to the Order.
   *
   * Permitted search attributes: ["id", "advert_id", "integration_key",
   * "order_id", "original_input_type_name",
   * "adverts_deliverable_formats_length_name"]
   *
   * Permitted sort attributes: ["position"]
   */
  adverts: Adverts;
  /** Answers submitted to the Order. */
  answers?: Maybe<Answers>;
  /** The art director associated with the Order. */
  artDirector?: Maybe<LegacyUser>;
  /**
   * Assets attached to the Order
   *
   * Permitted search attributes: ["id", "name", "asset_type"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  assets: Assets;
  /** The web location of the zipped assets. */
  assetsZipUrl?: Maybe<Scalars['String']>;
  /** The UTC timestamp for when the Brief was approved, as an Integer. */
  briefApprovedAt?: Maybe<Scalars['BigInt']>;
  /**
   * Campaign questions for the Order.
   *
   * Permitted search attributes: ["id", "question_option_id"]
   *
   * Permitted sort attributes: ["created_at", "position"]
   */
  campaignQuestions: Array<Question>;
  /** Checks if deliverables can be added to the project. */
  canAddDeliverables?: Maybe<Scalars['Boolean']>;
  /** Checks if the order can be completed. */
  canBeCompleted?: Maybe<Scalars['Boolean']>;
  /** Checks if the project can be approved. */
  canBeConfirmed?: Maybe<Scalars['Boolean']>;
  /** Checks if the order can be continued. */
  canBeContinued?: Maybe<Scalars['Boolean']>;
  /** Checks if the order can be deleted. */
  canBeDeleted?: Maybe<Scalars['Boolean']>;
  /** Checks by the projects effective state if it has been delivered internally. */
  canBeDelivered?: Maybe<Scalars['Boolean']>;
  /** Checks if the order can be duplicated matching the order structure without matching most fields. */
  canBeDuplicatedOrder?: Maybe<Scalars['Boolean']>;
  /** Checks if the project can be duplicated matching majority of its fields. */
  canBeDuplicatedProject?: Maybe<Scalars['Boolean']>;
  /** Checks if the orders brief can be modified. */
  canEditBrief?: Maybe<Scalars['Boolean']>;
  /** Checks if the orders brief-formats can be modified. */
  canEditBriefFormats?: Maybe<Scalars['Boolean']>;
  /** Checks if the revision limit of the project can be adjusted */
  canHaveRevisionLimitAdjusted?: Maybe<Scalars['Boolean']>;
  /** Checks if it's possible to attempt submitting the orders brief. */
  canSubmitBrief?: Maybe<Scalars['Boolean']>;
  /** Checks if deliverables can be updated in the project. */
  canUpdateDeliverables?: Maybe<Scalars['Boolean']>;
  /** Checks if the delivery turnaround time can be adjusted. */
  canUpdateDeliveryTurnaround: Scalars['Boolean'];
  /** Checks if the orders deliverables can be viewed. */
  canViewDeliverables?: Maybe<Scalars['Boolean']>;
  /** Checks if the orders production brief can be accessed. */
  canViewProductionBrief?: Maybe<Scalars['Boolean']>;
  /** Checks if the orders summary brief can be accessed. */
  canViewSummaryBrief?: Maybe<Scalars['Boolean']>;
  /** The Client associated with the Order. */
  client: ClientBrand;
  /** The sequence number of an order for a specific Client */
  clientSequenceNumber: Scalars['Int'];
  /** The web location of the zipped combined project files. */
  combinedZipUrl?: Maybe<Scalars['String']>;
  /** The UTC timestamp for the Order's completion, as an Integer. */
  completedAt?: Maybe<Scalars['BigInt']>;
  /** The copilot status of the Order. */
  copilotStatus?: Maybe<CopilotStatus>;
  /** The UTC timestamp for the Order's creation, as an Integer. */
  createdAt: Scalars['BigInt'];
  /** The current Workflow Step of the Order. */
  currentStep?: Maybe<WorkflowStep>;
  /** AWS credentials for deliverables */
  deliverableAwsCredentials: Scalars['Credential'];
  /**
   * Deliverable formats associated with the Order
   *
   * Permitted search attributes: ["id", "integration_key", "filter_name", "length_name", "name", "platform_name"]
   *
   * Permitted sort attributes: ["created_at", "name", "position"]
   */
  deliverableFormats: DeliverableFormats;
  /** The platforms associated to the deliverable formats of this order. */
  deliverablePlatforms?: Maybe<Array<Platform>>;
  /** Deliverables attached to the Order. */
  deliverables: LegacyDeliverables;
  /** The web location of the zipped deliverables. */
  deliverablesZipUrl?: Maybe<Scalars['String']>;
  /**
   * The UTC timestamp for the Order delivery due date, as an Integer.
   * @deprecated Use delivery_turnaround instead.
   */
  deliveryDueAt?: Maybe<Scalars['BigInt']>;
  /** The project's delivery turnaround times. */
  deliveryTurnaround?: Maybe<DeliveryTurnaround>;
  /** The UTC timestamp for the Order's archived time, as an Integer. */
  discardedAt?: Maybe<Scalars['BigInt']>;
  /** The effective state of the Order, varied by the current user's role. */
  effectiveState: OrderState;
  /** Link to the google drive folder associated with the Order. */
  googleDriveUrl?: Maybe<Scalars['String']>;
  /** The hubspot deal id added by internal users when creating a new order. */
  hubspotId?: Maybe<Scalars['BigInt']>;
  /** The UUID of the Order. */
  id: Scalars['ID'];
  /** A surrogate UUID used to identify an order when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** A flag indicating whether this project qualifies for express delivery. */
  isExpress: Scalars['Boolean'];
  /** A flag indicating whether the Order is in its final workflow step. */
  isFinalStep: Scalars['Boolean'];
  /** Flags if the client is of license account type. */
  license: Scalars['Boolean'];
  /** Flags if the client is of license plus account type. */
  licensePlus: Scalars['Boolean'];
  /** The maximum number of revisions that can be submitted for the Order. */
  maxNumRevisions?: Maybe<Scalars['Int']>;
  /** The user-specified name for the Order. */
  name?: Maybe<Scalars['String']>;
  /** The next step in the order's workflow step chain. */
  nextStep?: Maybe<WorkflowStep>;
  /** The current number of revisions that have been submitted for the Order. */
  numRevisions: Scalars['Int'];
  /** The campaign objective of the order. */
  objective: Objective;
  /** The production notes of the Order. */
  productionNotes?: Maybe<Scalars['String']>;
  /** The region on the Order's Client. */
  region: Region;
  /** The UTC timestamp when the Order's revision period will end and it will automatically be approved, as an Integer. */
  revisionEndTime?: Maybe<Scalars['BigInt']>;
  /** Workflow steps which will be used for the order. */
  selectedSteps: Array<WorkflowStep>;
  /** A numeric ID unique across all Orders. */
  serialId: Scalars['String'];
  /** The web slug for the order, containing the Client name. */
  slug: Scalars['String'];
  /** The current true state of the Order. */
  state: OrderState;
  /** The submission state of the Order. */
  submissionState: SubmissionState;
  /** The UTC timestamp for the Order's submission, as an Integer. */
  submittedAt?: Maybe<Scalars['BigInt']>;
  /** Flags if the client is of subscription account type. */
  subscription: Scalars['Boolean'];
  /** A UTC timestamp for the Order's target date. */
  targetDate?: Maybe<Scalars['BigInt']>;
  /** The sum total of deliverables ordered for all adverts. */
  totalDeliverablesOrdered?: Maybe<Scalars['Int']>;
  /** The sum total of specifications ordered for all adverts. */
  totalSpecificationsOrdered?: Maybe<Scalars['Int']>;
  /** The orders Trello board based on its region. */
  trelloBoard: TrelloBoard;
  /** Link to the trello card associated with the Order. */
  trelloCardUrl?: Maybe<Scalars['String']>;
  /** The most relevant URL for the order given the current state. */
  urlForState: Scalars['String'];
  /** For draft orders, the User who created it. After submission this User is updated to the submitter. */
  user: LegacyUser;
  /** The briefing flow version used to create this order. */
  version: Scalars['Int'];
  /** Working Files attached to the Order */
  workingFiles: WorkingFiles;
  /** The web location of the zipped working files. */
  workingFilesZipUrl?: Maybe<Scalars['String']>;
};


/** An order. */
export type OrderAdvertsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An order. */
export type OrderAnswersArgs = {
  actual?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


/** An order. */
export type OrderAssetsArgs = {
  assetType?: InputMaybe<AssetType>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An order. */
export type OrderCampaignQuestionsArgs = {
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An order. */
export type OrderDeliverableFormatsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An order. */
export type OrderDeliverablesArgs = {
  fileType?: InputMaybe<DeliverableFileType>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DeliverableState>;
};


/** An order. */
export type OrderWorkingFilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** A container for arguments used when completing an order. */
export type OrderCompleteInput = {
  /** UUID of the target order. */
  id: Scalars['ID'];
};

/** A container for arguments used when creating an order. */
export type OrderCreateInput = {
  /** Integration key of the Brand/EnterpriseBrand for the order to be associated with. */
  brandIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** Indicates that this projects brief is being ai generated. */
  generatingBrief?: InputMaybe<Scalars['Boolean']>;
  /** The hubspot deal id. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /** Indicates that this order should be created with a default concept. */
  withDefaultConcept?: InputMaybe<Scalars['Boolean']>;
};

/** A container for arguments used when updating the deliverables of an order. */
export type OrderDeliverablesUpdateInput = {
  /** Details of the deliverables to be updated. */
  deliverablesAttributes?: InputMaybe<Array<DeliverableInput>>;
  /** Integration key of the target order. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** Storage API integration keys for working files. */
  workingFileKeys?: InputMaybe<Array<Scalars['ID']>>;
};

/** A container for arguments used when discarding an order. */
export type OrderDiscardInput = {
  /**
   * UUID of the target order.
   * @deprecated Use `integration_key` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the target project. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when duplicating an order. */
export type OrderDuplicateInput = {
  /** Integration key of the brand to duplicate to. */
  brandIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** The Hubspot ID of the project. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /** Integration key of the target project. */
  integrationKey: Scalars['ID'];
  /** The name of the project. */
  name?: InputMaybe<Scalars['String']>;
};

/** A container for arguments used when revising a project */
export type OrderReviseInput = {
  /** The UUID of the project to be revised. */
  id: Scalars['ID'];
  /** The priority level of a revision round. */
  priority?: InputMaybe<ProductionPriority>;
};

/** An order state. */
export enum OrderState {
  AssetProduction = 'asset_production',
  ClientReview = 'client_review',
  ClientRevision = 'client_revision',
  CsReview = 'cs_review',
  DraftBrief = 'draft_brief',
  FinishingStep = 'finishing_step',
  InProgress = 'in_progress',
  InternalReview = 'internal_review',
  InternalRevision = 'internal_revision',
  ProductionReview = 'production_review',
  ProductionSetup = 'production_setup',
  ProjectComplete = 'project_complete',
  UgcIdeation = 'ugc_ideation'
}

/** A container for arguments used when submitting an order. */
export type OrderSubmitInput = {
  /**
   * UUID of the target order.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when setting a projects target date */
export type OrderTargetDateInput = {
  /** UUID of the target order. */
  id: Scalars['ID'];
  /** The target date of a project. */
  targetDate?: InputMaybe<Scalars['ISO8601Date']>;
};

/** A container for arguments used when updating an order. */
export type OrderUpdateInput = {
  /** UUID of the art director assigned to the order. */
  artDirectorId?: InputMaybe<Scalars['ID']>;
  /**
   * UUID of the target order.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the target order. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** The maximum number of revision requests allowed for the order. */
  maxNumRevisions?: InputMaybe<Scalars['Int']>;
  /** The name of the order. */
  name?: InputMaybe<Scalars['String']>;
  /** The production notes of the order. */
  productionNotes?: InputMaybe<EncodedString>;
};

/** A paginated array of Orders */
export type Orders = {
  __typename?: 'Orders';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Orders */
  nodes: Array<Order>;
};

export type OrganizationalUnit = {
  __typename?: 'OrganizationalUnit';
  canCreateInvitations: Scalars['Boolean'];
  canRemoveUsers: Scalars['Boolean'];
  key: Scalars['ID'];
};

/** The input type as determined by the file types of order assets. */
export enum OriginalInputType {
  ImagesOnly = 'images_only',
  Unknown = 'unknown',
  VideoAndImages = 'video_and_images',
  VideoOnly = 'video_only'
}

export enum OverlayType {
  Placement = 'Placement',
  Safezone = 'Safezone'
}

export enum PendingBriefAction {
  /** There is currently no action to be taken on this brief. */
  None = 'NONE',
  /** The brief is waiting to be submitted for asset production by a UGC creator. */
  SubmitForAssetProduction = 'SUBMIT_FOR_ASSET_PRODUCTION',
  /** The brief is waiting to be submitted for review by a Creative Specialist. */
  SubmitForCsReview = 'SUBMIT_FOR_CS_REVIEW',
  /** The brief is waiting to be submitted for review by the production team. */
  SubmitForProductionReview = 'SUBMIT_FOR_PRODUCTION_REVIEW',
  /** The brief is waiting to be submitted for UGC ideation. */
  SubmitForUgcIdeation = 'SUBMIT_FOR_UGC_IDEATION',
  /** NOT A REAL ACTION, DO NOT USE. */
  Unspecified = 'UNSPECIFIED'
}

/** Whitelisted attributes available to current user */
export type PermittedAttributes = {
  permittedAttributes: Scalars['JSON'];
};

/** A Platform */
export type Platform = {
  __typename?: 'Platform';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
};

export type PlatformInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export enum PreflightStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export enum Priority {
  QuickfixTier_1 = 'QUICKFIX_TIER_1',
  QuickfixTier_2 = 'QUICKFIX_TIER_2',
  Regular = 'REGULAR'
}

export enum ProductionAction {
  MoveToNextStage = 'MOVE_TO_NEXT_STAGE'
}

/** A container for arguments used when creating a production delivery turnaround for a project. */
export type ProductionDeliveryTurnaroundCreateInput = {
  /** Integration Key of the production stage to which the new production delivery turnaround will be assigned. */
  productionStageIntegrationKey: Scalars['ID'];
  /** Integration Key of the project to which the new production delivery turnaround will be assigned. */
  projectIntegrationKey: Scalars['ID'];
};

/** The priority type for the production of deliverables. */
export enum ProductionPriority {
  QuickfixTier_1 = 'QUICKFIX_TIER_1',
  QuickfixTier_2 = 'QUICKFIX_TIER_2',
  Regular = 'REGULAR'
}

export type ProductionStage = {
  __typename?: 'ProductionStage';
  key: Scalars['ID'];
  status: ProductionStageStatus;
  type: ProductionStageType;
};

/** Autogenerated input type of ProductionStageStarted */
export type ProductionStageStartedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Details of the ProductionStageStarted  */
  input: StartedProductionStageInput;
};

/** Autogenerated return type of ProductionStageStarted. */
export type ProductionStageStartedPayload = {
  __typename?: 'ProductionStageStartedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The result of the mutation. */
  result: Scalars['Boolean'];
};

export enum ProductionStageStatus {
  Approved = 'APPROVED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Stopped = 'STOPPED'
}

/** The Stage type of the production stage. */
export enum ProductionStageType {
  AdditionalFormats = 'ADDITIONAL_FORMATS',
  Express = 'EXPRESS',
  Hero = 'HERO',
  PostBriefFormats = 'POST_BRIEF_FORMATS',
  Variations = 'VARIATIONS'
}

/** A Project. */
export type Project = {
  __typename?: 'Project';
  activeDeliverables: Array<RevisionDeliverable>;
  canSetDeal: Scalars['Boolean'];
  canUnapproveDeliverables: Scalars['Boolean'];
  clientAccount: ClientAccount;
  clientBrand: ClientBrand;
  collaborators?: Maybe<Array<Collaborator>>;
  concepts: Concepts;
  creativeXEnabled: Scalars['Boolean'];
  /** The effective state of the Order, varied by the current user's role. */
  effectiveState: OrderState;
  /**  The generative AI preferences currently applicable for this project.  */
  generativeAIPreferences: Array<GenerativeAiPreference>;
  /**
   * The HubSpot deal associated with this project. This will be null until a deal or the no deal assigned option is
   * selected.
   *
   * Requires authorization and will be null without the correct permissions.
   */
  hubspotDeal?: Maybe<HubspotDealSelection>;
  id: Scalars['ID'];
  /** @deprecated Use 'key' instead */
  integrationKey: Scalars['ID'];
  /** A flag indicating whether the Order is in its final workflow step. */
  isFinalStep: Scalars['Boolean'];
  /** The project key. */
  key: Scalars['ID'];
  /**
   * The legacy order containing legacy fields
   * @deprecated Use Project instead. Fields to be moved to Project.
   */
  legacyOrder?: Maybe<Order>;
  name: Scalars['String'];
  numActiveDeliverables: Scalars['Int'];
  proposeProjectTransition?: Maybe<ProposedTransitionUsage>;
  revisionConcepts: Array<RevisionConcept>;
  /** @deprecated Use concepts instead. */
  specificationConcepts: Array<SpecificationConcept>;
  /** The current true state of the Order. */
  state: OrderState;
  status: ProjectStatus;
  usage: Array<AccountUsage>;
};


/** A Project. */
export type ProjectCollaboratorsArgs = {
  types?: InputMaybe<Array<CollaboratorType>>;
};


/** A Project. */
export type ProjectConceptsArgs = {
  params?: InputMaybe<ConceptsParams>;
};


/** A Project. */
export type ProjectProposeProjectTransitionArgs = {
  action: ProductionAction;
};

export type ProjectAccessSettings = {
  __typename?: 'ProjectAccessSettings';
  id: Scalars['ID'];
  integrationKey: Scalars['ID'];
  visibility: ProjectVisibility;
};

export type ProjectBrief = {
  __typename?: 'ProjectBrief';
  /** The concepts that make up this brief. */
  concepts: Array<Concept>;
  /** The notes for the CS review. */
  csReviewNote?: Maybe<Scalars['String']>;
  /** HACK: Apollo Client cache key */
  id: Scalars['ID'];
  /** The next action that should be taken on this brief. */
  pendingAction: PendingBriefAction;
  /** The integration key of the project this brief belongs to. */
  projectIntegrationKey: Scalars['ID'];
};

/** A boolean determining if a project brief is valid or not. */
export type ProjectBriefValidation = {
  __typename?: 'ProjectBriefValidation';
  integrationKey: Scalars['ID'];
  isValid: Scalars['Boolean'];
};

/** An answer to a CES question. */
export type ProjectCesAnswerInput = {
  /** CES comment for the question. */
  comment?: InputMaybe<Scalars['String']>;
  /** CES question text the user was asked. */
  question: Scalars['String'];
  /** CES rating for the question. */
  rating: CesRating;
};

export type ProjectCesAnswersInput = {
  creativeQuality: ProjectCesAnswerInput;
  deliveryTime: ProjectCesAnswerInput;
  gettingStarted: ProjectCesAnswerInput;
  platform: ProjectCesAnswerInput;
  supportAndServices: ProjectCesAnswerInput;
};

/** Project CES mutation input. */
export type ProjectCesInput = {
  /** CES answers for the given project. */
  answers?: InputMaybe<ProjectCesAnswersInput>;
  /** Client integration key. */
  clientIntegrationKey: Scalars['ID'];
  /** Client name. */
  clientName: Scalars['String'];
  /**
   * CES comment for the given project.
   * @deprecated Use `answers` instead.
   */
  comment?: InputMaybe<Scalars['String']>;
  /** Client Partner email. */
  cpEmail?: InputMaybe<Scalars['String']>;
  /** Customer Success Manager email. */
  csmEmail?: InputMaybe<Scalars['String']>;
  /** Hubspot Company ID. */
  hubspotCompanyId?: InputMaybe<Scalars['Uint64']>;
  /** Hubspot Subscription ID. */
  hubspotSubscriptionId?: InputMaybe<Scalars['Uint64']>;
  /** Project integration key. */
  projectIntegrationKey: Scalars['ID'];
  /** Project name. */
  projectName: Scalars['String'];
  /**
   * CES rating for the given project.
   * @deprecated Use `answers` instead.
   */
  rating?: InputMaybe<CesRating>;
  /** Contact email for who submitted the project. */
  submittedByEmail: Scalars['String'];
  /** Name of who submitted the project. */
  submittedByName: Scalars['String'];
};

/** Project CSAT mutation input. */
export type ProjectCsatInput = {
  /** Client integration key. */
  clientIntegrationKey: Scalars['ID'];
  /** Client name. */
  clientName: Scalars['String'];
  /** CSAT comment for the given project. */
  comment?: InputMaybe<Scalars['String']>;
  /** Client Partner email. */
  cpEmail?: InputMaybe<Scalars['String']>;
  /** Customer Success Manager email. */
  csmEmail?: InputMaybe<Scalars['String']>;
  /** Hubspot Company ID. */
  hubspotCompanyId?: InputMaybe<Scalars['Uint64']>;
  /** Hubspot Subscription ID. */
  hubspotSubscriptionId?: InputMaybe<Scalars['Uint64']>;
  /** Project integration key. */
  projectIntegrationKey: Scalars['ID'];
  /** Project name. */
  projectName: Scalars['String'];
  /** CSAT rating for the given project. */
  rating: CsatRating;
  /** CSAT reason for the given rating. */
  reason?: InputMaybe<Scalars['String']>;
  /** Reviewer contact email. */
  reviewerEmail: Scalars['String'];
  /** Reviewer name. */
  reviewerName: Scalars['String'];
};

/** A container for arguments used when duplicating a project. */
export type ProjectDuplicateInput = {
  /** The Hubspot ID of the project. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /** Integration key of the target project. */
  integrationKey: Scalars['ID'];
  /** The name of the project. */
  name?: InputMaybe<Scalars['String']>;
};

export enum ProjectPermission {
  /** Access to approve briefs */
  ProjectPermissionApproveBrief = 'PROJECT_PERMISSION_APPROVE_BRIEF',
  ProjectPermissionEditVisibility = 'PROJECT_PERMISSION_EDIT_VISIBILITY',
  ProjectPermissionModifyPostBriefFormats = 'PROJECT_PERMISSION_MODIFY_POST_BRIEF_FORMATS',
  ProjectPermissionPerformAdministrativeFunctions = 'PROJECT_PERMISSION_PERFORM_ADMINISTRATIVE_FUNCTIONS',
  ProjectPermissionRead = 'PROJECT_PERMISSION_READ',
  ProjectPermissionReadDeliverable = 'PROJECT_PERMISSION_READ_DELIVERABLE',
  ProjectPermissionReadDeliverableInternal = 'PROJECT_PERMISSION_READ_DELIVERABLE_INTERNAL',
  ProjectPermissionReadRenderables = 'PROJECT_PERMISSION_READ_RENDERABLES',
  ProjectPermissionRenderInstantCopyChange = 'PROJECT_PERMISSION_RENDER_INSTANT_COPY_CHANGE',
  ProjectPermissionRenderTemplate = 'PROJECT_PERMISSION_RENDER_TEMPLATE',
  /** Access to approve or un-approve deliverables */
  ProjectPermissionReviewDeliverable = 'PROJECT_PERMISSION_REVIEW_DELIVERABLE',
  /** Access to approve or reject deliverables revision comments */
  ProjectPermissionReviewRevision = 'PROJECT_PERMISSION_REVIEW_REVISION',
  /** Access to approve or reject deliverables internal revision comments */
  ProjectPermissionReviewRevisionInternal = 'PROJECT_PERMISSION_REVIEW_REVISION_INTERNAL',
  /** Access to submit briefs in cs review for asset production */
  ProjectPermissionSubmitBriefForAssetProduction = 'PROJECT_PERMISSION_SUBMIT_BRIEF_FOR_ASSET_PRODUCTION',
  /** Access to submit briefs for cs review */
  ProjectPermissionSubmitBriefForCsReview = 'PROJECT_PERMISSION_SUBMIT_BRIEF_FOR_CS_REVIEW',
  /** Access to submit briefs in cs review for production review */
  ProjectPermissionSubmitBriefForProductionReview = 'PROJECT_PERMISSION_SUBMIT_BRIEF_FOR_PRODUCTION_REVIEW',
  /** Access to submit briefs in draft brief for ugc ideation */
  ProjectPermissionSubmitBriefForUgcIdeation = 'PROJECT_PERMISSION_SUBMIT_BRIEF_FOR_UGC_IDEATION',
  /** NOT A REAL PERMISSION, DO NOT USE */
  ProjectPermissionUnspecified = 'PROJECT_PERMISSION_UNSPECIFIED',
  /** Access to create, update and delete concepts */
  ProjectPermissionWriteConcept = 'PROJECT_PERMISSION_WRITE_CONCEPT',
  /** Access to update internal fields for a concept, e.g. designer notes */
  ProjectPermissionWriteConceptInternal = 'PROJECT_PERMISSION_WRITE_CONCEPT_INTERNAL',
  /** Access to update CS review notes */
  ProjectPermissionWriteCsReviewNotes = 'PROJECT_PERMISSION_WRITE_CS_REVIEW_NOTES',
  /** Access to create, update and delete files */
  ProjectPermissionWriteFile = 'PROJECT_PERMISSION_WRITE_FILE',
  /** Access to create, update and delete revisions */
  ProjectPermissionWriteRevision = 'PROJECT_PERMISSION_WRITE_REVISION',
  /** Access to create, update and delete revision comments */
  ProjectPermissionWriteRevisionComment = 'PROJECT_PERMISSION_WRITE_REVISION_COMMENT'
}

/** Scopes for a given Project. */
export type ProjectScopes = {
  __typename?: 'ProjectScopes';
  canApprove: Scalars['Boolean'];
  canDestroy: Scalars['Boolean'];
  canDownloadProjectDeliverablesZip: Scalars['Boolean'];
  canModifyPostBriefFormats: Scalars['Boolean'];
  canReject: Scalars['Boolean'];
  canRevise: Scalars['Boolean'];
  canShow: Scalars['Boolean'];
  canSubmitFormats: Scalars['Boolean'];
  canUpdate: Scalars['Boolean'];
  /** The UUID of the Project. */
  id: Scalars['ID'];
};

export enum ProjectStatus {
  ClientReview = 'ClientReview',
  Complete = 'Complete',
  InProgress = 'InProgress',
  InternalReview = 'InternalReview'
}

export enum ProjectTransitionValidationFailure {
  MaxDeliverablesExceeded = 'MAX_DELIVERABLES_EXCEEDED',
  MaxHeroesExceeded = 'MAX_HEROES_EXCEEDED'
}

export enum ProjectVisibility {
  Private = 'Private',
  Public = 'Public'
}

export type Projects = {
  __typename?: 'Projects';
  meta: OffsetPageInfo;
  nodes: Array<Project>;
};

export type ProjectsFilter = {
  key?: InputMaybe<IdFilter>;
};

export type ProjectsParams = {
  filter?: InputMaybe<ProjectsFilter>;
  pagination?: InputMaybe<OffsetPagination>;
};

export type ProposedTransitionUsage = {
  __typename?: 'ProposedTransitionUsage';
  accountUsage: Array<AccountUsage>;
  validationFailures?: Maybe<Array<ProjectTransitionValidationFailure>>;
};

export type QualifiesForExpress = {
  __typename?: 'QualifiesForExpress';
  qualifies: Scalars['Boolean'];
};

export type QualityAssuranceReport = {
  __typename?: 'QualityAssuranceReport';
  id: Scalars['ID'];
  results?: Maybe<QualityAssuranceReportResults>;
  status: QualityAssuranceReportStatus;
};

export type QualityAssuranceReportCategory = {
  __typename?: 'QualityAssuranceReportCategory';
  category: ReportItemCategory;
  items: Array<QualityAssuranceReportItem>;
  score: ReportItemCategoryScore;
};

export type QualityAssuranceReportItem = {
  __typename?: 'QualityAssuranceReportItem';
  feedback?: Maybe<Scalars['String']>;
  key: Scalars['ID'];
  score: ReportItemScore;
  timestamp?: Maybe<Scalars['Float']>;
};

export enum QualityAssuranceReportResultState {
  Fail = 'FAIL',
  Pass = 'PASS',
  Review = 'REVIEW'
}

export type QualityAssuranceReportResults = {
  __typename?: 'QualityAssuranceReportResults';
  id: Scalars['ID'];
  reportTopics: Array<QualityAssuranceReportTopic>;
  resultState: QualityAssuranceReportResultState;
};

export enum QualityAssuranceReportStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type QualityAssuranceReportTopic = {
  __typename?: 'QualityAssuranceReportTopic';
  categories: Array<QualityAssuranceReportCategory>;
  topic: ReportItemTopic;
};

/** The query root of this schema */
export type Query = {
  __typename?: 'Query';
  /** Not implemented, this only exists due to limitations in the federation gem. */
  _concept: Concept;
  /** Not implemented, this only exists due to limitations in the federation gem. */
  _project: Project;
  /** Not implemented, this only exists due to limitations in the federation gem. */
  _ugcBrief: UgcBrief;
  /** Endpoint to check the Hero Balance for a given Account Key. */
  accountHeroBalance: AccountHeroBalance;
  adaptResultsForDeliverable: Array<AdaptResult>;
  /**
   * Get all available Adverts.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "advert_id", "integration_key",
   * "order_id", "original_input_type_name",
   * "adverts_deliverable_formats_length_name"]
   *
   * Permitted sort attributes: ["position"]
   */
  adverts: Adverts;
  /** Retrieve analytics dashboards for the current user. */
  analyticsDashboards: AnalyticsDashboards;
  /**
   * Get assets.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "name", "asset_type"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  assets: Assets;
  audience?: Maybe<Audience>;
  audienceDefaultForBrand?: Maybe<Audience>;
  audienceSubstitutions: Array<Maybe<AudienceSubstitution>>;
  audiences: Array<Audience>;
  /**
   * Get assumed role for a given auth0 integration key.
   *
   * Requires management authorization.
   */
  auth0AssumedRole: Scalars['String'];
  brandProfile: BrandProfile;
  /** Check whether the current user can update a brief's CS review notes. */
  canUpdateCsReviewNotes: Scalars['Boolean'];
  /**
   * Get information for a specific client.
   *
   * Requires authorization.
   */
  client: Client;
  /**  Get the client account for the current user.  */
  clientAccount: ClientAccount;
  /** Get the client account for the current user. */
  clientBrand: ClientBrand;
  /** Get the current users permissions for a given Client Brand. */
  clientBrandPermissions?: Maybe<Array<ClientBrandPermission>>;
  /**
   * Get all available orders.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "account_type_name", "hubspot_id", "name",
   * "region_name", "account_type", "ancestry", "enterprise_ancestry",
   * "region_ancestry", "group_ancestry", "brands", "enterprises", "groups",
   * "region_groups", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "name", "region_name"]
   */
  clients: Clients;
  /**
   * Get information for a specific Concept.
   *
   * The integrationKey parameter is deprecated. Use the key parameter instead.
   */
  concept: Concept;
  concepts: Concepts;
  /**
   * Get copy suggestions for the given inputs.
   *
   * Requires authorization.
   */
  copySuggestions: Array<CopySuggestion>;
  /** Retrieve an individual creator by its Key. */
  creator: Creator;
  creatorAttributes: CreatorAttributes;
  creatorBrief?: Maybe<CreatorBrief>;
  creatorOffer: CreatorOffer;
  /** Search creators. */
  creators: CreatorSearchResult;
  currentStage?: Maybe<ProductionStage>;
  deliverable: Deliverable;
  /** @deprecated use federated Deliverable type instead */
  deliverableForSpecificationKey: RevisionDeliverable;
  /** Get a list of all deliverable format filter names. */
  deliverableFormatFilterNames: Array<Scalars['String']>;
  /**
   * Get a list of all deliverable formats.
   *
   * Permitted search attributes: ["id", "integration_key", "filter_name", "length_name", "name", "platform_name"]
   *
   * Permitted sort attributes: ["created_at", "name", "position"]
   */
  deliverableFormats: DeliverableFormats;
  /**
   * Get all the deliverables for the order deliverables page.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "duration_filter", "order_client_name",
   * "order_client_vertical_name", "order_hubspot_id", "order_name",
   * "order_region_name", "order_serial_id", "copy_variation_number",
   * "deliverable_format_id", "deliverable_format_name",
   * "deliverable_format_platform_name", "hero", "language_code", "order_best_of",
   * "order_id", "pending_review_or_approved", "hero_or_copy_variation_filter",
   * "advert_position", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "order_best_of", "order_created_at", "order_completed_at"]
   */
  deliverables: LegacyDeliverables;
  deliverablesRevisionPoints: DeliverablesRevisionPoints;
  deliverablesRevisionsPriority: Priority;
  /** Get all error codes. */
  errorCodes: Array<ErrorCode>;
  /** Endpoint to get a file by its key */
  file: File;
  files: Array<File>;
  generativeAIOptions: Array<GenerativeAiModelOptions>;
  /** Has a CSAT rating for a project. */
  hasProjectCsat: Scalars['Boolean'];
  /** Endpoint to check API health. */
  healthz: Scalars['Boolean'];
  /** Retrieve an invitation by its key. */
  invitation: Invitation;
  /** Search for invitations. */
  invitations: Invitations;
  /** Get a specific async job */
  job: AsyncJob;
  /** A constrained list of languages supported for use in creative */
  languages: Array<Language>;
  /** Get a specific invitation by id. */
  legacyInvitation?: Maybe<LegacyInvitation>;
  /**
   * Get information for a specific User.
   *
   * Requires authorization.
   */
  legacyUser?: Maybe<LegacyUser>;
  /**
   * Get all available Users.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   *
   * Permitted sort attributes: ["created_at", "clients_id"]
   */
  legacyUsers?: Maybe<LegacyUsers>;
  /** List all available markets */
  markets: Array<Market>;
  me?: Maybe<Me>;
  nextStage?: Maybe<ProductionStage>;
  /** No-op field to avoid empty Query object. */
  noop: Noop;
  /**
   * Get information for a specific order.
   *
   * Requires authorization.
   */
  order: Order;
  /**
   * Get all available orders.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id",
   * "adverts_deliverable_formats_platform_name", "adverts_objective_name",
   * "adverts_original_input_type_name",
   * "approved_deliverables_deliverable_format_filter_name",
   * "approved_deliverables_deliverable_format_platform_name",
   * "approved_deliverables_duration_filter", "client_name",
   * "client_vertical_name", "hubspot_id", "name", "objective_name", "region_name",
   * "serial_id", "state_name", "best_of", "by_effective_state", "client_ancestry",
   * "client_id", "completed_at", "enterprise_ancestry", "full_search_cont",
   * "group_ancestry", "region_ancestry", "state", "submitted_at", "type",
   * "user_id", "eligible_to_create_variations", "client_ancestry_or_client_id",
   * "client_client_partners_id", "client_creative_specialists_id",
   * "client_production_managers_id"]
   *
   * Permitted sort attributes: ["created_at", "active_states", "best_of",
   * "brand_name", "client_name", "completed_at", "created_at", "enterprise_name",
   * "name", "state", "submitted_at", "target_date", "target_date_nulls",
   * "total_deliverables_ordered"]
   */
  orders: Orders;
  /**
   * Get a list of all platforms.
   *
   * Permitted search attributes: ["id", "name"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  platforms: Array<Platform>;
  /**
   * Get information for a specific Project.
   *
   * Requires authorization.
   *
   * The integrationKey parameter is deprecated. Use the key parameter instead.
   */
  project: Project;
  /**
   * Get access settings for a given Project.
   *
   * Requires authorization.
   */
  projectAccessSettings?: Maybe<ProjectAccessSettings>;
  /** Retrieve the brief for a given project integration key. */
  projectBrief?: Maybe<ProjectBrief>;
  /**
   * Check if a project brief is valid for submission.
   *
   * Requires authorization.
   */
  projectBriefValidation: ProjectBriefValidation;
  /** Get the current users permissions for a given Project. */
  projectPermissions?: Maybe<Array<ProjectPermission>>;
  /** Get a list of Projects. */
  projects: Projects;
  qualifiesForExpress: QualifiesForExpress;
  /**
   * Get all available Questions.
   *
   * Requires authorization.
   *
   * Permitted sort attributes: ["created_at", "position"]
   */
  questions: Array<Question>;
  /** Get a list of available regions */
  regions: Array<Region>;
  revision: Revision;
  /** @deprecated use federated Deliverable type instead */
  revisionDeliverable: RevisionDeliverable;
  revisionProject: Project;
  /** Get scopes for the requesting user */
  scopes: Scopes;
  specificationConcept: SpecificationConcept;
  /**
   * Get a list of available studios
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "name"]
   *
   * Permitted sort attributes: ["created_at", "name"]
   */
  studios: Studios;
  /** Retrieve a ugcOrder by its Key. */
  ugcOrder: UgcOrder;
  /** Retrieve a ugcOrder by a Concept Key. */
  ugcOrderByConceptKey: UgcOrder;
  /** Retrieve a user by their key. */
  user: User;
  /** Search for users. */
  users: Users;
  /**
   * Check whether the brief is ready to be submitted for CS review.
   * @deprecated Use Concept.canSubmitForCsReview instead.
   */
  validateBriefForCsReview: Scalars['Boolean'];
  variation: Variation;
  /**
   * Get verticals
   *
   * Permitted search attributes: ["id", "name"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  verticals: Verticals;
};


/** The query root of this schema */
export type Query_ConceptArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type Query_ProjectArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type Query_UgcBriefArgs = {
  conceptKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAccountHeroBalanceArgs = {
  clientAccountIntegrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAdaptResultsForDeliverableArgs = {
  input: AdaptResultsForDeliverableInput;
};


/** The query root of this schema */
export type QueryAdvertsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryAssetsArgs = {
  assetType?: InputMaybe<AssetType>;
  clientId?: InputMaybe<Scalars['ID']>;
  clientIntegrationKey?: InputMaybe<Scalars['ID']>;
  integrationKeys?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['ID']>;
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryAudienceArgs = {
  asOf?: InputMaybe<Scalars['Time']>;
  integrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAudienceDefaultForBrandArgs = {
  brandIntegrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAudienceSubstitutionsArgs = {
  brandIntegrationKey: Scalars['ID'];
  integrationKeys?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


/** The query root of this schema */
export type QueryAudiencesArgs = {
  asOf?: InputMaybe<Scalars['Time']>;
  brandIntegrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAuth0AssumedRoleArgs = {
  auth0IntegrationKey: Scalars['String'];
};


/** The query root of this schema */
export type QueryBrandProfileArgs = {
  asOf?: InputMaybe<Scalars['Time']>;
  brandIntegrationKey?: InputMaybe<Scalars['ID']>;
  integrationKey?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryCanUpdateCsReviewNotesArgs = {
  projectIntegrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryClientArgs = {
  id?: InputMaybe<Scalars['ID']>;
  integrationKey?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryClientAccountArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryClientBrandArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryClientBrandPermissionsArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryClientsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryConceptArgs = {
  integrationKey?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryConceptsArgs = {
  params?: InputMaybe<ConceptsParams>;
  projectIntegrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCopySuggestionsArgs = {
  audienceIntegrationKey?: InputMaybe<Scalars['ID']>;
  conceptIdea: Scalars['String'];
  description: Scalars['String'];
  responses?: InputMaybe<Array<Scalars['String']>>;
};


/** The query root of this schema */
export type QueryCreatorArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCreatorBriefArgs = {
  ugcOrderKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCreatorOfferArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCreatorsArgs = {
  params?: InputMaybe<CreatorSearchParams>;
};


/** The query root of this schema */
export type QueryCurrentStageArgs = {
  projectKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryDeliverableArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryDeliverableForSpecificationKeyArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryDeliverableFormatFilterNamesArgs = {
  orderId?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryDeliverableFormatsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryDeliverablesArgs = {
  dimensionId?: InputMaybe<Scalars['ID']>;
  fileType?: InputMaybe<DeliverableFileType>;
  limit?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['ID']>;
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  review?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<SortInput>>;
  state?: InputMaybe<DeliverableState>;
};


/** The query root of this schema */
export type QueryDeliverablesRevisionPointsArgs = {
  keys: Array<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryDeliverablesRevisionsPriorityArgs = {
  keys: Array<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryFileArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryFilesArgs = {
  keys: Array<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryHasProjectCsatArgs = {
  integrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryInvitationArgs = {
  key: Scalars['String'];
};


/** The query root of this schema */
export type QueryInvitationsArgs = {
  params?: InputMaybe<InvitationsParams>;
};


/** The query root of this schema */
export type QueryJobArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryLegacyInvitationArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryLegacyUserArgs = {
  auth0IntegrationKey?: InputMaybe<Scalars['String']>;
};


/** The query root of this schema */
export type QueryLegacyUsersArgs = {
  clientId?: InputMaybe<Scalars['ID']>;
  clientIntegrationKey?: InputMaybe<Scalars['ID']>;
  integrationKeys?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryNextStageArgs = {
  projectKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  integrationKey?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryOrdersArgs = {
  clientKey?: InputMaybe<Scalars['ID']>;
  effectiveState?: InputMaybe<OrderState>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  region?: InputMaybe<Scalars['String']>;
  showcase?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<SortInput>>;
  state?: InputMaybe<OrderState>;
};


/** The query root of this schema */
export type QueryPlatformsArgs = {
  clientId?: InputMaybe<Scalars['ID']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryProjectArgs = {
  integrationKey?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryProjectAccessSettingsArgs = {
  project_key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryProjectBriefArgs = {
  projectIntegrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryProjectBriefValidationArgs = {
  integrationKey?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryProjectPermissionsArgs = {
  integrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryProjectsArgs = {
  params?: InputMaybe<ProjectsParams>;
};


/** The query root of this schema */
export type QueryQualifiesForExpressArgs = {
  projectKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryQuestionsArgs = {
  category?: InputMaybe<QuestionCategory>;
  creativeGroups?: InputMaybe<Array<CreativeGroup>>;
  kept?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['ID']>;
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryRevisionArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryRevisionDeliverableArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryRevisionProjectArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QuerySpecificationConceptArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryStudiosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryUgcOrderArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryUgcOrderByConceptKeyArgs = {
  conceptKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryUserArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryUsersArgs = {
  params?: InputMaybe<UsersParams>;
};


/** The query root of this schema */
export type QueryValidateBriefForCsReviewArgs = {
  projectIntegrationKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryVariationArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type QueryVerticalsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};

/** Common query parameters for filtering and sorting. */
export type QueryParams = {
  /** Search matching results. */
  search: Scalars['String'];
  /** Direction to sort results. */
  sortDirection: SortDirection;
  /** Field to sort results by. */
  sortField: Scalars['String'];
};

/** A question */
export type Question = {
  __typename?: 'Question';
  /** Flag indicating that the question can have additional notes. */
  canHaveNotes: Scalars['Boolean'];
  /** Category of the question, determining where it is displayed. */
  category: QuestionCategory;
  /** For internal use. To aid copilot in brief generation. */
  copilotPrompt?: Maybe<Scalars['String']>;
  /** Flag indicating that the question is a copy variation question. */
  copyVariation: Scalars['Boolean'];
  /** Description of the question with additional details. */
  description?: Maybe<Scalars['String']>;
  /** The time this question was archived, or null. */
  discardedAt?: Maybe<Scalars['BigInt']>;
  /** A flag to determine if the question should be displayed regardless of selected formats. */
  fixed: Scalars['Boolean'];
  /** The UUID of the Question. */
  id: Scalars['ID'];
  /** A unique human-readable key for the Question. */
  key: Scalars['String'];
  /** The type of question. */
  kind: QuestionKind;
  /** Text label of the question asked. */
  label?: Maybe<Scalars['String']>;
  /** Determines the default height of `text` and `textarea` questions. */
  minRows?: Maybe<Scalars['Int']>;
  /** Placeholder text to appear before the user interacts. */
  placeholder?: Maybe<Scalars['String']>;
  /** Position determining the order to display question. */
  position?: Maybe<Scalars['Int']>;
  /** QuestionDeliverableFormats belonging to the Question. */
  questionDeliverableFormats: Array<QuestionDeliverableFormat>;
  /** The follow up option. */
  questionOption?: Maybe<QuestionOption>;
  /** Selectable options for multiple choice type questions. */
  questionOptions: Array<QuestionOption>;
  /** Flag determining if the questions must be answered. */
  required: Scalars['Boolean'];
  /** Section the question belongs to within the Category. */
  section?: Maybe<QuestionSection>;
  /** Short text label of the question. */
  shortLabel?: Maybe<Scalars['String']>;
  /** Informative text to be displayed with the question. */
  tooltip?: Maybe<Scalars['String']>;
  /** A flag to determine if the answer should be translated. */
  translatable: Scalars['Boolean'];
};

export enum QuestionCategory {
  Advert = 'advert',
  Campaign = 'campaign'
}

/** A Question Deliverable Format. */
export type QuestionDeliverableFormat = {
  __typename?: 'QuestionDeliverableFormat';
  /** The deliverable format assigned to the Question. */
  deliverableFormat: DeliverableFormat;
  /** ID of the deliverable format assigned to the Question. */
  deliverableFormatId: Scalars['String'];
  /** The length of deliverable. */
  formatLength: FormatLength;
  /** The UUID of the QuestionDeliverableFormat. */
  id: Scalars['ID'];
};

export enum QuestionKind {
  Checkbox = 'checkbox',
  Date = 'date',
  Dropdown = 'dropdown',
  Message = 'message',
  Radio = 'radio',
  Text = 'text',
  Textarea = 'textarea',
  Translatable = 'translatable'
}

/** A question option */
export type QuestionOption = {
  __typename?: 'QuestionOption';
  /**
   * Follow up questions belonging to the question option.
   *
   * Permitted search attributes: ["id", "question_option_id"]
   *
   * Permitted sort attributes: ["created_at", "position"]
   */
  followUpQuestions: Array<Question>;
  /** The UUID of the Question Option. */
  id: Scalars['ID'];
  /** Text label of the selectable option. */
  label: Scalars['String'];
  /** Position value of the Question Option. */
  position: Scalars['Int'];
};


/** A question option */
export type QuestionOptionFollowUpQuestionsArgs = {
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};

/** A Question Section */
export type QuestionSection = {
  __typename?: 'QuestionSection';
  /** The UUID of the Question Section. */
  id: Scalars['ID'];
  /** The Section's key */
  key: QuestionSectionKey;
  /** Position this section (or sub-section) should appear. */
  position: Scalars['Int'];
  /** The Section's primary title. */
  title: Scalars['String'];
};

export enum QuestionSectionKey {
  AdditionalDetails = 'additionalDetails',
  Audio = 'audio',
  ConceptBasics = 'conceptBasics',
  ConceptIdea = 'conceptIdea',
  CopyVariations = 'copyVariations',
  Disclaimer = 'disclaimer',
  Messaging = 'messaging',
  PlatformSpecifics = 'platformSpecifics',
  ProjectBasics = 'projectBasics',
  VoiceOver = 'voiceOver'
}

/** A region */
export type Region = {
  __typename?: 'Region';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of RejectDeliverables */
export type RejectDeliverablesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Inputs used for rejecting deliverables. */
  input: DeliverablesRejectInput;
};

/** Autogenerated return type of RejectDeliverables. */
export type RejectDeliverablesPayload = {
  __typename?: 'RejectDeliverablesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The rejected Order. */
  order?: Maybe<Order>;
};

/** Input for rejecting a revision and adding a new comment. */
export type RejectRevisionInput = {
  /** Deliverable ID that the new revision + comment is being added to. */
  deliverableId: Scalars['ID'];
  /** Revision ID that is being rejected. */
  id: Scalars['ID'];
  /** The body text of the rejection comment */
  message: Scalars['String'];
};

export type RemoveConceptVariationInput = {
  integrationKey: Scalars['ID'];
};

/** Autogenerated input type of RemoveDeliverableFormatsFromConcept */
export type RemoveDeliverableFormatsFromConceptInput = {
  /**
   * The advert id
   * @deprecated Use `advertIntegrationKey` instead.
   */
  advertId?: InputMaybe<Scalars['ID']>;
  /** The advert integration key */
  advertIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ids of the advert deliverable formats to remove from the advert */
  formatIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of RemoveDeliverableFormatsFromConcept. */
export type RemoveDeliverableFormatsFromConceptPayload = {
  __typename?: 'RemoveDeliverableFormatsFromConceptPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of RemoveUser */
export type RemoveUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the User to be removed. */
  user: UserRemoveInput;
};

/** Autogenerated return type of RemoveUser. */
export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RemoveVariationInput = {
  key: Scalars['ID'];
};

export type RenewInvitationInput = {
  /** Unique key of the invitation to resend */
  key: Scalars['String'];
  /** Optional flag to skip sending an email */
  skipEmail?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of ReplaceDeliverable */
export type ReplaceDeliverableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Deliverable to be replaced. */
  id: Scalars['ID'];
};

/** Autogenerated return type of ReplaceDeliverable. */
export type ReplaceDeliverablePayload = {
  __typename?: 'ReplaceDeliverablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable to be replaced. */
  deliverable?: Maybe<Deliverable>;
};

export enum ReportItemCategory {
  AsteriskUsage = 'ASTERISK_USAGE',
  BitrateMode = 'BITRATE_MODE',
  ColorSetting = 'COLOR_SETTING',
  Copy = 'COPY',
  CopyMatching = 'COPY_MATCHING',
  CustomPlatformSpecification = 'CUSTOM_PLATFORM_SPECIFICATION',
  DeliverableFileNaming = 'DELIVERABLE_FILE_NAMING',
  Dimension = 'DIMENSION',
  DisclaimerCopy = 'DISCLAIMER_COPY',
  DisclaimerUsage = 'DISCLAIMER_USAGE',
  FileSize = 'FILE_SIZE',
  FrameRatePerSecond = 'FRAME_RATE_PER_SECOND',
  Grammar = 'GRAMMAR',
  LanguageApplied = 'LANGUAGE_APPLIED',
  LetterCase = 'LETTER_CASE',
  TextLegibility = 'TEXT_LEGIBILITY',
  VideoDuration = 'VIDEO_DURATION',
  VideoTotalBitrate = 'VIDEO_TOTAL_BITRATE'
}

export enum ReportItemCategoryScore {
  Fail = 'FAIL',
  Pass = 'PASS',
  Review = 'REVIEW'
}

export enum ReportItemScore {
  Fail = 'FAIL',
  Pass = 'PASS',
  Review = 'REVIEW'
}

export enum ReportItemTopic {
  Copy = 'COPY',
  Technical = 'TECHNICAL'
}

/** Autogenerated input type of ResendLegacyInvitation */
export type ResendLegacyInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the User to be reinvited. */
  user: InvitationResendInput;
};

/** Autogenerated return type of ResendLegacyInvitation. */
export type ResendLegacyInvitationPayload = {
  __typename?: 'ResendLegacyInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The invited User. */
  user: LegacyUser;
};

/** Autogenerated input type of RevertBriefToUgcIdeation */
export type RevertBriefToUgcIdeationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be reverted. */
  order: BriefRevertToUgcIdeationInput;
};

/** Autogenerated return type of RevertBriefToUgcIdeation. */
export type RevertBriefToUgcIdeationPayload = {
  __typename?: 'RevertBriefToUgcIdeationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The reverted order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of ReviseOrder */
export type ReviseOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Inputs used for revising a project. */
  input: OrderReviseInput;
};

/** Autogenerated return type of ReviseOrder. */
export type ReviseOrderPayload = {
  __typename?: 'ReviseOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The revised Order. */
  order?: Maybe<Order>;
};

export type Revision = {
  __typename?: 'Revision';
  annotations: Array<Annotation>;
  appliesToConcept: Scalars['Boolean'];
  attachments: Array<Attachment>;
  author?: Maybe<Author>;
  category: RevisionCategory;
  changeRequestIntegrationKey: Scalars['ID'];
  comment: Scalars['String'];
  comments: Array<Comment>;
  createdAt: Scalars['Time'];
  date: Scalars['String'];
  deliverable: RevisionDeliverable;
  fullFrameUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRejected: Scalars['Boolean'];
  resolved: Scalars['Boolean'];
  sequenceID: Scalars['Int'];
  subCategory?: Maybe<RevisionSubCategory>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** Apply revision on a video format to a specific point in time. Also used to track the primary frame for annotations. */
  timestamp?: Maybe<Scalars['Float']>;
  /** End point in time for applying a time range to a revision. */
  timestampEnd?: Maybe<Scalars['Float']>;
  /** Starting point in time for applying a time range to a revision. */
  timestampStart?: Maybe<Scalars['Float']>;
  /** Type of timestamp that is applied to a revision. */
  timestampType: TimestampType;
};

export enum RevisionCategory {
  Audio = 'Audio',
  Imagery = 'Imagery',
  Other = 'Other',
  Text = 'Text',
  Timing = 'Timing',
  Transition = 'Transition'
}

/**
 * Represents a concept in the Revision domain.
 *
 * This type will be superseded by the Concept and Variation types in the Concept domain.
 */
export type RevisionConcept = {
  __typename?: 'RevisionConcept';
  activeDeliverables: Array<RevisionDeliverable>;
  additionalNotes: Scalars['String'];
  deliverables: Array<RevisionDeliverable>;
  heroNotes: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  project: Project;
};


/**
 * Represents a concept in the Revision domain.
 *
 * This type will be superseded by the Concept and Variation types in the Concept domain.
 */
export type RevisionConceptActiveDeliverablesArgs = {
  keys?: InputMaybe<Array<Scalars['ID']>>;
};

/**
 * Represents a deliverable in the Revision domain.
 *
 * This type will be superseded by the Deliverable type in the Deliverable domain.
 */
export type RevisionDeliverable = {
  __typename?: 'RevisionDeliverable';
  bitrate?: Maybe<Scalars['Int']>;
  concept: RevisionConcept;
  copyVariationNumber: Scalars['Int'];
  creativeX?: Maybe<DeliverableCreativeX>;
  duration?: Maybe<Scalars['Uint64']>;
  externallyDeliveredAt?: Maybe<Scalars['Time']>;
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  formatLength: FormatLength;
  formatName: Scalars['String'];
  framePerSecond?: Maybe<Scalars['Float']>;
  hasConceptRevisions: Scalars['Boolean'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  internallyDeliveredAt?: Maybe<Scalars['Time']>;
  isExternal: Scalars['Boolean'];
  isHero: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  /** Is this record a variation for revising an existing deliverable (i.e. version '0')? */
  isSourceVariation: Scalars['Boolean'];
  key: Scalars['ID'];
  language: Language;
  mediaType: MediaType;
  nextDeliverable?: Maybe<RevisionDeliverable>;
  notifications?: Maybe<Array<Notification>>;
  overlayUrl?: Maybe<Scalars['String']>;
  platformCode: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  previousDeliverable?: Maybe<RevisionDeliverable>;
  revisions: Array<Revision>;
  revisionsCount: Scalars['Int'];
  s3Key: Scalars['String'];
  specificationIntegrationKey?: Maybe<Scalars['ID']>;
  status: DeliverableStatus;
  unitNumber: Scalars['Int'];
  url: Scalars['String'];
  versionNumberExternal?: Maybe<Scalars['Int']>;
  versionNumberInternal?: Maybe<Scalars['Int']>;
  versions?: Maybe<Array<RevisionDeliverable>>;
  width: Scalars['Int'];
};


/**
 * Represents a deliverable in the Revision domain.
 *
 * This type will be superseded by the Deliverable type in the Deliverable domain.
 */
export type RevisionDeliverableOverlayUrlArgs = {
  type: OverlayType;
};

export type RevisionInput = {
  annotations?: InputMaybe<Array<AnnotationInput>>;
  appliesToConcept: Scalars['Boolean'];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  category: RevisionCategory;
  comment: Scalars['String'];
  date: Scalars['String'];
  deliverableKey: Scalars['ID'];
  fullFrame?: InputMaybe<Scalars['Upload']>;
  revisionSource?: InputMaybe<RevisionSourceInput>;
  subCategory?: InputMaybe<RevisionSubCategory>;
  thumbnail?: InputMaybe<Scalars['Upload']>;
  /** Apply revision on a video format to a specific point in time. Also used to track the primary frame for annotations. */
  timestamp?: InputMaybe<Scalars['Float']>;
  /** End point in time for applying a time range to a revision. */
  timestampEnd?: InputMaybe<Scalars['Float']>;
  /** Starting point in time for applying a time range to a revision. */
  timestampStart?: InputMaybe<Scalars['Float']>;
  /** Type of the timestamp that is applied to a revision. */
  timestampType: TimestampType;
};

export type RevisionSourceInput = {
  externalKey: Scalars['ID'];
  type: RevisionSourceType;
};

export enum RevisionSourceType {
  /** Revision comment was first created by a quality assurance report. */
  QaReportItem = 'QA_REPORT_ITEM'
}

export enum RevisionSubCategory {
  Animation = 'Animation',
  Colour = 'Colour',
  Font = 'Font',
  Layout = 'Layout',
  Music = 'Music',
  Other = 'Other',
  Size = 'Size',
  SoundEffects = 'SoundEffects',
  TextChange = 'TextChange',
  Voiceover = 'Voiceover'
}

export type Revisions = {
  __typename?: 'Revisions';
  count: Scalars['Int'];
  nodes: Array<Revision>;
};

export type RevokeCreatorOfferInput = {
  key: Scalars['ID'];
  reason: Scalars['String'];
};

export type Scene = {
  __typename?: 'Scene';
  id: Scalars['ID'];
  /** @deprecated Temporary Field. Needed to tie notes back to questions. */
  legacyQuestionId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

/** Scopes permitted to the requesting user. */
export type Scopes = {
  __typename?: 'Scopes';
  /** Scopes for a given Brand. */
  brand?: Maybe<BrandScopes>;
  /** Scopes for a given Client Account. */
  clientAccount?: Maybe<ClientAccountScopes>;
  /** Scopes for a given Project. */
  project?: Maybe<ProjectScopes>;
};


/** Scopes permitted to the requesting user. */
export type ScopesBrandArgs = {
  key: Scalars['ID'];
};


/** Scopes permitted to the requesting user. */
export type ScopesClientAccountArgs = {
  key: Scalars['ID'];
};


/** Scopes permitted to the requesting user. */
export type ScopesProjectArgs = {
  key: Scalars['ID'];
};

export enum Section {
  AdditionalDetails = 'additionalDetails',
  Audio = 'audio',
  CopyVariations = 'copyVariations',
  Disclaimer = 'disclaimer',
  Translations = 'translations',
  UgcContent = 'ugcContent'
}

/** Autogenerated input type of SetAssetNotes */
export type SetAssetNotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the note. */
  content: Scalars['String'];
  /** The UUID of the Asset. */
  id: Scalars['ID'];
};

/** Autogenerated return type of SetAssetNotes. */
export type SetAssetNotesPayload = {
  __typename?: 'SetAssetNotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  note?: Maybe<AssetNote>;
};

export type SetConceptLanguageInput = {
  /** Integration key of the concept to update. */
  integrationKey: Scalars['ID'];
  /** ISO-639 language code. */
  languageCode: Scalars['String'];
};

/** Input for setting a deal for a project. */
export type SetDealForProjectInput = {
  /** HubSpot deal ID to associate with the project. */
  dealId?: InputMaybe<Scalars['String']>;
  /** Whether no deal should be assigned to the project. If false, the dealId field must be provided. */
  noDealAssigned: Scalars['Boolean'];
  /** Key of the project to be updated. */
  projectKey: Scalars['ID'];
};

export type SetEnableUgcOrderingInput = {
  clientAccountKey: Scalars['ID'];
  enableUgcOrdering: Scalars['Boolean'];
};

/** Autogenerated input type of SetOrderCopilotStatus */
export type SetOrderCopilotStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Integration key of the order to be updated. */
  integrationKey: Scalars['ID'];
  /** Integration key of the order to be updated. */
  status: CopilotStatus;
};

/** Autogenerated return type of SetOrderCopilotStatus. */
export type SetOrderCopilotStatusPayload = {
  __typename?: 'SetOrderCopilotStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order, or null. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of SetOrderTargetDate */
export type SetOrderTargetDateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Target date to be set. */
  input: OrderTargetDateInput;
};

/** Autogenerated return type of SetOrderTargetDate. */
export type SetOrderTargetDatePayload = {
  __typename?: 'SetOrderTargetDatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

export type SetProjectAccessInput = {
  projectKey: Scalars['ID'];
  visibility: ProjectVisibility;
};

export type SetUgcAssetNoteInput = {
  /** Integration key of the UGC Asset to update notes for */
  key: Scalars['ID'];
  /** The new asset notes */
  note: Scalars['String'];
};

/** Sort direction. */
export enum SortDirection {
  /** Ascending order. */
  Asc = 'ASC',
  /** Descending order. */
  Desc = 'DESC'
}

/** A container for arguments used when sorting a results */
export type SortInput = {
  /** The field to sort results */
  field: Scalars['String'];
  /** The order to sort results */
  order: SortOrder;
};

export enum SortOrder {
  /** Ascending order. */
  Asc = 'ASC',
  /** Descending order. */
  Desc = 'DESC'
}

/**
 * Represents a concept in the Specification domain.
 *
 * This type will be superseded by the Concept and Variation types.
 */
export type SpecificationConcept = {
  __typename?: 'SpecificationConcept';
  createdAt: Scalars['Time'];
  deliverableSpecifications: Array<DeliverableSpecification>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index: Scalars['Int'];
  isInstantCopyChange: Scalars['Boolean'];
  key: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
  languageVariationCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  numCopyVariations: Scalars['Int'];
  source?: Maybe<SpecificationConcept>;
  variations: Array<SpecificationConcept>;
};

export type SpecificationDimension = {
  __typename?: 'SpecificationDimension';
  height: Scalars['Int'];
  isCustom: Scalars['Boolean'];
  ratio: Scalars['Float'];
  width: Scalars['Int'];
};

/** A container for arguments used when creating a production delivery turnaround for a project. */
export type StartedProductionStageInput = {
  /** The priority of the production delivery turnaround. */
  priority: ProductionPriority;
  /** Integration Key of the production stage to which the new production delivery turnaround will be assigned. */
  productionStageIntegrationKey: Scalars['ID'];
  /** Integration Key of the project to which the new production delivery turnaround will be assigned. */
  projectIntegrationKey: Scalars['ID'];
  /** The integration keys of the specifications to be associated with the production delivery turnaround. */
  specificationIntegrationKeys: Array<Scalars['ID']>;
  /** The type of the production stage. */
  stageType: ProductionStageType;
};

export type StringArrayFilter = {
  all?: InputMaybe<Array<Scalars['String']>>;
  any?: InputMaybe<Array<Scalars['String']>>;
};

export enum StringEncoding {
  Base64 = 'base64',
  None = 'none'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
};

/** A studio. */
export type Studio = {
  __typename?: 'Studio';
  /**
   * Art Directors belonging to the Studio.
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   */
  artDirectors: LegacyUsers;
  /**
   * Designers belonging to the Studio.
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   */
  designers: LegacyUsers;
  /** The UUID of the Studio. */
  id: Scalars['ID'];
  /** The name of the Studio. */
  name: Scalars['String'];
};


/** A studio. */
export type StudioArtDirectorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
};


/** A studio. */
export type StudioDesignersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
};

/** A paginated array of Studios */
export type Studios = {
  __typename?: 'Studios';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Studios */
  nodes: Array<Studio>;
};

export enum SubmissionState {
  Error = 'error',
  New = 'new',
  Touched = 'touched',
  Valid = 'valid'
}

export type SubmitAdaptRequestInput = {
  deliverableKey: Scalars['ID'];
  imageUrl: Scalars['String'];
  maskBase64Encoded: Scalars['String'];
  prompt: Scalars['String'];
  sessionId: Scalars['ID'];
};

export type SubmitBriefForCsReviewInput = {
  /** Integration key of the project brief to update. */
  projectIntegrationKey: Scalars['ID'];
};

export type SubmitBriefForProductionReviewInput = {
  /** Integration key of the project brief to update. */
  projectIntegrationKey: Scalars['ID'];
};

/** Input for submitting creator registration (public). */
export type SubmitCreatorRegistrationInput = {
  /** Address city. */
  addressCity: Scalars['String'];
  /** Address country code. */
  addressCountryCode: Scalars['String'];
  /** Address line 1. */
  addressLine1: Scalars['String'];
  /** Address line 2. */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** Address state. */
  addressState: Scalars['String'];
  /** Address zip code. */
  addressZipCode: Scalars['String'];
  /** Year of birth. */
  birthYear: Scalars['Int'];
  /** Email. */
  email: Scalars['String'];
  /** Ethnicity. */
  ethnicity: Scalars['String'];
  /** First name. */
  firstName: Scalars['String'];
  /** Gender identifier. */
  genderID: Scalars['ID'];
  /** Hobby / Interest identifiers. */
  hobbyInterestIDs: Array<Scalars['ID']>;
  /** Languages. */
  languages: Array<Scalars['String']>;
  /** Last name. */
  lastName: Scalars['String'];
  /** Media input. */
  media: Array<MediaInput>;
  /** Post ads answer. */
  postAds: CreatorPostAd;
  /** Profile / bio. */
  profile: Scalars['String'];
  /** Social media links. */
  socialMediaLinks: Array<Scalars['String']>;
  /** Specialty vertical identifiers. */
  specialtyVerticalIDs: Array<Scalars['ID']>;
};

/** Autogenerated input type of SubmitFormats */
export type SubmitFormatsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the formats to be submitted. */
  input: FormatsSubmitInput;
};

/** Autogenerated return type of SubmitFormats. */
export type SubmitFormatsPayload = {
  __typename?: 'SubmitFormatsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The project formats were submitted for. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of SubmitOrder */
export type SubmitOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be submitted. */
  order: OrderSubmitInput;
};

/** Autogenerated return type of SubmitOrder. */
export type SubmitOrderPayload = {
  __typename?: 'SubmitOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The submitted order. */
  order?: Maybe<Order>;
};

export type SubmitUgcDeliverablesInput = {
  /** Integration key of the UGC order to submit deliverables */
  ugcOrderKey: Scalars['ID'];
};

/** A container for arguments used when submitting an order. */
export type SubmittedBriefForCsReviewInput = {
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order. */
  integrationKey: Scalars['ID'];
  /** The date and time the order was submitted. */
  submittedAt: Scalars['ISO8601DateTime'];
  /** The integration key of the user who submitted the order. */
  userIntegrationKey: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  userCreated: User;
};

/** Autogenerated input type of SyncLegacyUser */
export type SyncLegacyUserInput = {
  /** Auth0 User Id to be assigned to the user. */
  auth0UserId?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UTC timestamp for the User's creation, as an Integer. */
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** The UTC timestamp for the User's archived time, as an Integer. */
  discardedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** The integration key of the user. */
  key: Scalars['ID'];
  /** The UTC timestamp for the User was last changed, as an Integer. */
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of SyncLegacyUser. */
export type SyncLegacyUserPayload = {
  __typename?: 'SyncLegacyUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the user was successfully synced. */
  success?: Maybe<Scalars['Boolean']>;
};

export enum TargetGender {
  All = 'All',
  Female = 'Female',
  Male = 'Male'
}

export enum Ternary {
  No = 'NO',
  Unsure = 'UNSURE',
  Yes = 'YES'
}

export type TimeFilter = {
  eq?: InputMaybe<Scalars['Time']>;
  gt?: InputMaybe<Scalars['Time']>;
  gte?: InputMaybe<Scalars['Time']>;
  in?: InputMaybe<Array<Scalars['Time']>>;
  lt?: InputMaybe<Scalars['Time']>;
  lte?: InputMaybe<Scalars['Time']>;
};

export enum TimestampType {
  None = 'None',
  Point = 'Point',
  Range = 'Range'
}

/** A region */
export type TrelloBoard = {
  __typename?: 'TrelloBoard';
  boardId: Scalars['String'];
  defaultLabelId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  newCardListId: Scalars['String'];
  orderReadyListId?: Maybe<Scalars['String']>;
  productionReviewListId?: Maybe<Scalars['String']>;
  projectCompleteListId: Scalars['String'];
};

export type UgcAsset = {
  __typename?: 'UGCAsset';
  file: File;
  /** @deprecated Use `file` instead. */
  fileKey: Scalars['ID'];
  id: Scalars['ID'];
  key: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type UgcAssetLink = {
  __typename?: 'UGCAssetLink';
  note?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UgcAssetLinkInput = {
  note?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

/** A UGC brief. */
export type UgcBrief = {
  __typename?: 'UGCBrief';
  additionalInstructions?: Maybe<Scalars['String']>;
  approach?: Maybe<ConceptApproach>;
  /** The audience of the concept. */
  audience?: Maybe<Audience>;
  brandAssets: Array<UgcAsset>;
  brandDescription?: Maybe<Scalars['String']>;
  /** The presigned URL of the brand logo. */
  brandLogoUrl?: Maybe<Scalars['String']>;
  /** The name of the brand. */
  brandName: Scalars['String'];
  brandProductPositioning?: Maybe<Scalars['String']>;
  brandRelevantSocialChannels?: Maybe<Scalars['String']>;
  concept: ConceptLite;
  conceptDirection?: Maybe<Scalars['String']>;
  /** The name of the concept. */
  conceptName: Scalars['String'];
  /** The objective of the concept. */
  conceptObjective?: Maybe<Scalars['String']>;
  creatorAdditionalRequirements?: Maybe<Scalars['String']>;
  creatorAge?: Maybe<AgeRangePreference>;
  creatorEthnicity?: Maybe<Scalars['String']>;
  creatorGender?: Maybe<GenderPreference>;
  creatorInterestsAndHobbies?: Maybe<Scalars['String']>;
  creatorLocation?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Scalars['String']>;
  dosAndDonts?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages: Array<Language>;
  legalRequirements?: Maybe<Scalars['String']>;
  productProcurement?: Maybe<Scalars['String']>;
  /** The name of the project. */
  projectName: Scalars['String'];
  referenceAssets: Array<UgcAsset>;
  referenceLinks: Array<UgcAssetLink>;
  scriptOrVoiceOverInstructions?: Maybe<Scalars['String']>;
  sections: Array<UgcBriefSection>;
  theHook?: Maybe<Scalars['String']>;
  ugcOrderKey: Scalars['ID'];
  uniqueSellingPoints?: Maybe<Scalars['String']>;
};

export enum UgcBriefSection {
  AdditionalInstructions = 'additionalInstructions',
  BrandDetails = 'brandDetails',
  DosAndDonts = 'dosAndDonts',
  Language = 'language',
  LegalRequirements = 'legalRequirements',
  ProductProcurement = 'productProcurement',
  ScriptOrVoiceOverInstructions = 'scriptOrVoiceOverInstructions',
  TheHook = 'theHook',
  UniqueSellingPoints = 'uniqueSellingPoints'
}

export type UgcDeliverable = {
  __typename?: 'UGCDeliverable';
  file?: Maybe<File>;
  key: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  state: UgcDeliverableState;
  submitted_time?: Maybe<Scalars['Time']>;
};

export enum UgcDeliverableState {
  Approved = 'APPROVED',
  Inactive = 'INACTIVE',
  InReview = 'IN_REVIEW',
  Pending = 'PENDING'
}

export type UgcOrder = {
  __typename?: 'UGCOrder';
  activeOffer?: Maybe<CreatorOffer>;
  brief: UgcBrief;
  canArchive: Scalars['Boolean'];
  canSelectCreator: Scalars['Boolean'];
  canShortlistCreators: Scalars['Boolean'];
  canWriteUgcBrief: Scalars['Boolean'];
  conceptKey: Scalars['ID'];
  creator?: Maybe<Creator>;
  creatorShortlist: Array<Creator>;
  deliverableLinks: Array<UgcAssetLink>;
  deliverables: Array<UgcDeliverable>;
  id: Scalars['ID'];
  key: Scalars['ID'];
  projectKey: Scalars['ID'];
  /** @deprecated Use `canWriteUgcBrief` instead. */
  readonly: Scalars['Boolean'];
  state: UgcOrderState;
};

export type UgcOrderInput = {
  conceptKey: Scalars['ID'];
  deliverableLinks?: InputMaybe<Array<UgcAssetLinkInput>>;
  projectKey: Scalars['ID'];
};

export enum UgcOrderState {
  Archived = 'ARCHIVED',
  Complete = 'COMPLETE',
  Ideation = 'IDEATION',
  InProduction = 'IN_PRODUCTION',
  InReview = 'IN_REVIEW',
  Pending = 'PENDING'
}

/** A container for arguments used when an order has been submitted. */
export type UgcIdeationSubmissionInput = {
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order. */
  integrationKey: Scalars['ID'];
  /** The date and time the order was submitted. */
  submittedAt: Scalars['ISO8601DateTime'];
  /** The integration key of the user who submitted the order. */
  userIntegrationKey: Scalars['ID'];
};

export type UnapproveDeliverableInput = {
  integrationKey: Scalars['ID'];
};

/** A container for arguments used when unapproving a deliverable */
export type UnapprovedDeliverableInput = {
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /**
   * Event sequence ID of incoming event.
   * @deprecated Related to Kinesis events. Superseded by knative and `event_id`.
   */
  eventSequenceId?: InputMaybe<Scalars['Int']>;
  /** A surrogate UUID used to identify a deliverable when integrating with external apps. */
  integrationKey: Scalars['ID'];
};

/** Autogenerated input type of UpdateAdvert */
export type UpdateAdvertInput = {
  /** Details of the advert to be updated. */
  advert: AdvertUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAdvert. */
export type UpdateAdvertPayload = {
  __typename?: 'UpdateAdvertPayload';
  /** The updated advert. */
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateAuth0IntegrationKey */
export type UpdateAuth0IntegrationKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Integration Key of the user to be updated. */
  integrationKey: Auth0IntegrationKeyUpdateInput;
};

/** Autogenerated return type of UpdateAuth0IntegrationKey. */
export type UpdateAuth0IntegrationKeyPayload = {
  __typename?: 'UpdateAuth0IntegrationKeyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The user to be updated. */
  user?: Maybe<LegacyUser>;
};

/**  Input for updating a client account.  */
export type UpdateClientAccountInput = {
  /**  The new value for the enableGenerativeAI property.  */
  enableGenerativeAI: Scalars['Boolean'];
  /**  The new value for the enablePlatformAI property.  */
  enablePlatformAI: Scalars['Boolean'];
  /**  The new value for the enableUgcOrdering property.  */
  enableUgcOrdering: Scalars['Boolean'];
  /**  The key of the client account to update.  */
  key: Scalars['ID'];
  /**  The new value for the requireHubspotDeals property.  */
  requireHubspotDeals: Scalars['Boolean'];
};

export type UpdateClientBrandInput = {
  defaultProjectVisibility: ProjectVisibility;
  key: Scalars['ID'];
};

/** Autogenerated input type of UpdateClient */
export type UpdateClientInput = {
  /** Details of the client to be updated. */
  client: ClientUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateClient. */
export type UpdateClientPayload = {
  __typename?: 'UpdateClientPayload';
  /** The updated client. */
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateConceptDescriptionInput = {
  description: Scalars['String'];
  integrationKey: Scalars['ID'];
};

export type UpdateConceptFormatsInput = {
  formats: Array<InputMaybe<FormatInput>>;
  integrationKey: Scalars['ID'];
};

export type UpdateConceptNotesInput = {
  additionalNotes: Scalars['String'];
  heroNotes: Scalars['String'];
  integrationKey: Scalars['ID'];
};

export type UpdateConceptVariationFormatsInput = {
  deliverableSpecificationIntegrationKeys: Array<Scalars['ID']>;
  integrationKey: Scalars['ID'];
};

export type UpdateCreativeXBrandInput = {
  /** The client brand key */
  clientBrandKey: Scalars['ID'];
  /** The CreativeX brand ID */
  externalBrandID: Scalars['Int'];
};

export type UpdateCreativeXIntegrationInput = {
  /** The CreativeX API key */
  apiKey?: InputMaybe<Scalars['String']>;
  /** The client account key */
  clientAccountKey: Scalars['ID'];
};

export type UpdateCsReviewNotesInput = {
  /** Notes for CS review. */
  note?: InputMaybe<Scalars['String']>;
  /** Integration key of the project brief to update. */
  projectIntegrationKey: Scalars['ID'];
};

/** Autogenerated input type of UpdateDeliverable */
export type UpdateDeliverableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Fields of the deliverable to be updated. */
  deliverable: DeliverableUpdateInput;
};

/** Autogenerated return type of UpdateDeliverable. */
export type UpdateDeliverablePayload = {
  __typename?: 'UpdateDeliverablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable to be updated. */
  deliverable?: Maybe<Deliverable>;
};

/** Autogenerated input type of UpdateDeliveryTurnaround */
export type UpdateDeliveryTurnaroundInput = {
  /** Reason the delivery turnaround time is being adjusted. */
  adjustedReason: Scalars['String'];
  /** New delivery turnaround time. */
  adjustedTime: Scalars['BigInt'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the target delivery turnaround. */
  key: Scalars['ID'];
};

/** Autogenerated return type of UpdateDeliveryTurnaround. */
export type UpdateDeliveryTurnaroundPayload = {
  __typename?: 'UpdateDeliveryTurnaroundPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Indicates if the mutation was successful. */
  deliveryTurnaround?: Maybe<DeliveryTurnaround>;
};

/** Autogenerated input type of UpdateLegacyUser */
export type UpdateLegacyUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the user to be updated. */
  user: UserUpdateInput;
};

/** Autogenerated return type of UpdateLegacyUser. */
export type UpdateLegacyUserPayload = {
  __typename?: 'UpdateLegacyUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** True if the user was updated, false otherwise. */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateOrderDeliverables */
export type UpdateOrderDeliverablesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be updated. */
  order: OrderDeliverablesUpdateInput;
};

/** Autogenerated return type of UpdateOrderDeliverables. */
export type UpdateOrderDeliverablesPayload = {
  __typename?: 'UpdateOrderDeliverablesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of UpdateOrder */
export type UpdateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be updated. */
  order: OrderUpdateInput;
};

/** Autogenerated return type of UpdateOrder. */
export type UpdateOrderPayload = {
  __typename?: 'UpdateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of UpdateTranslations */
export type UpdateTranslationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Order. */
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateTranslations. */
export type UpdateTranslationsPayload = {
  __typename?: 'UpdateTranslationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of UpdateTrello */
export type UpdateTrelloInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Order. */
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateTrelloLabels */
export type UpdateTrelloLabelsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** The UUID of the target project. */
  integrationKey: Scalars['ID'];
};

/** Autogenerated return type of UpdateTrelloLabels. */
export type UpdateTrelloLabelsPayload = {
  __typename?: 'UpdateTrelloLabelsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Indicates if the mutation was successful. */
  result?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateTrello. */
export type UpdateTrelloPayload = {
  __typename?: 'UpdateTrelloPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Order. */
  order?: Maybe<Order>;
};

export type UpdateUgcBriefInput = {
  additionalInstructions?: InputMaybe<Scalars['String']>;
  approach?: InputMaybe<ConceptApproach>;
  brandDescription?: InputMaybe<Scalars['String']>;
  brandProductPositioning?: InputMaybe<Scalars['String']>;
  brandRelevantSocialChannels?: InputMaybe<Scalars['String']>;
  conceptDirection?: InputMaybe<Scalars['String']>;
  creatorAdditionalRequirements?: InputMaybe<Scalars['String']>;
  creatorAge?: InputMaybe<AgeRangePreference>;
  creatorEthnicity?: InputMaybe<Scalars['String']>;
  creatorGender?: InputMaybe<GenderPreference>;
  creatorInterestsAndHobbies?: InputMaybe<Scalars['String']>;
  creatorLocation?: InputMaybe<Scalars['String']>;
  deliverable?: InputMaybe<Scalars['String']>;
  dosAndDonts?: InputMaybe<Scalars['String']>;
  languageCodes?: InputMaybe<Array<Scalars['String']>>;
  legalRequirements?: InputMaybe<Scalars['String']>;
  productProcurement?: InputMaybe<Scalars['String']>;
  referenceLinks?: InputMaybe<Array<UgcAssetLinkInput>>;
  scriptOrVoiceOverInstructions?: InputMaybe<Scalars['String']>;
  sections?: InputMaybe<Array<UgcBriefSection>>;
  theHook?: InputMaybe<Scalars['String']>;
  ugcOrderKey: Scalars['ID'];
  uniqueSellingPoints?: InputMaybe<Scalars['String']>;
};

export type UpdateUgcDeliverableInput = {
  key: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  /** Email address belonging to the user */
  email?: InputMaybe<Scalars['String']>;
  /** Given name of the user */
  firstName?: InputMaybe<Scalars['String']>;
  /** Integration key of the user to update */
  key: Scalars['ID'];
  /** Family name of the user */
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateVariationBriefInput = {
  /** Key of the Variation to update. */
  key?: InputMaybe<Scalars['ID']>;
  /** Legacy copy variation key of the Variation to update. */
  legacyCopyVariationKey?: InputMaybe<Scalars['ID']>;
  /** The name of the Variation. */
  name: Scalars['String'];
};

export type UpdateVariationDescriptionInput = {
  description: Scalars['String'];
  key: Scalars['ID'];
};

export type UpdateVariationFormatsInput = {
  formats?: InputMaybe<Array<InputMaybe<FormatInput>>>;
  key: Scalars['ID'];
  specificationKeys?: InputMaybe<Array<Scalars['ID']>>;
};

export enum UsageType {
  Deliverable = 'DELIVERABLE',
  Hero = 'HERO'
}

export type User = {
  __typename?: 'User';
  /** The UTC timestamp when the user was archived */
  archivedAt?: Maybe<Scalars['Time']>;
  /** The Auth0 user associated with this user. */
  auth0User?: Maybe<Auth0User>;
  /** The UTC timestamp when the user was created */
  createdAt?: Maybe<Scalars['Time']>;
  /** Email address belonging to the user */
  email?: Maybe<Scalars['String']>;
  /** Given name of the user */
  firstName?: Maybe<Scalars['String']>;
  /** Full name of the user (first name + last name) */
  fullName?: Maybe<Scalars['String']>;
  /** A unique identifier for the user. */
  key: Scalars['ID'];
  /** Family name of the user */
  lastName?: Maybe<Scalars['String']>;
  /** URL to the users avatar/profile picture */
  picture?: Maybe<Scalars['String']>;
  /**
   * RBAC role for the user.
   * @deprecated For backwards compatibility. Will be removed/replaced with ABAC policies in the future
   */
  role: UserRole;
  /** The UTC timestamp when the user was last updated */
  updatedAt?: Maybe<Scalars['Time']>;
};

/** A container for arguments used when removing a user from an account */
export type UserRemoveInput = {
  /**
   * UUID of the Client to add the new User to.
   * @deprecated Use `clientIntegrationKey` instead
   */
  clientId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the Client to add the new User to. */
  clientIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** UUID of the User to be removed. */
  id: Scalars['ID'];
};

export enum UserRole {
  Client = 'CLIENT',
  Guest = 'GUEST',
  SrcAdmin = 'SRC_ADMIN',
  SrcArtDirector = 'SRC_ART_DIRECTOR',
  SrcDesigner = 'SRC_DESIGNER',
  SrcManager = 'SRC_MANAGER',
  SrcTraffic = 'SRC_TRAFFIC'
}

/** A container for arguments used when updating a user. */
export type UserUpdateInput = {
  /** Updated email address of the user, this will need to be reconfirmed if modified. */
  email?: InputMaybe<Scalars['String']>;
  /** Given name of the User. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Family name of the User. */
  lastName?: InputMaybe<Scalars['String']>;
};

export type Users = {
  __typename?: 'Users';
  meta: OffsetPageInfo;
  nodes: Array<User>;
};

export type UsersFilter = {
  /**
   * Filter to active/archived users, or both
   * Default: FALSE
   */
  archived?: InputMaybe<ArchivedFilter>;
  key?: InputMaybe<IdFilter>;
  /** Substring search over email, name and auth0 user id */
  keyword?: InputMaybe<Scalars['String']>;
  orgUnitKey?: InputMaybe<IdFilter>;
};

export type UsersParams = {
  filter?: InputMaybe<UsersFilter>;
  pagination?: InputMaybe<OffsetPagination>;
  sort?: InputMaybe<SortInput>;
};

export type Variation = {
  __typename?: 'Variation';
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isInstantCopyChange: Scalars['Boolean'];
  key: Scalars['ID'];
  language?: Maybe<Language>;
  languageVariations: Array<Language>;
  name: Scalars['String'];
  numCopyVariations: Scalars['Int'];
  revisionsCount: Scalars['Int'];
  specifications: DeliverableSpecifications;
  type: VariationType;
};


export type VariationSpecificationsArgs = {
  params?: InputMaybe<DeliverableSpecificationsParams>;
};

export type VariationBrief = {
  __typename?: 'VariationBrief';
  /** The key of the Variation. */
  key: Scalars['ID'];
  /** The key of the Advert::CopyVariation in the legacy Order service. */
  legacyCopyVariationKey: Scalars['ID'];
  /** The name of the Variation. */
  name: Scalars['String'];
};

export enum VariationType {
  ConceptVariation = 'CONCEPT_VARIATION',
  CopyVariation = 'COPY_VARIATION',
  LanguageVariation = 'LANGUAGE_VARIATION',
  Original = 'ORIGINAL'
}

export type Variations = {
  __typename?: 'Variations';
  count: Scalars['Int'];
  nodes: Array<Variation>;
};

export type VariationsFilter = {
  key?: InputMaybe<IdFilter>;
};

export type VariationsParams = {
  filter?: InputMaybe<VariationsFilter>;
};

/** A vertical */
export type Vertical = {
  __typename?: 'Vertical';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A paginated array of Verticals */
export type Verticals = {
  __typename?: 'Verticals';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Verticals */
  nodes: Array<Vertical>;
};

/** A workflow step */
export type WorkflowStep = {
  __typename?: 'WorkflowStep';
  id: Scalars['ID'];
  maxNumRevisions?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nextStep?: Maybe<WorkflowStep>;
  productionId?: Maybe<Scalars['String']>;
  revisionLimitDays?: Maybe<Scalars['Int']>;
  revisionTurnaroundDays?: Maybe<Scalars['Int']>;
  turnaroundDays?: Maybe<Scalars['Int']>;
};

/** A Working File */
export type WorkingFile = {
  __typename?: 'WorkingFile';
  /** The UUID of the Working File. */
  id: Scalars['ID'];
  /** A surrogate UUID used to identify a Working File in external apps. */
  integrationKey: Scalars['ID'];
  /** The order assigned to the Working File. */
  order?: Maybe<Order>;
  /** The user who uploaded the Working File. */
  user?: Maybe<LegacyUser>;
};

/** A paginated array of Working Files */
export type WorkingFiles = {
  __typename?: 'WorkingFiles';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Working Files */
  nodes: Array<WorkingFile>;
};

export enum Join__Graph {
  Concept = 'CONCEPT',
  CoreServices = 'CORE_SERVICES',
  Creator = 'CREATOR',
  Customer = 'CUSTOMER',
  Deliverable = 'DELIVERABLE',
  Order = 'ORDER',
  Policy = 'POLICY',
  Production = 'PRODUCTION',
  Project = 'PROJECT',
  QualityAssurance = 'QUALITY_ASSURANCE',
  Revision = 'REVISION',
  Specification = 'SPECIFICATION',
  Storage = 'STORAGE',
  Usage = 'USAGE',
  User = 'USER'
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}
