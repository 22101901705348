import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCircleDot } from '@fortawesome/pro-regular-svg-icons/faCircleDot';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons/faSquareCheck';
import {
  Box,
  createTheme,
  formControlLabelClasses,
  formHelperTextClasses,
  formLabelClasses,
  Theme,
  ThemeOptions,
} from '@mui/material';
import { mergeWith } from 'lodash';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { tableCellClasses } from '@mui/material/TableCell';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { FaIcon } from '../components/DataDisplay/Icon/FaIcon';

/// Converts a `px` number to `rem` units - assumes a base font size of 16px (browser defaults)
export const pxToRem = (px: number): string => `${px / 16}rem`;

const typography: ThemeOptions['typography'] = {
  fontFamily: 'Inter',
  xxl: {
    fontWeight: 700,
    fontSize: '3rem',
    lineHeight: '3.5rem',
  },
  xl: {
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '3rem',
  },
  h1: {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  h2: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
  },
  h4: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  h5: {
    fontWeight: 600,
    fontSize: '0.9375rem',
    lineHeight: '1.5rem',
  },
  h6: {
    fontWeight: 600,
    fontSize: '0.8125rem',
    lineHeight: '1.1875rem',
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
  },
  body1: {
    fontWeight: 400,
    fontSize: pxToRem(15),
    lineHeight: pxToRem(24),
  },
  body2: {
    fontWeight: 400,
    fontSize: pxToRem(13),
    lineHeight: pxToRem(19),
  },
  overline: {
    fontWeight: 500,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
  },
  inputLabel: {
    fontWeight: 500,
    fontSize: pxToRem(15),
    lineHeight: pxToRem(20),
  },
  inputText: {
    fontWeight: 400,
    fontSize: pxToRem(15),
    lineHeight: pxToRem(20),
  },
  inputTextMedium: {
    fontWeight: 400,
    fontSize: pxToRem(17),
    lineHeight: pxToRem(32),
    letterSpacing: '0.25px',
  },
  helperText: {
    fontWeight: 400,
    fontSize: pxToRem(13),
    lineHeight: pxToRem(20),
  },
  caption: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
  },
};

/**
 * The `BaseTheme` is responsible for concerns that are global between both `LightTheme` and `DarkTheme`
 *
 * Typically this includes things like Typography, default props and styles that influence margin/padding
 *
 * THERE SHOULD NOT BE ANY HARD-CODED COLOURS DEFINED IN THE BASE THEME
 */
const BaseTheme: Partial<ThemeOptions> = {
  shape: { borderRadius: 8 },
  typography,
  // Shadows are an exception to the "no hard-coded colours" rule as they are the same for both themes
  // 0px -3px 13px rgba(0, 0, 0, 0.02), 0px -1px 24px rgba(17, 17, 17, 0.08), 0px -5px 9px rgba(17, 17, 17, 0.02);
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(17,17,17,0.05),0px 1px 1px 0px rgba(17,17,17,0.02),0px 1px 7px 0px rgba(17,17,17,0.08)',
    '0px 3px 1px -2px rgba(17,17,17,0.05),0px 2px 2px 0px rgba(17,17,17,0.02),0px 1px 9px 0px rgba(17,17,17,0.08)',
    '0px 3px 3px -2px rgba(17,17,17,0.05),0px 3px 4px 0px rgba(17,17,17,0.02),0px 1px 12px 0px rgba(17,17,17,0.08)',
    '0px 2px 4px -1px rgba(17,17,17,0.05),0px 4px 5px 0px rgba(17,17,17,0.02),0px 1px 14px 0px rgba(17,17,17,0.08)',
    '0px 3px 5px -1px rgba(17,17,17,0.05),0px 5px 8px 0px rgba(17,17,17,0.02),0px 1px 18px 0px rgba(17,17,17,0.08)',
    '0px 3px 5px -1px rgba(17,17,17,0.05),0px 6px 10px 0px rgba(17,17,17,0.02),0px 1px 22px 0px rgba(17,17,17,0.08)',
    '0px 4px 5px -2px rgba(17,17,17,0.05),0px 7px 10px 1px rgba(17,17,17,0.02),0px 2px 20px 1px rgba(17,17,17,0.08)',
    '0px 5px 5px -3px rgba(17,17,17,0.05),0px 8px 10px 1px rgba(17,17,17,0.02),0px 3px 18px 2px rgba(17,17,17,0.08)',
    '0px 5px 6px -3px rgba(17,17,17,0.05),0px 9px 12px 1px rgba(17,17,17,0.02),0px 3px 20px 2px rgba(17,17,17,0.08)',
    '0px 6px 6px -3px rgba(17,17,17,0.05),0px 10px 14px 1px rgba(17,17,17,0.02),0px 4px 22px 3px rgba(17,17,17,0.08)',
    '0px 6px 7px -4px rgba(17,17,17,0.05),0px 11px 15px 1px rgba(17,17,17,0.02),0px 4px 24px 3px rgba(17,17,17,0.08)',
    '0px 7px 8px -4px rgba(17,17,17,0.05),0px 12px 17px 2px rgba(17,17,17,0.02),0px 5px 26px 4px rgba(17,17,17,0.08)',
    '0px 7px 8px -4px rgba(17,17,17,0.05),0px 13px 19px 2px rgba(17,17,17,0.02),0px 5px 28px 4px rgba(17,17,17,0.08)',
    '0px 7px 9px -4px rgba(17,17,17,0.05),0px 14px 21px 2px rgba(17,17,17,0.02),0px 5px 30px 4px rgba(17,17,17,0.08)',
    '0px 8px 9px -5px rgba(17,17,17,0.05),0px 15px 22px 2px rgba(17,17,17,0.02),0px 6px 32px 5px rgba(17,17,17,0.08)',
    '0px 8px 10px -5px rgba(17,17,17,0.05),0px 16px 24px 2px rgba(17,17,17,0.02),0px 6px 34px 5px rgba(17,17,17,0.08)',
    '0px 8px 11px -5px rgba(17,17,17,0.05),0px 17px 26px 2px rgba(17,17,17,0.02),0px 6px 36px 5px rgba(17,17,17,0.08)',
    '0px 9px 11px -5px rgba(17,17,17,0.05),0px 18px 28px 2px rgba(17,17,17,0.02),0px 7px 38px 6px rgba(17,17,17,0.08)',
    '0px 9px 12px -6px rgba(17,17,17,0.05),0px 19px 29px 2px rgba(17,17,17,0.02),0px 7px 40px 6px rgba(17,17,17,0.08)',
    '0px 10px 13px -6px rgba(17,17,17,0.05),0px 20px 31px 3px rgba(17,17,17,0.02),0px 8px 42px 7px rgba(17,17,17,0.08)',
    '0px 10px 13px -6px rgba(17,17,17,0.05),0px 21px 33px 3px rgba(17,17,17,0.02),0px 8px 44px 7px rgba(17,17,17,0.08)',
    '0px 10px 14px -6px rgba(17,17,17,0.05),0px 22px 35px 3px rgba(17,17,17,0.02),0px 8px 46px 7px rgba(17,17,17,0.08)',
    '0px 11px 14px -7px rgba(17,17,17,0.05),0px 23px 36px 3px rgba(17,17,17,0.02),0px 9px 48px 8px rgba(17,17,17,0.08)',
    '0px 11px 15px -7px rgba(17,17,17,0.05),0px 24px 38px 3px rgba(17,17,17,0.02),0px 9px 50px 8px rgba(17,17,17,0.08)',
  ],
  components: {
    MuiAlert: {
      styleOverrides: {
        root: () => ({
          borderWidth: '2px',
        }),
        action: {
          '& svg': {
            width: '1.5em',
            height: '1.5em',
          },
        },
        // other severity alerts still using default MUI Icon, therefore we only override the icon style for severity `info`
        icon: ({ ownerState }) => ({
          ...(ownerState?.severity === 'info' && {
            fontSize: '1.25rem',
            padding: '8px 0',
          }),
        }),
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'default',
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
        clearIcon: (
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FaIcon icon={faXmark} sx={{ fontSize: '0.75rem' }} />
          </Box>
        ),
        popupIcon: (
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FaIcon icon={faChevronDown} sx={{ fontSize: '0.75rem' }} />
          </Box>
        ),
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.size === 'medium' && {
            input: theme.typography.inputTextMedium,
          }),
          '.MuiFilledInput-root': {
            padding: '6px',
            '&.MuiInputBase-sizeSmall': {
              paddingBottom: '6px',
              '.MuiFilledInput-input': {
                paddingLeft: '6px',
              },
            },
          },
          ...(ownerState?.readOnly && {
            '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
              paddingLeft: 0,
              '.MuiAutocomplete-input': { paddingLeft: 0 },
            },
            '.MuiAutocomplete-endAdornment': { display: 'none' },
          }),
        }),
        popper: ({ theme, ownerState }) => ({
          ...(ownerState.size === 'medium' && {
            '.MuiAutocomplete-option': theme.typography.inputTextMedium,
          }),
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '34px',
          height: '34px',
          fontWeight: 600,
          fontSize: pxToRem(12),
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <FaIcon icon={faSquareCheck} />,
        icon: <FaIcon icon={faSquare} />,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState?.readOnly && { cursor: 'default' }),
          ...(ownerState?.size === 'medium' && {
            '.MuiSvgIcon-root': {
              fontSize: '1.25rem',
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
              },
            },
          }),
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '.MuiChip-icon': {
            marginRight: '1px',
            paddingLeft: '4px',
          },
          ...(ownerState?.size === 'xsmall' && {
            borderRadius: 0,
            height: 'unset',
            ...theme.typography.caption,
          }),
          ...(ownerState?.color === 'gradient' &&
            ['filled', 'square', 'm3'].includes(ownerState?.variant || '') && {
              background: theme.palette.gradient.main,
            }),
          ...(ownerState?.size !== 'xsmall' && {
            [theme.breakpoints.up('md')]: {
              padding: `0 ${theme.spacing(2)}`,
            },
          }),
          // Styled to look like Material 3 chips: https://m3.material.io/components/chips/specs
          ...(ownerState?.variant === 'm3' && {
            '.MuiChip-icon': {
              marginLeft: 0,
              marginRight: '8px',
              paddingLeft: 0,
              ...(ownerState?.size === 'small' && {
                fontSize: '0.75rem',
              }),
            },
            '.MuiChip-label': {
              padding: 0,
            },
            ...(ownerState?.clickable && {
              '&:hover::after': {
                position: 'absolute',
                inset: 0,
                content: '""',
                borderRadius: '4px',
                backgroundColor: 'rgba(0, 0, 0, 0.15)',
              },
            }),
            borderRadius: '4px',
            padding: '0 8px',
            [theme.breakpoints.up('sm')]: {
              padding: '0 8px',
            },
            ...(ownerState?.size === 'small' && {
              height: '22px',
              fontSize: '0.75rem',
            }),
          }),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
        paperFullScreen: {
          borderRadius: 'unset',
        },
      },
    },
    MuiDialogActions: {
      defaultProps: { disableSpacing: true },
      styleOverrides: {
        root: ({ theme }) => ({
          gap: theme.spacing(1),
          flexDirection: 'column',
          alignItems: 'unset',
          padding: theme.spacing(2),
          paddingBottom: theme.spacing(3),
          [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(1.5),
            padding: theme.spacing(3),
            flexDirection: 'row',
            alignItems: 'center',
          },
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...typography.h2,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: { borderRadius: 8 },
        bar: { borderRadius: 8 },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
      defaultProps: {
        underline: 'none',
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: '8px',
          paddingBottom: '8px',
        },
      },
    },
    MuiTableSortLabel: {
      defaultProps: {
        IconComponent: (props) => <FaIcon icon={faArrowDown} {...props} />,
      },
      styleOverrides: {
        root: {
          width: '100%',
          justifyContent: 'space-between',
          '.MuiTableSortLabel-icon': { fontSize: '0.75em' },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState?.variant === 'expressive' && {
            background: theme.palette.gradient.main,
            boxShadow: '0px 4px 30px rgba(71, 74, 86, 0.2)',
            color: 'white',
            lineHeight: pxToRem(26),
            '&:hover': {
              background: theme.palette.gradient.light,
              boxShadow: '0px 4px 30px rgba(71, 74, 86, 0.3);',
            },
            '&.MuiButton-sizeLarge': {
              paddingBottom: pxToRem(8),
              paddingLeft: pxToRem(30),
              paddingRight: pxToRem(30),
              paddingTop: pxToRem(8),
            },
          }),
        }),
        sizeLarge: {
          fontSize: pxToRem(16),
          fontWeight: 600,
          lineHeight: pxToRem(26),
          paddingLeft: pxToRem(16),
          paddingRight: pxToRem(16),
        },
        sizeMedium: {
          fontSize: pxToRem(14),
          fontWeight: 600,
          lineHeight: pxToRem(20),
          paddingBottom: pxToRem(8),
          paddingLeft: pxToRem(16),
          paddingRight: pxToRem(16),
          paddingTop: pxToRem(8),
        },
        sizeSmall: {
          borderRadius: pxToRem(4),
          fontSize: pxToRem(12),
          fontWeight: 600,
          lineHeight: pxToRem(14),
          paddingBottom: pxToRem(8),
          paddingLeft: pxToRem(16),
          paddingRight: pxToRem(16),
          paddingTop: pxToRem(8),
        },
        outlinedSizeLarge: {
          paddingLeft: pxToRem(15),
          paddingRight: pxToRem(15),
        },
        outlinedSizeMedium: {
          paddingBottom: pxToRem(7),
          paddingLeft: pxToRem(15),
          paddingRight: pxToRem(15),
          paddingTop: pxToRem(7),
        },
        outlinedSizeSmall: {
          paddingBottom: pxToRem(7),
          paddingLeft: pxToRem(15),
          paddingRight: pxToRem(15),
          paddingTop: pxToRem(7),
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: { fontSize: pxToRem(16) },
        sizeSmall: { fontSize: pxToRem(12) },
        sizeMedium: { fontSize: pxToRem(14) },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          [`.${formLabelClasses.root} + .${formHelperTextClasses.root}, .MuiFormLabel-requiredContainer + .${formHelperTextClasses.root}`]:
            {
              marginTop: 0,
              marginBottom: theme.spacing(1),
            },
          [`.${formLabelClasses.root}:not(:has(+ .${formHelperTextClasses.root}))`]:
            {
              marginBottom: theme.spacing(1),
            },
          '.MuiFormLabel-requiredContainer': {
            [`&:not(:has(+ .${formHelperTextClasses.root}))`]: {
              marginBottom: theme.spacing(1),
            },
            '.MuiFormLabel-requiredIndicator': {
              margin: 0,
            },
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`.${formControlLabelClasses.label}`]: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
          },
          '&.MuiFormControlLabel-medium': {
            [`.${formControlLabelClasses.label}`]:
              theme.typography.inputTextMedium,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(13),
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { fontWeight: 500 },
        asterisk: { display: 'none' },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      variants: [
        {
          props: { color: 'subtle' },
          style: {
            opacity: 0.5,
            '&:hover': {
              opacity: 1,
            },
          },
        },
      ],
    },
    MuiRadio: {
      defaultProps: {
        checkedIcon: <FaIcon icon={faCircleDot} />,
        icon: <FaIcon icon={faCircle} />,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState?.readOnly && {
            cursor: 'default',
            '&:hover': { backgroundColor: 'unset' },
          }),
          ...(ownerState?.size === 'medium' && {
            '.MuiSvgIcon-root': {
              fontSize: '1.25rem',
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
              },
            },
          }),
        }),
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          padding: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
          '& .MuiSwitch-track': {
            backgroundColor: theme.palette.grey.A300,
            opacity: 1,
            borderRadius: 10,
          },
          '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            opacity: 1,
            ...(ownerState.color === 'primary' && {
              backgroundColor: theme.palette.primary.light,
            }),
          },
          '& .MuiSwitch-switchBase': {
            color: theme.palette.grey.A600,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(11px)',
            color: theme.palette.grey.A800,
          },
          ...(ownerState.size === 'small' && {
            width: 35,
          }),
          ...(ownerState.size === 'medium' && {
            width: 'fit-content',
            height: 'fit-content',
            overflow: 'visible',
            '& .MuiSwitch-track': {
              width: 32,
              height: 21,
              backgroundColor: theme.palette.grey.A300,
              opacity: 1,
              borderRadius: 10,
            },
            '& .MuiSwitch-switchBase:not(.Mui-checked)': {
              transform: 'translateX(-5px) translateY(-5px)',
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(6px) translateY(-5px)',
              color: theme.palette.grey.A800,
            },
            '& .MuiSwitch-thumb': {
              width: '21px',
              height: '21px',
            },
          }),
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          minHeight: pxToRem(42),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.size === 'medium' && {
            'input, textarea, .MuiSelect-select': {
              ...theme.typography.inputTextMedium,
              padding: '8.5px 14px',
            },
          }),
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        }),
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          minHeight: pxToRem(42),
          ':before, :after': { content: 'none' },
        },
        input: {
          // Matches OutlinedInput
          padding: '8.5px 14px',
        },
        multiline: {
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '6px',
          minHeight: pxToRem(42),
          ...(ownerState?.size === 'medium' && {
            '&.MuiInputBase-multiline': { padding: 0 },
          }),
          ...(ownerState?.readOnly && {
            'input, fieldset, &.MuiInputBase-multiline, textarea': {
              paddingLeft: 0,
            },
          }),
        }),
      },
    },
    MuiTable: {
      variants: [
        {
          props: {},
          style: ({ theme }) => ({
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
              '&:first-of-type': {
                borderTopLeftRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
              },
              '&:last-of-type': {
                borderTopRightRadius: theme.shape.borderRadius,
                borderBottomRightRadius: theme.shape.borderRadius,
              },
            },
            [`& .${tableCellClasses.head}`]: {
              fontWeight: 400,
              color: theme.palette.text.secondary,
            },
            [`& td.${tableCellClasses.body}`]: {
              // `td` treats "height" like "min-height"
              height: '60px',
            },
          }),
        },
        {
          props: { size: 'small' },
          style: {
            [`& td.${tableCellClasses.body}, th.${tableCellClasses.head}`]: {
              // `td` treats "height" like "min-height"
              height: '42px',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: pxToRem(15),
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          '&.Mui-active': {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderRadius: '50%',
          },
          '&&': {
            fontSize: '1.5rem',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          cursor: 'pointer',
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderLeftStyle: 'solid',
          borderLeftWidth: '2px',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderLeftStyle: 'solid',
          borderLeftWidth: '2px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSvgIcon-fontSizeXsmall': {
            fontSize: '8px',
          },
          '&.MuiSvgIcon-fontSizeSmall': {
            fontSize: '15px',
          },
          '&.MuiSvgIcon-fontSizeMedium': {
            fontSize: '18px',
          },
          '&.MuiSvgIcon-fontSizeLarge': {
            fontSize: '24px',
          },
          '&.MuiSvgIcon-fontSizeXlarge': {
            fontSize: '48px',
          },
          '&.MuiSvgIcon-fontSizeXxlarge': {
            fontSize: '60px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: 'relative',
          '& .MuiTabs-indicator': {
            borderRadius: '1.5px',
            height: '3px',
          },
          '& .MuiTabs-flexContainer': {
            gap: '0.5rem',
          },
          '&:before': {
            content: '""',
            height: '3px',
            backgroundColor: '#EEEEEE',
            borderRadius: '1.5px',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          marginBottom: '0.5rem',
          borderRadius: '0.5rem',
          textTransform: 'none',
          fontWeight: 500,
          '&.Mui-selected': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(15),
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
        enterTouchDelay: 0,
        leaveTouchDelay: 3000,
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          ...typography.body2,
          padding: 16,
          marginLeft: 10,
          marginRight: 10,
          [theme.breakpoints.up('sm')]: {
            ...typography.body1,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 16,
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h6',
          subtitle2: 'h6',
          body1: 'p',
          body2: 'p',
          inherit: 'p',
        },
      },
    },
  },
};

const mergeArrays = (objValue: unknown, srcValue: unknown) => {
  return Array.isArray(objValue) ? objValue.concat(srcValue) : undefined;
};

/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */
const mergeThemeObjects = (objValue: unknown, srcValue: unknown) => {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  if (objValue instanceof Function) {
    return (...args: unknown[]) => {
      const src = srcValue instanceof Function ? srcValue(...args) : srcValue;
      return mergeWith({}, objValue(...args), src, mergeArrays);
    };
  }
  return undefined;
};
/* eslint-enable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */

export const makeTheme = (theme: Partial<ThemeOptions>): Theme => {
  const result = createTheme(
    mergeWith({}, BaseTheme, theme, mergeThemeObjects),
  );
  result.typography.pxToRem = pxToRem;
  return result;
};
