import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';

export function SrIconButtonGroup({
  children,
}: PropsWithChildren): ReactElement {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 2,
        borderRadius: 0.5,
        border: '1px solid',
        borderColor: 'stroke.dark',
        backdropFilter: 'blur(5.5px)',
        px: 2,
        py: 0.5,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Stack>
  );
}

SrIconButtonGroup.Item = Item;

export type SrIconButtonGroupItemProps = Readonly<{
  icon: IconDefinition;
  selected?: boolean;
  disabled?: boolean;
  tooltipAvailable?: ReactNode;
  tooltipUnavailable?: ReactNode;
  onClick?(): void;
}>;

function Item({
  icon,
  selected,
  disabled,
  tooltipAvailable,
  tooltipUnavailable,
  onClick,
}: SrIconButtonGroupItemProps) {
  return (
    <Tooltip title={disabled ? tooltipUnavailable : tooltipAvailable}>
      <div>
        <IconButton
          aria-label="toggle overlay"
          onClick={!disabled ? onClick : undefined}
          disabled={disabled}
          sx={{
            padding: 0.875,
            borderRadius: 0.5,
            color: selected ? 'stroke.active' : 'text.secondary',
          }}
        >
          <FaIcon icon={icon} />
        </IconButton>
      </div>
    </Tooltip>
  );
}
