export type User = {
  key: string | undefined;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  picture?: string;
  roles: UserRole[];
  hasInternalRole: boolean;
  isSocialUser: boolean;
  auth0IntegrationKey: string | undefined;
  requiresSignUp?: boolean;
};

export enum UserRole {
  SRC_Admin = 'SRC Admin',
  SRC_Manager = 'SRC Manager',
  SRC_Traffic = 'SRC Traffic',
  SRC_Art_Director = 'SRC Art Director',
  SRC_Designer = 'SRC Designer',
  Client = 'Client',
  Guest = 'Guest',
}
