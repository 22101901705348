/* eslint-disable @typescript-eslint/no-use-before-define */
import { getUserData } from '@creative-foundation/auth';
import { commonBasicLogger } from 'launchdarkly-js-sdk-common';
import {
  initialize as ldClientInitialize,
  LDClient,
  LDSingleKindContext,
} from 'launchdarkly-js-client-sdk';
import { ConfigService } from '@shuttlerock/configuration';

class Flags {
  public readonly client: Promise<LDClient>;

  constructor() {
    this.client = initClient();
  }

  private async isEnabled(
    flag: string,
    defaultValue = false,
  ): Promise<boolean> {
    const client = await this.client;
    return client.variation(flag, defaultValue);
  }

  public get reviewPageFilters() {
    return this.isEnabled('review-page-filters');
  }

  public get hxProjectFilters() {
    return this.isEnabled('hx-project-filters');
  }

  public get novaUserMigration() {
    return this.isEnabled('nova-user-migration');
  }

  public get jamAdaptGenAi() {
    return this.isEnabled('jam-adapt-gen-ai');
  }
}

async function initClient() {
  const data = await getUserData(new Request(''));
  const context: LDSingleKindContext = data?.user
    ? {
        kind: 'user',
        email: data.user.email,
        key: data.user.auth0IntegrationKey,
        roles: data.user.roles,
      }
    : {
        kind: 'user',
        anonymous: true,
      };

  const client = ldClientInitialize(
    ConfigService.LAUNCHDARKLY_CLIENT_ID() || '',
    context,
    {
      wrapperName: 'react-client-sdk',
      sendEventsOnlyForVariation: true,
      logger: commonBasicLogger({
        // eslint-disable-next-line no-console
        destination: console.error,
        level: 'error',
      }),
    },
  );
  await client.waitUntilReady();

  return client;
}

// Client for determining the state of feature flags outside of React components
export const flags = new Flags();
