import { IconButton } from '@mui/material';
import { useDebouncedCallback } from '@react-hookz/web';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Breakpoint } from '@mui/system';
import { SxProps, Theme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { FormControl } from '../FormControl/FormControl';
import { TextInput } from '../TextInput/TextInput';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';
import { CircularProgress } from '../../Feedback/CircularProgress/CircularProgress';

export type SearchFieldProps = {
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  initialValue?: string;
  debounceTime?: number;
  loading?: boolean;
  /** Removes the border and background colour from the input, starting at the specified Breakpoint upwards */
  unfilledFrom?: Breakpoint;
  sx?: SxProps<Theme>;
};

const DEFAULT_DEBOUNCE_TIME = 500;

export const SearchField = ({
  name,
  placeholder,
  initialValue,
  unfilledFrom,
  loading,
  onChange,
  debounceTime = DEFAULT_DEBOUNCE_TIME,
  sx,
}: SearchFieldProps): ReactElement => {
  const [value, setValue] = useState(initialValue || '');

  const debounceChange = useDebouncedCallback(
    onChange,
    [onChange],
    debounceTime,
  );

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setValue(target?.value);
      debounceTime ? debounceChange(target?.value) : onChange(target?.value);
    },
    [debounceChange, debounceTime, onChange],
  );

  const onClear = useCallback(() => {
    setValue('');
    onChange('');
  }, [onChange]);

  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  const sxProps: SxProps | undefined = useMemo(() => {
    if (!unfilledFrom) {
      return undefined;
    }
    const bp = unfilledFrom;
    return {
      background: { [bp]: 'none' },
      boxShadow: { [bp]: 0 },
      '& fieldset': {
        border: { [bp]: 0 },
      },
      '& input': {
        background: { [bp]: 'none' },
      },
    };
  }, [unfilledFrom]);

  return (
    <FormControl id={name} fullWidth>
      <TextInput
        name={name}
        placeholder={placeholder}
        value={value}
        sx={[sxProps, ...(Array.isArray(sx) ? sx : [sx])]}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            {loading ? (
              <CircularProgress size={15} />
            ) : (
              <FaIcon icon={faMagnifyingGlass} />
            )}
          </InputAdornment>
        }
        endAdornment={
          value ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear"
                data-testid={`SearchField-ClearButton-${name}`}
                onClick={onClear}
              >
                <FaIcon icon={faXmark} />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
      />
    </FormControl>
  );
};
