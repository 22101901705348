import {
  Children,
  forwardRef,
  isValidElement,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  Link,
  SxProps,
  Theme,
} from '@mui/material';
import { SearchField } from '../SearchField/SearchField';
import { OverflowText } from '../../DataDisplay/OverflowText/OverflowText';

export type SrFilterProps = {
  name: string;
  children: ReactNode;
  sx?: SxProps<Theme>;
  optionsSearch?: boolean;
  showAll?: boolean;
};

export const SrFilter = ({
  name,
  children,
  sx,
  optionsSearch,
  showAll,
  ...props
}: SrFilterProps): ReactElement => {
  // Number to limit the displayed Options
  const OPTIONS_SEARCH = 12;
  const OPTIONS_SHOW_ALL = 7;

  const [keyword, setKeyword] = useState<string>('');
  const [showAllOn, setShowAllOn] = useState<boolean>(!!showAll);

  const showSearchField = (): boolean => {
    return optionsSearch || Children.count(children) > OPTIONS_SEARCH;
  };

  const showShowAllButton = (): boolean => {
    const optionsAmount = Children.count(children);
    return !showAllOn && !keyword.length && optionsAmount > OPTIONS_SHOW_ALL;
  };

  /*
   * Filter or limit the displayed Options based on the defined limits
   */
  const filterChildren = (): ReactNode => {
    // Limit the displayed Options
    if (showShowAllButton()) {
      const options = Children.toArray(children);
      return options.slice(0, OPTIONS_SHOW_ALL);
    }
    // Filter the displayed Options
    if (showSearchField()) {
      const filteredOptions: ReactNode[] = [];
      Children.forEach(children, (child: ReactNode) => {
        if (
          isValidElement(child) &&
          child.props.value.toLowerCase().includes(keyword.toLowerCase())
        ) {
          filteredOptions.push(child);
        }
      });
      return filteredOptions;
    }
    return children;
  };

  return (
    <FormGroup {...props} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {/* Show the Options Search Input if more than {OPTIONS_SEARCH} options OR if the optionsSearch is TRUE */}
      {showSearchField() ? (
        <SearchField
          name={name}
          placeholder="Search"
          onChange={(value) => {
            setKeyword(value);
          }}
          sx={{ mb: 1.5, ml: -1.5 }}
        />
      ) : null}
      {filterChildren()}
      {showShowAllButton() ? (
        <Link
          component="button"
          variant="body1"
          sx={{
            mt: 0.75,
            ml: -0.75,
            textAlign: 'left',
            color: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.primary.main
                : theme.palette.primary.light,
          }}
          onClick={() => {
            setShowAllOn(true);
          }}
        >
          Show all
        </Link>
      ) : null}
    </FormGroup>
  );
};

export type SrFilterCheckboxProps = {
  id?: string;
  name?: string;
  label?: ReactNode;
  value: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onCheckedChange?(isChecked: boolean): void;
};

const FilterCheckbox = forwardRef<HTMLInputElement, SrFilterCheckboxProps>(
  (
    { id, name, label, value, defaultChecked, checked, onCheckedChange },
    ref,
  ) => {
    return (
      <FormControlLabel
        disableTypography
        label={
          <OverflowText variant="inputLabel" sx={{ color: 'text.primaryAlt' }}>
            {label ?? value}
          </OverflowText>
        }
        control={
          <Checkbox
            inputRef={ref}
            id={id}
            name={name}
            value={value}
            defaultChecked={defaultChecked}
            checked={checked}
            onChange={(e) => onCheckedChange?.(e.target.checked)}
            sx={{
              py: 0.75,
              color: 'grey.A400',
              '&.Mui-checked svg': {
                color: 'primary.light',
              },
            }}
          />
        }
      />
    );
  },
);

SrFilter.Checkbox = FilterCheckbox;
